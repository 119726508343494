import React, { useEffect, useState } from 'react';
import { differenceInSeconds, intervalToDuration } from 'date-fns';

import { useInterval } from 'common/customHook';
import moment from 'moment';
import { formatDate } from '../../common/constant';
import { formatTimeNumber } from '../../utils/time';
import { Tooltip } from 'antd';

const CountdownTime = ({ pool, ...props }) => {
  const [duration, setDuration] = useState({});

  const countDownTimer = () => {
    const currentTime = new Date();
    const endTime = new Date(pool.startDate);

    const isValidDuration = differenceInSeconds(endTime, currentTime) > 0;

    const duration = intervalToDuration({
      start: currentTime,
      end: endTime,
    });

    if (isValidDuration) {
      setDuration(duration);
    } else {
      setDuration(0);
    }
  };

  const timer = useInterval(() => countDownTimer(), 1000);

  useEffect(() => {
    pool.startDate && timer.start();

    !duration && timer.stop();

    return () => {
      pool.startDate && timer.stop();
    };
  }, [pool.startDate, duration]);

  const { years, months, days, hours, minutes, seconds } = duration;

  let time =
    (!!days || !!hours || !!minutes || !!seconds) &&
    `${!!years ? `${years}y` : ''} ${!!months ? `${months}m` : ''} ${!!days ? `${days}d` : ''} ${
      !!hours ? `${formatTimeNumber(hours)}h` : '00h'
    } ${!!minutes ? `${formatTimeNumber(minutes)}m` : '00m'} ${!!seconds ? `${formatTimeNumber(seconds)}s` : '00s'}`;

  return (
    <Tooltip
      title={Object.keys(duration).length ? time : moment(pool.startDate).format(formatDate)}
      placement="topLeft"
    >
      <p {...props}>{Object.keys(duration).length ? time : moment(pool.startDate).format(formatDate)}</p>
    </Tooltip>
  );
};

export default CountdownTime;
