import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  pools: {
    total: 0,
    records: [],
  },

  loading: false,

  listNftPool: {
    total: 0,
    records: [],
  },
  loadingNft: false,
  isApprovalForAll: false,
};

export const NftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    handleGetPoolsRequest: (state, action) => {
      const { payload } = action;
      return { ...state, loading: !payload.data.offset };
    },

    handleGetPoolsResponse: (state, action) => {
      const { payload } = action;
      const { data, loadMore } = payload;
      const { pools } = current(state);

      let poolsData = loadMore ? { ...pools, records: [...pools.records, ...data.records], total: data.total } : data;

      return { ...state, pools: poolsData, loading: false };
    },

    handleGetMyStakingPoolsRequest: (state, action) => {
      return { ...state, loading: true };
    },

    handleGetMyStakingPoolsResponse: (state, action) => {
      const { payload } = action;
      const { data, loadMore } = payload;
      const { pools } = current(state);

      let poolsData = loadMore ? { ...pools, records: [...pools.records, ...data.records], total: data.total } : data;

      return { ...state, pools: poolsData, loading: false };
    },

    handleGetNftsRequest: (state, action) => {
      return { ...state, loadingNft: true };
    },

    handleGetNftsResponse: (state, action) => {
      const { payload } = action;
      const { data, loadMore } = payload;
      const { listNftPool } = current(state);

      let poolsData = loadMore
        ? { ...listNftPool, records: [...listNftPool.records, ...data.records], total: data.total }
        : data;
      return { ...state, listNftPool: poolsData, loadingNft: false };
    },
    handleStakingRequest: (state, action) => {
      return { ...state };
    },

    handleUpdateTxidStakingRequest: (state, action) => {
      return { ...state };
    },

    handleSignRequest: (state, action) => {
      return { ...state };
    },

    handleClaimRequest: (state, action) => {
      return { ...state };
    },

    handleSetIsApprovepool: (state, action) => {
      const { payload } = action;

      return { ...state, isApprovalForAll: payload };
    },

    handleUpdateStatusUnstake: (state, action) => {
      const { payload } = action;

      return { ...state };
    },

    handleRevertUnstake: (state, action) => {
      const { payload } = action;

      return { ...state };
    },

    handleUpdateClaim: (state, action) => {
      const { payload } = action;

      return { ...state };
    },
  },
});

export const {
  handleGetPoolsRequest,
  handleGetPoolsResponse,
  handleGetNftsRequest,
  handleGetNftsResponse,
  handleStakingRequest,
  handleUpdateTxidStakingRequest,
  handleGetMyStakingPoolsRequest,
  handleGetMyStakingPoolsResponse,
  handleSignRequest,
  handleClaimRequest,
  handleSetIsApprovepool,
  handleUpdateStatusUnstake,
  handleRevertUnstake,
  handleUpdateClaim,
} = NftSlice.actions;

export default NftSlice.reducer;
