import { Button } from 'antd';
import React from 'react';

const ButtonPE = ({ text, classNameStyle, variant, onCLick, disabled, ...props }) => {
  return (
    <Button onClick={onCLick} disabled={disabled} {...props} className={`button button--${variant} ${classNameStyle}`}>
      {text}
    </Button>
  );
};

export default ButtonPE;
