import React from 'react';
import ImageLoaing from 'resources/images/icon-loading.png';

const Loading = () => {
  return (
    <div className="wrap-loading" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img className="loading-rotate rotating" src={ImageLoaing} style={{ marginTop: 104 }} />
    </div>
  );
};

export default Loading;
