import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TokenStaking from 'resources/images/token_staking.png';
import NftStaking from 'resources/images/nft-staking.png';
import ImageRight from 'resources/images/image-dort-staking.png';
import ImageTriangleLarge from 'resources/images/triangle-large.png';
import ImageTriangleMedium from 'resources/images/triangle-medium.png';
import ImageTriangleSmall from 'resources/images/triangle-small.png';
import ImageScrew from 'resources/images/screw.png';
import LockIcon from 'resources/images/lock-icon.png';
import BookmarkIcon from 'resources/images/bookmark-icon.png';
import TTXIcon from 'resources/images/ttx-icon.png';

import ButtonPE from 'components/ButtonPE';
import { linkRouters } from 'common/constant';
import NumberFormat from 'components/NumberFormat';
import { numberToRound } from 'common/constant';
import selectedAuthen from 'redux/authen/selector';
import { handleConnectModalRequest } from 'redux/authen/slice';
import { handleGetSummaryRequest } from 'redux/home/slice';

function HomePage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);
  const summary = useSelector((state) => state.HomeSlice.summary);

  useEffect(() => {
    dispatch(handleGetSummaryRequest());
  }, []);

  const handleRedirectNft = () => {
    if (selectedAddressWallet)
      props.history.push({
        pathname: linkRouters.nftStaking,
      });
    else dispatch(handleConnectModalRequest({ data: null }));
  };

  const handleRedirectToken = (type) => {
    if (selectedAddressWallet)
      props.history.push({
        pathname: linkRouters.tokenStaking,
        state: { type },
      });
    else dispatch(handleConnectModalRequest({ data: null }));
  };

  return (
    <Row className="home-page">
      <div className="home_container container">
        <div className="summary-warp">
          <Row className="home-page--sumary" justify="space-between" gutter={[19, 24]}>
            <Col xl={8} md={8} sm={24} xs={24}>
              <div>
                <img src={LockIcon} alt="total amount staked" />
                <NumberFormat
                  className="amount"
                  thousandSeparator
                  value={numberToRound(summary?.totalAmountStakedUsd, 2)}
                  displayType="text"
                  prefix={'$ '}
                />
                <p className="title">{t('home.txt_total_amount_staked')}</p>
              </div>
            </Col>
            <Col xl={8} md={8} sm={24} xs={24}>
              <div>
                <img src={BookmarkIcon} alt="total rewarn claimed" />
                <NumberFormat
                  className="amount"
                  thousandSeparator
                  value={numberToRound(summary?.totalRewardClaimedUsd, 2)}
                  displayType="text"
                  prefix={'$ '}
                />
                <p className="title">{t('home.txt_total_reward_claimed')}</p>
              </div>
            </Col>
            <Col xl={8} md={8} sm={24} xs={24}>
              <div>
                <img src={TTXIcon} alt="ttx token price" />
                <NumberFormat
                  className="amount"
                  thousandSeparator
                  value={numberToRound(summary?.zTokenUsd, 2)}
                  displayType="text"
                  prefix={'$ '}
                />
                <p className="title">{t('home.txt_z_token_price')}</p>
              </div>
            </Col>
          </Row>
        </div>

        {/* <Row className="staking-list">
          <Col className="token-staking">
            <img src={TokenStaking} className="staking" />
            <p className="title">{t('home.txt_token_staking')}</p>
            <p className="content">{t('home.txt_welcome_to_token')}</p>
            <div className="btn-group">
              <ButtonPE
                text={t('home.txt_z_token_staking')}
                variant="primary"
                onCLick={() => handleRedirectToken('z_token')}
              />
              <ButtonPE
                text={t('home.txt_lp_staking')}
                classNameStyle="lp-staking"
                onCLick={() => handleRedirectToken('lp_token')}
              />
            </div>
          </Col>
          <Col className="nft-staking">
            <img src={NftStaking} className="staking" />
            <p className="title">{t('home.txt_nft_staking')}</p>
            <p className="content">{t('home.txt_welcome_to_characters_items')}</p>
            <ButtonPE text={t('home.txt_nft_staking')} variant="secondary" onCLick={handleRedirectNft} />
          </Col>
          <Col className="dort-staking">
            <img src={ImageRight} />
            <img src={ImageTriangleLarge} className="triangle-large" />
            <img src={ImageTriangleMedium} className="triangle-medium" />
            <img src={ImageTriangleSmall} className="triangle-small" />
            <img src={ImageScrew} className="screw" />
          </Col>
        </Row> */}
      </div>
    </Row>
  );
}
export default withRouter(HomePage);
