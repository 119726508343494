import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import type { AddEthereumChainParameter } from '@web3-react/types';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import {
  BSCMainnetChainIdDec,
  BSCTestnetChainIdDec,
  CHAIN_INFO,
  EtherMainnetChainIdDec,
  EtherTestnetChainIdDec,
  LIST_NETWORK_RPC_MAINNET,
  // LIST_NETWORK_RPC_TESTNET,
  METAMASK_DEEPLINK,
  PolygonMainnetChainIdDec,
  PolygonTestnetChainIdDec,
  TYPE_OF_ANT_DESIGN,
} from 'common/constant';

import showMessage from 'components/Message';

export const ETH_CHAIN_ID = CHAIN_INFO.ETHER.value;
export const BSC_CHAIN_ID = CHAIN_INFO.BSC.value;
export const POLYGON_CHAIN_ID = CHAIN_INFO.POLYGON.value;
export const ETH_CHAIN_ID_TESTNET = CHAIN_INFO.ETHER_TESTNET.value;
export const BSC_CHAIN_ID_TESTNET = CHAIN_INFO.BSC_TESTNET.value;
export const POLYGON_CHAIN_ID_TESTNET = CHAIN_INFO.POLYGON_TESTNET.value;

export const LIST_BSC_TESTNET = [
  'https://data-seed-prebsc-1-s1.binance.org:8545/',
  'https://data-seed-prebsc-2-s1.binance.org:8545/',
  'https://data-seed-prebsc-1-s2.binance.org:8545/',
  'https://data-seed-prebsc-2-s2.binance.org:8545/',
  'https://data-seed-prebsc-1-s3.binance.org:8545/',
  'https://data-seed-prebsc-2-s3.binance.org:8545/',
];

export const randomRPCTesnet = (listRPC: any) => {
  const lengthList = listRPC?.length;
  const randomNumber = Math.floor(Math.random() * 10) % lengthList;
  return listRPC[randomNumber];
};

export const LIST_NETWORK_RPC_TESTNET: any = {
  97: randomRPCTesnet(LIST_BSC_TESTNET),
  80001: 'https://rpc.ankr.com/polygon_mumbai',
  5: 'https://goerli.infura.io/v3/a5bf235847a245d98822410f2f42f3d0',
  222000222: 'https://subnets.avax.network/meld/testnet/rpc',
};

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

const BNB: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Binance',
  symbol: 'BNB',
  decimals: 18,
};

const GMELD: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'gMELD',
  symbol: 'gMELD',
  decimals: 18,
};

type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation };

export const MAINNET_CHAINS: ChainConfig = {
  [ETH_CHAIN_ID]: {
    name: CHAIN_INFO.ETHER.name,
    nativeCurrency: ETH,
    urls: [LIST_NETWORK_RPC_MAINNET[ETH_CHAIN_ID]],
    blockExplorerUrls: [CHAIN_INFO.ETHER.url],
  },
  [POLYGON_CHAIN_ID]: {
    name: CHAIN_INFO.POLYGON.name,
    nativeCurrency: MATIC,
    urls: [LIST_NETWORK_RPC_MAINNET[POLYGON_CHAIN_ID]],
    blockExplorerUrls: [CHAIN_INFO.POLYGON.url],
  },
  [BSC_CHAIN_ID]: {
    name: CHAIN_INFO.BSC.name,
    nativeCurrency: BNB,
    urls: [LIST_NETWORK_RPC_MAINNET[BSC_CHAIN_ID]],
    blockExplorerUrls: [CHAIN_INFO.BSC.url],
  },
};

export const TESTNET_CHAINS: ChainConfig = {
  [ETH_CHAIN_ID_TESTNET]: {
    name: CHAIN_INFO.ETHER_TESTNET.name,
    nativeCurrency: ETH,
    urls: [LIST_NETWORK_RPC_TESTNET[ETH_CHAIN_ID_TESTNET]],
    blockExplorerUrls: [CHAIN_INFO.ETHER_TESTNET.url],
  },
  [BSC_CHAIN_ID_TESTNET]: {
    name: CHAIN_INFO.BSC_TESTNET.name,
    nativeCurrency: BNB,
    urls: [LIST_NETWORK_RPC_TESTNET[BSC_CHAIN_ID_TESTNET]],
    blockExplorerUrls: [CHAIN_INFO.BSC_TESTNET.url],
  },
  [POLYGON_CHAIN_ID_TESTNET]: {
    name: CHAIN_INFO.POLYGON_TESTNET.name,
    nativeCurrency: MATIC,
    urls: [LIST_NETWORK_RPC_TESTNET[POLYGON_CHAIN_ID_TESTNET]],
    blockExplorerUrls: [CHAIN_INFO.POLYGON_TESTNET.url],
  },
};

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
};

// end update

export const WALLET_CONNECT_PROJECT_ID = 'c35a8f5bfff036434df12a31ea10742d';

interface BasicChainInformation {
  urls: string[];
  name: string;
}
interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation,
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation)?.nativeCurrency;
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export const [walletConnectBSC, hooksWalletConnectBSC] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
        chains: [process.env.REACT_APP_IS_DEV ? BSCTestnetChainIdDec : BSCMainnetChainIdDec],
        showQrModal: true,
      },
    }),
);
export const [walletConnectETH, hooksWalletConnectETH] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
        chains: [process.env.REACT_APP_IS_DEV ? EtherTestnetChainIdDec : EtherMainnetChainIdDec],
        showQrModal: true,
      },
    }),
);
export const [walletConnectPoly, hooksWalletConnectPoly] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
        chains: [process.env.REACT_APP_IS_DEV ? PolygonTestnetChainIdDec : PolygonMainnetChainIdDec],
        showQrModal: true,
      },
    }),
);

export const [metaMask, hooksMetaMask] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }));

export function getErrorConnectMessage(error: Error, deactivate: any, metamaskNotFound?: any) {
  localStorage.removeItem('walletconnect');
  if (error) {
    return metamaskNotFound && metamaskNotFound();
    // } else if (error instanceof UnsupportedChainIdError) {
  } else {
    console.error(error);
    return showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'common.unknowError');
  }
}

export interface WalletInfo {
  connector?: any; // AbstractConnector;
  name: string;
  // iconName: string
  description: string;
  href: string | null;
  // color: string
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
  disableIcon: string;
  icon: string;
  deepLink?: string;
}

export enum ConnectorNames {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
}

export type connectorNames = Extract<ConnectorNames, ConnectorNames.MetaMask | ConnectorNames.WalletConnect>;

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    // connector: injected,
    name: ConnectorNames.MetaMask,
    icon: '/images/metamask.svg',
    disableIcon: '/images/metamask-disabled.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    mobile: true,
    deepLink: METAMASK_DEEPLINK,
  },
  WALLET_CONNECT: {
    // connector: walletConnect,
    name: ConnectorNames.WalletConnect,
    icon: '/images/WalletConnect.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    disableIcon: '/images/wallet-connect-disabled.svg',
    href: null,
    mobile: true,
  },
};

export const SUPPORTED_WALLETS_BSC: { [key: string]: WalletInfo } = {
  METAMASK: SUPPORTED_WALLETS.METAMASK,
  BSC_WALLET: SUPPORTED_WALLETS.BSC_WALLET,
  WALLET_CONNECT: {
    // connector: walletConnectBsc,
    // connector: walletConnect,
    name: ConnectorNames.WalletConnect,
    icon: '/images/WalletConnect.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    disableIcon: '/images/wallet-connect-disabled.svg',
    href: null,
  },
};

export const connectorsByName: { [key in ConnectorNames]: any /* AbstractConnector */ } = {
  [ConnectorNames.MetaMask]: metaMask,
  [ConnectorNames.WalletConnect]: 'Wallet connect',
  // [ConnectorNames.WalletConnectBsc]: walletConnectBsc,
};

export const APP_NETWORKS_SUPPORT = {
  [ETH_CHAIN_ID]: {
    details: {
      chainId: `0x${(+ETH_CHAIN_ID).toString(16)}`,
      chainName: CHAIN_INFO.ETHER.name,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_MAINNET[ETH_CHAIN_ID]],
      blockExplorerUrls: [CHAIN_INFO.ETHER.url],
    },
  },
  [BSC_CHAIN_ID]: {
    details: {
      chainId: `0x${(+BSC_CHAIN_ID).toString(16)}`,
      chainName: CHAIN_INFO.BSC.name,
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_MAINNET[BSC_CHAIN_ID]],
      blockExplorerUrls: [CHAIN_INFO.BSC.url],
    },
  },
  [POLYGON_CHAIN_ID]: {
    details: {
      chainId: `0x${(+POLYGON_CHAIN_ID).toString(16)}`,
      chainName: CHAIN_INFO.POLYGON.name,
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_MAINNET[POLYGON_CHAIN_ID]],
      blockExplorerUrls: [CHAIN_INFO.POLYGON.url],
    },
  },

  [ETH_CHAIN_ID_TESTNET]: {
    details: {
      chainId: `0x${(+ETH_CHAIN_ID_TESTNET).toString(16)}`,
      chainName: CHAIN_INFO.ETHER_TESTNET.name,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[ETH_CHAIN_ID_TESTNET]],
      blockExplorerUrls: [CHAIN_INFO.ETHER_TESTNET.url],
    },
  },
  [BSC_CHAIN_ID_TESTNET]: {
    details: {
      chainId: `0x${(+BSC_CHAIN_ID_TESTNET).toString(16)}`,
      chainName: CHAIN_INFO.BSC_TESTNET.name,
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[BSC_CHAIN_ID_TESTNET]],
      blockExplorerUrls: [CHAIN_INFO.BSC_TESTNET.url],
    },
  },
  [POLYGON_CHAIN_ID_TESTNET]: {
    details: {
      chainId: `0x${(+POLYGON_CHAIN_ID_TESTNET).toString(16)}`,
      chainName: CHAIN_INFO.POLYGON_TESTNET.name,
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [LIST_NETWORK_RPC_TESTNET[POLYGON_CHAIN_ID_TESTNET]],
      blockExplorerUrls: [CHAIN_INFO.POLYGON_TESTNET.url],
    },
  },
};
