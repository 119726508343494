import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Col, Collapse, Row, Tabs, Image, Tooltip } from 'antd';

import {
  DATA_SEARCH_POOL_MECHANISM_TYPE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SEARCH_STATUS,
  DEFAULT_SEARCH_TOKEN_STATUS,
  NETWORK_URL_BSC,
  numberToRound,
  POOL_REWARD_TYPE,
  TIER_TYPE,
} from 'common/constant';
import Loading from 'components/Loading';
import ModalPE from 'components/Modal';
import Nodata from 'components/Nodata';
import NumberFormat from 'components/NumberFormat';
import selectedAuthen from 'redux/authen/selector';
import { handleGetSummaryRequest } from 'redux/home/slice';
import selectedToken from 'redux/tokenStaking/selector';
import { handleGetListNftInPoolStart, handleGetPoolTokensRequest } from 'redux/tokenStaking/slice';
import { selectActiveProvider } from 'utils';

import PoolDetail from './ListViewTokenStaking/PoolDetail';
import CustomPagination from 'components/Pagination';
import Search from './Search';

import IconClose from 'resources/svg/iconClose';
import ImageLoaing from 'resources/images/icon-loading.png';

const { TabPane } = Tabs;

const tabPanes = [
  {
    tab: 'Tier 1',
    key: '1',
  },
  {
    tab: 'Tier 2',
    key: '2',
  },
  {
    tab: 'Tier 3',
    key: '3',
  },
  {
    tab: 'Tier 4',
    key: '4',
  },
];

const TokenStaking = (props) => {
  const { location } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedLoadingPoolToken = useSelector(selectedToken.selectedLoadingPoolToken);
  const listPoolTokens = useSelector(selectedToken.selectedpoolTokens);
  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);
  const summary = useSelector((state) => state.HomeSlice.summary);
  const listNftInPool = useSelector(selectedToken.selectedListNftInPool);
  const totalNftInPool = useSelector(selectedToken.selectedTotalNftInPool);
  const isLoadingListNftInPool = useSelector(selectedToken.selectedIsLoadingListNftInPool);
  const chains = useSelector((state) => state.HomeSlice.chains);
  const network = useSelector((state) => state.HomeSlice.network);

  const [isStakingTierVisible, setIsStakingTierVisible] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: DEFAULT_PAGE_SIZE, page: 1 });
  const [tabKey, setTabKey] = useState(TIER_TYPE.TIER_1);
  const [tierLimit, setTierLimit] = useState({ from: 0, to: 0 });
  const [currentPool, setCurrentPool] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [activeKey, setActiveKey] = useState([]);
  const [dataSearch, setDataSearch] = useState({
    status: DEFAULT_SEARCH_STATUS,
    type: location.state
      ? location.state.type
        ? location.state.type
        : DEFAULT_SEARCH_TOKEN_STATUS
      : DEFAULT_SEARCH_TOKEN_STATUS,
    myStaking: false,
    modelType: DATA_SEARCH_POOL_MECHANISM_TYPE.ALLOCATION,
    offset: 0,
    limit: 10,
    rewardType: POOL_REWARD_TYPE.TOKEN,
    sortField: 'createdAt',
    sortType: -1,
  });
  const [currentRpc, setCurrentRpc] = useState(NETWORK_URL_BSC);

  const networkSelected = useMemo(() => {
    const chain = chains.find((item) => item.chainId === network);

    return chain;
  }, [chains, network]);

  useEffect(() => {
    setCurrentRpc('');
    const getCurrentProvider = async () => {
      if (networkSelected && networkSelected?.rpcUrls.length) {
        const rpc = await selectActiveProvider(networkSelected?.rpcUrls);
        if (rpc) setCurrentRpc(rpc);
      }
    };
    getCurrentProvider();
  }, [networkSelected?.rpcUrls]);

  // change account
  useEffect(() => {
    setActiveKey([]);
  }, [selectedAddressWallet]);

  useEffect(() => {
    if (network)
      dispatch(
        handleGetPoolTokensRequest({
          data: { ...dataSearch, chainId: network },
          isAuthorized: Boolean(selectedAddressWallet),
        }),
      );
  }, [dataSearch, selectedAddressWallet, network]);

  useEffect(() => {
    if (dataSearch.limit < listPoolTokens.total) {
      setHasMore(true);
    } else if (listPoolTokens.total) setHasMore(false);
  }, [listPoolTokens?.total]);

  useEffect(() => {
    if (network) dispatch(handleGetSummaryRequest({ chainId: network }));
  }, [network]);

  useEffect(() => {
    if (isStakingTierVisible && currentPool) {
      dispatch(
        handleGetListNftInPoolStart({
          id: currentPool._id,
          params: {
            limit: pagination.pageSize,
            offset: (pagination.page - 1) * pagination.pageSize,
            tier: tabKey,
            sortField: 'name',
            sortType: 1,
          },
        }),
      );
    }
  }, [currentPool?._id, pagination, tabKey, isStakingTierVisible]);

  useEffect(() => {
    switch (tabKey) {
      case '1':
        setTierLimit({ to: currentPool?.tier1, from: currentPool?.tier2 });
        break;
      case '2':
        setTierLimit({ to: currentPool?.tier2, from: currentPool?.tier3 });
        break;
      case '3':
        setTierLimit({ to: currentPool?.tier3, from: currentPool?.tier4 });
        break;
      case '4':
        setTierLimit({ to: currentPool?.tier4, from: currentPool?.maxStakeTier });

      default:
        break;
    }
  }, [tabKey, currentPool]);

  const handleChangeActivekey = (data) => {
    if (data.length > 0) setActiveKey([data[data.length - 1]]);
    else setActiveKey([]);
  };

  const handleChangeSearch = (data) => {
    setDataSearch({ ...data, limit: 10 });
    setActiveKey([]);
  };

  const fetchMoreData = () => {
    if (dataSearch.offset + dataSearch.limit < listPoolTokens.total)
      setDataSearch({ ...dataSearch, limit: dataSearch.limit + 10 });
  };

  const onChangeTab = (value) => {
    setDataSearch({ ...dataSearch, rewardType: value });
  };

  const onOpenModal = (e, data) => {
    e.stopPropagation();
    setCurrentPool(data);
    setIsStakingTierVisible(true);
  };

  const onChangeTabTier = (key) => {
    setTabKey(key);
    setPagination({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  };

  const handleChangePage = (page, pageSize) => {
    setPagination({ page, pageSize });
  };

  const onCloseModal = () => {
    setIsStakingTierVisible(false);
    setTabKey('1');
    setPagination({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  };

  return (
    <>
      <Row className="home-page">
        <div className="home_container container">
          <div className="summary-warp">
            <Row className="home-page--sumary" justify="space-between" gutter={[19, 24]}>
              <Col xl={8} md={8} sm={24} xs={24}>
                <div>
                  <Tooltip
                    title={
                      <NumberFormat
                        className="amount"
                        thousandSeparator
                        value={numberToRound(summary?.totalAmountStakedUsd, 2)}
                        displayType="text"
                        prefix={'$ '}
                      />
                    }
                  >
                    <NumberFormat
                      className="amount"
                      thousandSeparator
                      value={numberToRound(summary?.totalAmountStakedUsd, 2)}
                      displayType="text"
                      prefix={'$ '}
                    />
                  </Tooltip>
                  <p className="title">{t('home.txt_total_amount_staked')}</p>
                </div>
              </Col>
              <Col xl={8} md={8} sm={24} xs={24}>
                <div>
                  <Tooltip
                    title={
                      <NumberFormat
                        className="amount"
                        thousandSeparator
                        value={numberToRound(summary?.totalRewardClaimedUsd, 2)}
                        displayType="text"
                        prefix={'$ '}
                      />
                    }
                  >
                    <NumberFormat
                      className="amount"
                      thousandSeparator
                      value={numberToRound(summary?.totalRewardClaimedUsd, 2)}
                      displayType="text"
                      prefix={'$ '}
                    />
                  </Tooltip>
                  <p className="title">{t('home.txt_total_reward_claimed')}</p>
                </div>
              </Col>
              <Col xl={8} md={8} sm={24} xs={24}>
                <div>
                  <Tooltip
                    title={
                      <NumberFormat
                        className="amount"
                        thousandSeparator
                        value={numberToRound(summary?.zTokenUsd, 4)}
                        displayType="text"
                        prefix={'$ '}
                      />
                    }
                  >
                    <NumberFormat
                      className="amount"
                      thousandSeparator
                      value={numberToRound(summary?.zTokenUsd, 4)}
                      displayType="text"
                      prefix={'$ '}
                    />
                  </Tooltip>
                  <p className="title">{t('home.txt_z_token_price')}</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <div className="token">
        <Search dataSearch={dataSearch} onChangeSearch={handleChangeSearch} />
        <div className="list-token">
          <Tabs
            defaultActiveKey={POOL_REWARD_TYPE.TOKEN}
            className="list-token__tabs"
            onChange={onChangeTab}
            destroyInactiveTabPane
          >
            <TabPane tab="Token Rewards" key={POOL_REWARD_TYPE.TOKEN}>
              {selectedLoadingPoolToken && dataSearch.limit === 10 ? (
                <Loading />
              ) : listPoolTokens.total ? (
                networkSelected &&
                networkSelected.poolContractAddress && (
                  <InfiniteScroll
                    dataLength={listPoolTokens.records.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<Loading />}
                    style={{ overflow: 'hidden' }}
                  >
                    <Collapse activeKey={activeKey} onChange={handleChangeActivekey}>
                      {listPoolTokens.records.map((e, index) => {
                        return (
                          <PoolDetail
                            key={index}
                            pool={e}
                            index={index}
                            activeKey={activeKey}
                            setActiveKey={setActiveKey}
                            dataSearch={dataSearch}
                            setDataSearch={setDataSearch}
                            currentRpc={currentRpc}
                            poolAddress={networkSelected?.poolContractAddress}
                          />
                        );
                      })}
                    </Collapse>
                  </InfiniteScroll>
                )
              ) : (
                <Nodata />
              )}
            </TabPane>
            <TabPane tab="NFT Rewards" key={POOL_REWARD_TYPE.NFT}>
              {selectedLoadingPoolToken && dataSearch.limit === 10 ? (
                <Loading />
              ) : listPoolTokens.total ? (
                <InfiniteScroll
                  dataLength={listPoolTokens.records.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<Loading />}
                  style={{ overflow: 'hidden' }}
                >
                  <Collapse activeKey={activeKey} onChange={handleChangeActivekey}>
                    {listPoolTokens.records.map((e, index) => {
                      return (
                        <PoolDetail
                          key={index}
                          pool={e}
                          index={index}
                          activeKey={activeKey}
                          setActiveKey={setActiveKey}
                          dataSearch={dataSearch}
                          setDataSearch={setDataSearch}
                          onOpenModal={onOpenModal}
                          currentRpc={currentRpc}
                          poolAddress={networkSelected?.poolContractAddress}
                        />
                      );
                    })}
                  </Collapse>
                </InfiniteScroll>
              ) : (
                <Nodata />
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
      <ModalPE visible={isStakingTierVisible} wrapClassName="staking-tier-modal" width="80%" onCancel={onCloseModal}>
        <div className="close-icon" onClick={onCloseModal}>
          <IconClose />
        </div>
        <div className="modal-header">
          <h2 className="modal-header__title">Staking Tier</h2>
          <p className="modal-header__content">
            Stake {currentPool?.currencyName} to earn NFTs. The more you stake the more valuable NFT.
          </p>
        </div>
        <div>
          <Tabs defaultActiveKey="1" onChange={onChangeTabTier} activeKey={tabKey} centered>
            {tabPanes.map((tabItem) => (
              <Tabs.TabPane tab={tabItem.tab} key={tabItem.key}>
                <p className="required-amount">
                  Required Staking Amount: {tierLimit.to} {currentPool?.currencyName} - {tierLimit.from}{' '}
                  {currentPool?.currencyName}
                </p>
                <div className="list-nft">
                  {!isLoadingListNftInPool && listNftInPool.length
                    ? listNftInPool.map((item) => (
                        <div className="list-nft__item">
                          <Image
                            src={item?.detail?.imageUrlM || item?.detail?.imageUrl}
                            alt="NFT"
                            preview={{
                              visible: false,
                              onVisibleChange: () => {
                                window.open(item?.detail?.imageUrlM || item?.detail?.imageUrl);
                              },
                            }}
                          />
                          <span>{item?.detail?.name}</span>
                        </div>
                      ))
                    : 'There are no NFTs'}
                </div>
                {isLoadingListNftInPool && (
                  <img src={ImageLoaing} className="rotating" style={{ margin: '20px auto', display: 'block' }} />
                )}
                <CustomPagination
                  current={pagination.page}
                  defaultCurrent={1}
                  pageSize={pagination.pageSize}
                  total={totalNftInPool}
                  onChange={handleChangePage}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </ModalPE>
    </>
  );
};

export default withRouter(TokenStaking);
