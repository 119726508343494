import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';

import './index.css';
import './i18n/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/configStore';
import { getToken } from './services/api';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'antd/dist/antd.css';
import './App.css';
import './sass/_app.scss';
import ErrorBoundary from 'components/ErrorBoundary';

const onBeforeLift = (store) => () => {
  const { AuthenSlice } = store.getState();
  getToken(AuthenSlice.token);
};

const persistor = persistStore(store);

Sentry.init({
  dsn: 'https://416fd504bf73451ab780e20ff404223a@sentry.ekoios.net/2',
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  // enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate onBeforeLift={onBeforeLift(store)} loading={null} persistor={persistor}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
