import { combineReducers } from 'redux';
import AuthenSlice from './authen/slice';
import HomeSlice from './home/slice';
import TokenSlice from './tokenStaking/slice';
import NftSlice from './nftStaking/slice';

export default combineReducers({
  AuthenSlice,
  HomeSlice,
  TokenSlice,
  NftSlice,
});
