import * as React from 'react';

const IconDocument = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 17.5H5.00002C4.53978 17.5 4.16669 17.1269 4.16669 16.6667L4.16669 3.33333C4.16669 2.8731 4.53978 2.5 5.00002 2.5L11.3026 2.5C11.5347 2.5 11.7563 2.59683 11.9141 2.76716L15.6115 6.76038C15.7541 6.91442 15.8334 7.11662 15.8334 7.32656L15.8334 16.6667C15.8334 17.1269 15.4603 17.5 15 17.5Z"
      stroke="#060A13"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.5 14.1667H12.5" stroke="#060A13" strokeWidth={1.25} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 11.6667H12.5" stroke="#060A13" strokeWidth={1.25} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.8333 7.5L11.6666 7.5C11.2064 7.5 10.8333 7.1269 10.8333 6.66667L10.8333 2.5"
      stroke="#060A13"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconDocument;
