import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { message } from 'antd';
import moment from 'moment';

import { BSCMainnetChainIdDec, BSCTestnetChainIdDec, EVENT_NAME_SOCKET, finish, formatDateTime } from 'common/constant';
import ButtonPE from 'components/ButtonPE';
import ConfirmSuccess from 'components/ConfirmSuccess';
import ModalPE from 'components/Modal';
import { SocketContext } from 'context/socket';
import selectedAuthen from 'redux/authen/selector';
import { handleRevertUnstake, handleUpdateTxidStakingRequest } from 'redux/nftStaking/slice';
import selectedToken from 'redux/tokenStaking/selector';
import { handleGetPoolTokensResponse, handleStakingZTokenRequest } from 'redux/tokenStaking/slice';
import BaseWalletService, { getProvider } from 'utils/baseWallet';
import { v4 as uuidv4 } from 'uuid';

const StakeButton = ({
  values,
  errors,
  setVisibleLoaingContract,
  pool,
  dataSearch,
  setDataSearch,
  resetFieldValue,
  balance,
  balanceString,
  poolAddress,
}) => {
  const { connector } = useWeb3React();
  const library = getProvider(connector?.provider);

  const { t } = useTranslation();
  const wallet = new BaseWalletService().getInstance();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const listPoolTokens = useSelector(selectedToken.selectedpoolTokens);
  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);

  const [isEndDateStakeOver, setIsEnđDateStakeOver] = useState(false);
  const [visibleConfirmSuccess, setConfirmSuccess] = useState(false);
  const [visibleConfirmStake, setVisibleConfirmStake] = useState(false);
  const [textSuccess, setTextSuccess] = useState('');
  const [textHash, setTxHash] = useState('');
  const [stakeId, setStakeId] = useState('');

  useEffect(() => {
    if (stakeId) socket.on(`${EVENT_NAME_SOCKET.STAKE}${stakeId}`, handleSuccessStake);
    return () => {
      // clear listen
      socket.off(`${EVENT_NAME_SOCKET.STAKE}${stakeId}`, handleSuccessStake);
    };
  }, [socket, stakeId]);

  useEffect(() => {
    const isEndDateStakeOverValue = moment().isAfter(pool?.endStakeDate);
    setIsEnđDateStakeOver(isEndDateStakeOverValue);
  }, [pool]);

  const handleSuccessStake = (res) => {
    setConfirmSuccess(true);
    resetFieldValue();
    setDataStake(res);
    setVisibleLoaingContract(false);
    setTextSuccess('Successfully staked.');
    setTxHash(res.txId);
  };

  const onHandleStake = async () => {
    setVisibleConfirmStake(false);
    setVisibleLoaingContract(true);

    let dataStake = {
      amountStake: values.amountStake,
      poolId: pool._id,
      networkType: pool?.networkType,
      status: 1,
      networkAddress: selectedAddressWallet,
      startDate: pool.startDate,
      endDate: pool.endDate,
      contractAddress: poolAddress,
      currencyKey: pool.currencyKey,
      currencyName: pool.currencyName,
      currencyKeyReward: pool.currencyKeyReward,
      currencyNameReward: pool.currencyNameReward,
      txId: pool.txId,
      metadata: {},
      duration: pool.duration,
      isClaim: !pool.duration,
      type: dataSearch.type,
    };

    dispatch(handleStakingZTokenRequest({ data: dataStake, callback: callbackCreateNftStaking, callbackFail }));
  };

  const setMaxAmountStake = (amount, balance) => {
    if (amount < balance) return amount;
    return balanceString;
  };

  const callbackCreateNftStaking = async (stakeId) => {
    setStakeId(stakeId);
    await wallet.stakeToken({
      poolId: pool._id,
      tokenAddress: pool.stakeToken.tokenId,
      library,
      currentAccount: selectedAddressWallet,
      amount: setMaxAmountStake(values.amountStake, balance),
      stakeId,
      contractAddress: poolAddress,
      callbackSuccess: () => {},
      callbackReject,
      callbackProcesing: handleUpdateTxIdStake,
      callbackError: () => callbackFail(true),
    });
  };

  const callbackReject = (id) => {
    dispatch(
      handleRevertUnstake({
        data: { stakeId: id },
        callbackFail,
        callback: () => setVisibleLoaingContract(false),
      }),
    );
  };

  const handleUpdateTxIdStake = async (res) => {
    await dispatch(
      handleUpdateTxidStakingRequest({
        txid: res.txId,
        idStake: res.stakeId,
        callback: () => {},
        callbackFail: callbackFail,
      }),
    );
  };

  const callbackFail = (messErr) => {
    setVisibleLoaingContract(false);
    messErr && message.error('An error occurred. Please try again later.');
  };

  const handleCallback = () => {
    setConfirmSuccess(false);
    setDataSearch({ ...dataSearch, uuid: uuidv4() });
  };

  const setDataStake = (res) => {
    let listPoolClone = JSON.parse(JSON.stringify(listPoolTokens.records));
    let poolClone = JSON.parse(JSON.stringify(pool));
    poolClone.tokenStakings = res;
    const index = listPoolClone.findIndex((e) => e._id === poolClone._id);
    listPoolClone[index] = poolClone;

    dispatch(
      handleGetPoolTokensResponse({
        data: { records: listPoolClone, total: listPoolTokens.total },
        loadMore: false,
      }),
    );
  };

  const handleCancel = () => {};

  const handleCheckConfirm = () => {
    if (pool.tokenStakings && pool.duration) setVisibleConfirmStake(true);
    else onHandleStake();
  };
  return (
    <>
      <ButtonPE
        text="Stake"
        classNameStyle="btn-stake"
        onClick={handleCheckConfirm}
        disabled={
          Object.keys(errors).length ||
          !values.amountStake ||
          dataSearch.status === finish ||
          isEndDateStakeOver ||
          moment().isBefore(pool.startDate)
        }
      />

      <ModalPE visible={visibleConfirmStake} onCancel={handleCancel} wrapClassName="model-claim-reward">
        <p className="title-claime-reward">
          {pool?.tokenStakings && moment().isBefore(pool?.tokenStakings?.updatedAt)
            ? 'Staking Confirmation'
            : ' Are you sure?'}
        </p>
        <div className="content">
          {pool?.tokenStakings &&
          moment().isAfter(moment(pool?.tokenStakings?.updatedAt).add(pool.duration, 'days').toISOString()) ? (
            <div className="claim-content">
              You have rewards to claim from your previous staking period which ended on&nbsp;
              {moment(pool?.tokenStakings?.updatedAt).add(pool.duration, 'days').format(formatDateTime)}. If you stake
              more tokens, you will not be able to claim rewards until&nbsp;
              {moment().add(pool.duration, 'days').format(formatDateTime)}
              <p>Do you want to stake more?</p>
            </div>
          ) : (
            `You currently have already staked in this pool. If you want to stake more tokens, the date you can claim will be extended to ${moment()
              .add(pool.duration, 'days')
              .format(formatDateTime)}. Are you sure you want to stake
          more?`
          )}
        </div>
        <div className="group-btn">
          <ButtonPE
            text={t('nft-staking.txt_cancel')}
            variant="default"
            onCLick={() => {
              setVisibleConfirmStake(false);
            }}
          />
          <ButtonPE text={t('nft-staking.txt_stake')} onCLick={onHandleStake} />
        </div>
      </ModalPE>

      <ConfirmSuccess
        onCancel={handleCallback}
        visible={visibleConfirmSuccess}
        title={`Success`}
        content={textSuccess}
        hash={textHash}
        textBtn="Done"
        onDone={handleCallback}
      />
    </>
  );
};

export default StakeButton;
