import { put, takeLatest, all } from 'redux-saga/effects';
import {
  handleGetSummaryRequest,
  handleGetSummaryResponse,
  handleGetChainsRequest,
  handleGetChainsResponse,
} from './slice';
import { getChainsApi, getSummaryApi } from 'services/home';

export function* handleGetSummarySaga(action) {
  const { payload } = action;
  try {
    const response = yield getSummaryApi(payload);
    if (response?.meta?.code === 0) {
      yield put(
        handleGetSummaryResponse({
          data: response.data,
        }),
      );
    } else {
    }
  } catch (error) {
  } finally {
  }
}

export function* handleGetChainsSaga(action) {
  const { payload } = action;
  try {
    const response = yield getChainsApi();
    if (response?.meta?.code === 0) {
      yield put(
        handleGetChainsResponse({
          data: response.data,
        }),
      );
    } else {
    }
  } catch (error) {
  } finally {
  }
}

function* watchHomeSaga() {
  yield all([takeLatest(handleGetSummaryRequest, handleGetSummarySaga)]);
  yield all([takeLatest(handleGetChainsRequest, handleGetChainsSaga)]);
}

export default watchHomeSaga;
