import React from 'react';
import { Pagination } from 'antd';
const CustomPagination = ({ ...props }) => {
  return (
    <div className="modal-pagination">
      <Pagination responsive className="modal-pagination" {...props} />
    </div>
  );
};
export default CustomPagination;
