import React from 'react';
import { Modal } from 'antd';

const ModalPE = ({ visible, ...props }) => {
  return (
    <Modal title={null} visible={visible} footer={null} closable={false} {...props}>
      {props.children}
    </Modal>
  );
};

export default ModalPE;
