import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Connector } from '@web3-react/types';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import {
  getAddChainParameters,
  getErrorConnectMessage,
  hooksMetaMask,
  hooksWalletConnectBSC,
  hooksWalletConnectETH,
  hooksWalletConnectPoly,
  metaMask,
  walletConnectBSC,
  walletConnectETH,
  walletConnectPoly,
} from 'common/connectors';
import selectedAuthen from 'redux/authen/selector';

import {
  BSCMainnetChainIdDec,
  BSCTestnetChainIdDec,
  CURRENT_WALLET,
  EtherMainnetChainIdDec,
  EtherTestnetChainIdDec,
  PolygonMainnetChainIdDec,
  PolygonTestnetChainIdDec,
} from './../constant';

export const deactivateConnect = (connector: Connector) => {
  if (connector?.deactivate) {
    void connector.deactivate();
  } else {
    void connector.resetState();
  }
};

const onConnectWallet = async (connector: Connector, chainId: string | number) => {
  try {
    if (connector instanceof WalletConnectV2) {
      await connector.activate(Number(chainId));
    } else {
      await connector.activate(getAddChainParameters(Number(chainId)));
    }
  } catch (error) {
    console.log('error :>>', error);
  }
};

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getChain = (chainId: string | number, currentWallet: string) => {
  if (
    (chainId === PolygonMainnetChainIdDec || chainId === PolygonTestnetChainIdDec) &&
    currentWallet === CURRENT_WALLET.WALLET_CONNECT
  ) {
    return { connector: walletConnectPoly, hooks: hooksWalletConnectPoly };
  }
  if (
    (chainId === EtherTestnetChainIdDec || chainId === EtherMainnetChainIdDec) &&
    currentWallet === CURRENT_WALLET.WALLET_CONNECT
  ) {
    return { connector: walletConnectETH, hooks: hooksWalletConnectETH };
  }
  if (
    (chainId === BSCTestnetChainIdDec || chainId === BSCMainnetChainIdDec) &&
    currentWallet === CURRENT_WALLET.WALLET_CONNECT
  ) {
    return { connector: walletConnectBSC, hooks: hooksWalletConnectBSC };
  }
};

export const getConnectorWithHooks = (chainId: string | number, currentWallet: string) => {
  const objWallet = {
    [CURRENT_WALLET.METAMASK]: {
      connector: metaMask,
      hooks: hooksMetaMask,
    },
    [currentWallet]: getChain(chainId, currentWallet),
  };

  return objWallet[currentWallet] || {};
};

export const useConnectWallet = () => {
  const currentWallet = useSelector(selectedAuthen.selectedCurrentWallet);
  const network = useSelector((state: any) => state.HomeSlice.network);
  const connectorWithHooks = getConnectorWithHooks(network, currentWallet) || {};
  const w2c = getConnectorWithHooks(network, CURRENT_WALLET.WALLET_CONNECT);

  const { connector: walletConnector, hooks }: any = connectorWithHooks;
  const { connector: walletConnectV2, _hooksV2 }: any = w2c;

  const connect = useMemo(() => {
    return {
      connectInjected(metamaskNotFound?: any, callbackSuccess?: any, callbackError?: any): void {
        // injected.isAuthorized().then(async (isAuthorized: boolean) => {
        metaMask?.connectEagerly().then(async () => {
          callbackSuccess && callbackSuccess();
          onConnectWallet(metaMask, network).catch((error) => {
            console.log('error Meta mask:>>', error);
            callbackError && callbackError();
            getErrorConnectMessage(error, metaMask.deactivate, metamaskNotFound);
          });
        });
      },

      connectWalletConnect(): void {
        walletConnectV2 &&
          onConnectWallet(walletConnectV2, network).catch(async (error) => {
            console.log('error activate', error);

            getErrorConnectMessage(error, walletConnectV2.deactivate);
          });
      },

      connectDeactivate(): void {
        if (walletConnector) {
          if (walletConnector?.deactivate) {
            void walletConnector.deactivate();
            if (walletConnector.provider) {
              walletConnector.provider = undefined;
            }
          } else {
            void walletConnector.resetState();
          }
        }
      },
    };
  }, []);
  // re-connect when refresh

  useEffect(() => {
    walletConnector &&
      walletConnector.connectEagerly().catch((error: any) => {
        console.debug('Failed to connect eagerly to walletconnect', error);
      });
  }, []);
  return connect;
};

export function useInterval(callback: Function, interval: number | undefined) {
  const intervalId: MutableRefObject<null | number> = useRef(null);
  const handler = useMemo(() => {
    return {
      start(overrideInterval: number | undefined = undefined): void {
        handler.stop();
        intervalId.current = setInterval(callback, overrideInterval === undefined ? interval : overrideInterval);
      },

      stop(): void {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }
      },

      restart() {
        handler.stop();
        handler.start();
      },
    };
  }, [callback, interval]);

  useEffect(() => {
    return () => {
      handler.stop();
    };
  }, []);

  return handler;
}

export default { useConnectWallet, useInterval };
