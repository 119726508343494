import Web3 from 'web3';
import { AddressZero } from '@ethersproject/constants';
import BigNumber from 'bignumber.js';
import { formatUnits } from '@ethersproject/units';

import { Contract, ContractFactory } from '@ethersproject/contracts';

import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import peAbiApprove from '../common/abi/peAbiApprove.json';
import TokenJSON from '../common/abi/token.json';
import PePoolAbi from '../common/abi/peAbi.json';
import { maxBalance, nftStaking } from '../common/constant';
import { number, string } from 'prop-types';
import { captureError } from 'utils';

let instance: any;

export function getContractFactory(abi: any, bytecode: any, library: any, account: string): ContractFactory {
  const signer = library.getSigner(account);
  return new ContractFactory(abi, bytecode, signer);
}

export const checkEnoughBalance = (data: any, balance: any) => {
  return new BigNumber(balance).isGreaterThanOrEqualTo(new BigNumber(data.price).multipliedBy(data.quantity));
};

export const convertEToNumber = (value: any, number: any) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });

  return new BigNumber(value).dividedBy(new BigNumber(10).pow(number)).toNumber();
};

export function getProvider(library: any) {
  if (library) {
    return new Web3Provider(library);
  }
  return;
}

export function getSigner(library: any, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(library: any, account?: string): any | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function isAddress(address: string) {
  return Web3.utils.isAddress(address);
}

// account is optional
export function getContract(address: string, ABI: any, library: any, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export default class BaseWalletService {
  getInstance = () => {
    if (instance == null) {
      instance = new BaseWalletService();
      instance.constructor = null;
    }
    return instance;
  };

  /**
   * Check Buyer Balance
   * @param data
   * @returns
   */
  checkBuyerBalance = async (library: any, data: any, tokenAddress: string) => {
    if (tokenAddress) {
      const buyerAdress = data;
      const balances = await this._getBalanceBuyer(library, buyerAdress, tokenAddress);
      return balances;
    }
    return '';
  };

  /**
   * get Balance
   * @returns balance
   */
  _getBalanceBuyer = async (library: any, buyerAddress: string, tokenAddress: string) => {
    const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library);
    if (buyerAddress) {
      const balance = await tokenInst.balanceOf(buyerAddress);
      const decimals = await tokenInst.decimals();
      return {
        balance: convertEToNumber(formatUnits(balance, 'wei'), decimals),
        balanceString: balance.toString(),
      };
    } else {
      return {
        balance: 0,
        balanceString: '',
      };
    }
  };

  // approve Nft
  approveNft = async ({
    erc721Collection,
    currentAccount,
    library,
    nftExchangeAddress,
    callbackSuccess,
    callbackError,
  }: {
    erc721Collection: string;
    currentAccount: string;
    library: any;
    nftExchangeAddress: string;
    callbackSuccess: any;
    callbackError: any;
  }) => {
    if (!erc721Collection) {
      callbackError();
    }

    let contractCollectionInst;
    try {
      contractCollectionInst = getContract(erc721Collection, peAbiApprove.output.abi, library, currentAccount);

      const approveRes = await contractCollectionInst.setApprovalForAll(nftExchangeAddress, true);

      const receipt = await library.waitForTransaction(approveRes.hash);
      if (receipt.status === 1) {
        callbackSuccess(approveRes);
      } else {
        callbackError();
      }
    } catch (e) {
      callbackError(e);
    }
  };

  // is approve pool
  isApprovalForAll = async ({
    erc721Collection,
    currentAccount,
    library,
    poolAddress,
    callback,
  }: {
    erc721Collection: string;
    currentAccount: string;
    library: any;
    poolAddress: string;
    callback: any;
  }) => {
    if (!erc721Collection) {
      callback(false);
    }

    let contractCollectionInst;
    try {
      contractCollectionInst = getContract(erc721Collection, peAbiApprove.output.abi, library, currentAccount);

      const isApproved = await contractCollectionInst.isApprovedForAll(currentAccount, poolAddress);

      callback(isApproved);
    } catch (e) {
      console.log(e);
    }
  };

  // stake nft
  stakeNft = async ({
    poolId,
    contractAddress,
    currentAccount,
    library,
    signature,
    signer,
    salt,
    nft,
    stakeId,
    callbackSuccess,
    callbackProcessing,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    signature: string;
    signer: string;
    nft: any;
    salt: string;
    stakeId: string;
    callbackSuccess: any;
    callbackProcessing: any;
    callbackError: any;
  }) => {
    if (!contractAddress) {
      callbackError();
    }

    let contractPoolInst;
    try {
      const { tokenId, price } = nft;

      let priceBigNumber = new BigNumber(price).multipliedBy(new BigNumber(10).pow(18));

      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);

      const stakeRes = await contractPoolInst.stakeNft(
        [poolId, stakeId],
        [`0x${tokenId}`, priceBigNumber.toString(), salt],
        [signer],
        signature,
      );

      callbackProcessing({ txId: stakeRes.hash, stakeId });

      const receipt = await stakeRes.wait((res: any) => {
        console.log(`res`, res);
      });

      if (receipt.status) {
        callbackSuccess(stakeRes);
      } else {
        callbackError();
      }
    } catch (e) {
      callbackError(e);
    }
  };

  // unstake nft
  unstakeNft = async ({
    poolId,
    contractAddress,
    currentAccount,
    library,
    nft,
    callbackSuccess,
    callbackProcesing,
    callbackReject,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    nft: any;
    callbackSuccess: any;
    callbackProcesing: any;
    callbackReject: any;
    callbackError: any;
  }) => {
    if (!contractAddress) {
      callbackError();
    }

    let contractPoolInst;
    try {
      const { tokenId, _id } = nft;

      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);

      const unstakeRes = contractPoolInst.unstakeNft([poolId, _id], `0x${tokenId}`);

      await unstakeRes
        .then(async (e: any) => {
          callbackProcesing(e.hash);

          const receipt = await e.wait((res: any) => {
            console.log(`res`, res);
          });

          if (receipt.status) {
            callbackSuccess(unstakeRes);
          } else {
            callbackError();
          }
        })
        .catch((e: any) => {
          callbackError(e);
          if (e.code === 4001) {
            callbackReject(_id);
          }
        });
    } catch (e) {
      callbackError(e);
    }
  };

  // claim
  claimStaking = async ({
    poolId,
    type,
    contractAddress,
    currentAccount,
    library,
    data,
    callbackSuccess,
    callbackProcesing,
    callbackReject,
    callbackError,
  }: {
    poolId: string;
    type: String;
    contractAddress: string;
    currentAccount: string;
    library: any;
    data: any;
    callbackSuccess: any;
    callbackProcesing: any;
    callbackReject: any;
    callbackError: any;
  }) => {
    if (!contractAddress) {
      callbackError();
    }

    let contractPoolInst;
    try {
      const { tokenId, _id } = data;

      let claimRes;
      let receipt;

      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);

      if (type === nftStaking) {
        claimRes = contractPoolInst.claimReward([poolId, _id], [0, `0x${tokenId}`]);
      } else {
        claimRes = contractPoolInst.claimReward([poolId, _id]);
      }

      await claimRes
        .then(async (e: any) => {
          callbackProcesing(e.hash);

          receipt = await e.wait((res: any) => {
            console.log(`res`, res);
          });

          if (receipt.status) {
            callbackSuccess(e.hash);
          } else {
            callbackError();
          }
        })
        .catch((e: any) => {
          captureError(e, { poolId, address: currentAccount });
          callbackReject(_id);

          callbackError(e);
          if (e.code === 4001) {
            callbackReject(_id);
          }
        });
    } catch (e) {
      captureError(e, { poolId, address: currentAccount });
      callbackError(e);
    }
  };

  // is claim staking
  isClaimStaking = async ({
    poolId,
    type,
    contractAddress,
    currentAccount,
    library,
    nft,
    callback,
  }: {
    poolId: string;
    type: String;
    contractAddress: string;
    currentAccount: string;
    library: any;
    nft: any;
    callback: any;
  }) => {
    let contractPoolInst;
    try {
      let canClaimRes;

      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);

      if (type === nftStaking) {
        const { tokenId } = nft;

        if (!tokenId) return callback(false);
        canClaimRes = await contractPoolInst.canGetReward(poolId, `0x${tokenId}`, 0);
      } else {
        canClaimRes = await contractPoolInst.canGetReward(poolId);
      }

      callback(canClaimRes);
    } catch (e) {
      callback(false);
    }
  };

  // get reward claim
  rewardClaim = async ({
    pool,
    type,
    contractAddress,
    currentAccount,
    library,
    callback,
  }: {
    pool: any;
    type: String;
    contractAddress: string;
    currentAccount: string;
    library: any;
    callback: any;
  }) => {
    let contractPoolInst: any;
    let data = { earned: 0, listEarned: [{}] };

    try {
      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);
      if (type === nftStaking) {
        if (!pool.nftstakings || (pool.nftstakings && !pool.nftstakings.length)) return callback(data);
        for (let index = 0; index < pool.nftstakings.length; index++) {
          let rewardClaim = await contractPoolInst.earned(
            pool._id,
            currentAccount,
            `0x${pool.nftstakings[index].tokenId}`,
            0,
          );

          data.earned = +rewardClaim.toString() + data.earned;
          data.listEarned.push({ id: pool.nftstakings[index]._id, earned: rewardClaim.toString() });
        }
      } else {
        let rewardClaim = await contractPoolInst.earned(pool._id, currentAccount);
        data.earned = +rewardClaim._hex.toString();
      }

      callback(data);
    } catch (e) {
      callback(false);
    }
  };

  // check is allowance balance
  isAllowance = async ({
    tokenAddress,
    library,
    account,
    contractAddress,
    callback,
  }: {
    tokenAddress: string;
    library: any;
    account: string;
    contractAddress: string;
    callback: any;
  }) => {
    const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library, account);

    const balances = await this.checkBuyerBalance(library, account, tokenAddress);
    const allowance = await tokenInst.allowance(account, contractAddress);
    let checkAllowance =
      balances &&
      new BigNumber(+balances.balance).multipliedBy(new BigNumber(10).pow(18)).comparedTo(allowance.toString());
    callback(checkAllowance !== 1);
    return checkAllowance !== 1;
  };

  // approve Token
  approveToken = async ({
    tokenAddress,
    library,
    account,
    contractAddress,
    callbackError,
    callbackSuccess,
  }: {
    tokenAddress: string;
    library: any;
    account: string;
    contractAddress: string;
    callbackError: any;
    callbackSuccess: any;
  }) => {
    try {
      const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library, account);

      const approveToken = await tokenInst.approve(contractAddress, maxBalance);

      const receipt = await approveToken.wait((res: any) => {
        console.log(`res`, res);
      });

      if (receipt.status) {
        callbackSuccess(approveToken);
      } else {
        callbackError();
      }
    } catch (e) {
      callbackError(e);
    }
  };

  // stake token
  stakeToken = async ({
    poolId,
    contractAddress,
    currentAccount,
    library,
    amount,
    stakeId,
    callbackSuccess,
    callbackProcesing,
    callbackReject,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    amount: string;
    stakeId: string;
    callbackSuccess: any;
    callbackProcesing: any;
    callbackReject: any;
    callbackError: any;
  }) => {
    if (!contractAddress) {
      callbackError();
    }

    let contractPoolInst;
    try {
      let priceBigNumber =
        typeof amount === 'string' ? amount : new BigNumber(+amount).multipliedBy(new BigNumber(10).pow(18));

      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);

      const stakeRes = contractPoolInst.stakeToken([poolId, stakeId], priceBigNumber.toString());

      console.log('data:', [poolId, stakeId], priceBigNumber.toString());

      stakeRes
        .then(async (e: any) => {
          callbackProcesing({ txId: e.hash, stakeId });

          const receipt = await e.wait((res: any) => {
            console.log(`res`, res);
          });

          if (receipt.status) {
            callbackSuccess();
          } else {
            callbackError();
          }
        })
        .catch((e: any) => {
          captureError(e, { poolId, address: currentAccount });
          callbackError(e);
          if (e.code === 4001) {
            callbackReject(stakeId);
          }
        });
    } catch (e) {
      captureError(e, { poolId, address: currentAccount });
      callbackError(e);
    }
  };

  // unstake token
  unstakeToken = async ({
    poolId,
    contractAddress,
    currentAccount,
    library,
    amount,
    stakeId,
    callbackSuccess,
    callbackProcesing,
    callbackReject,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    amount: string;
    stakeId: string;
    callbackSuccess: any;
    callbackProcesing: any;
    callbackReject: any;
    callbackError: any;
  }) => {
    if (!contractAddress) {
      callbackError();
    }

    let contractPoolInst;
    try {
      let priceBigNumber =
        typeof amount === 'string' ? amount : new BigNumber(+amount).multipliedBy(new BigNumber(10).pow(18));

      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);

      const unstakeRes = contractPoolInst.unstakeToken([poolId, stakeId], priceBigNumber.toString());
      console.log('data:', [poolId, stakeId], priceBigNumber.toString());

      unstakeRes
        .then(async (e: any) => {
          callbackProcesing(e.hash);

          const receipt = await e.wait((res: any) => {
            console.log(`res`, res);
          });

          if (receipt.status) {
            callbackSuccess(unstakeRes);
          } else {
            callbackError();
          }
        })
        .catch((e: any) => {
          captureError(e, { poolId, address: currentAccount });
          callbackError(e);
          if (e.code === 4001) {
            callbackReject(stakeId);
          }
        });
    } catch (e) {
      callbackError(e);
      captureError(e, { poolId, address: currentAccount });
    }
  };
  getMaxAmountStake = async ({
    poolId,
    contractAddress,
    currentAccount,
    library,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
  }) => {
    let contractPoolInst;
    try {
      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);

      const res = await contractPoolInst.tokenStakingData(poolId, currentAccount);

      return {
        stakedBalance: convertEToNumber(formatUnits(res?.balance, 'wei'), 18),
        stakedBalanceString: res?.balance?.toString(),
      };
    } catch (e) {}
  };
  getApr = async ({
    library,
    poolId,
    contractAddress,
    currentAccount,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    callbackError: any;
  }) => {
    let contractPoolInst;
    try {
      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);
      const res = await contractPoolInst.apr(poolId);
      return res.toString();
    } catch (e) {
      callbackError();
    }
  };
  getPoolInfo = async ({
    library,
    poolId,
    contractAddress,
    currentAccount,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    callbackError: any;
  }) => {
    let contractPoolInst;
    try {
      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);
      const res = await contractPoolInst.poolInfo(poolId);
      const { stakedBalance, rewardFund, initialFund } = res;
      return { stakedBalance, rewardFund, initialFund };
    } catch (e) {
      callbackError();
    }
  };
  getStakeBalancedPerUser = async ({
    poolId,
    contractAddress,
    currentAccount,
    library,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    callbackError: any;
  }) => {
    try {
      const contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);
      const res = await contractPoolInst.stakedBalancePerUser(poolId, currentAccount);
      return res;
    } catch (error) {
      callbackError();
    }
  };
  getRewardPerSecond = async ({
    poolId,
    contractAddress,
    currentAccount,
    library,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    callbackError: any;
  }) => {
    try {
      const contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);
      const res = await contractPoolInst.rewardPerToken(poolId);
      return res;
    } catch (error) {
      callbackError();
    }
  };
  getMaxTVL = async ({
    library,
    poolId,
    contractAddress,
    currentAccount,
    callbackError,
  }: {
    poolId: string;
    contractAddress: string;
    currentAccount: string;
    library: any;
    callbackError: any;
  }) => {
    let contractPoolInst;
    try {
      contractPoolInst = getContract(contractAddress, PePoolAbi.output.abi, library, currentAccount);
      const res = await contractPoolInst.showMaxTVL(poolId);
      return res;
    } catch (e) {
      callbackError();
    }
  };
  claimReward1155NFT = async ({
    library,
    signer,
    account,
    contractAddress,
    nftAddress,
    tokenId,
    signature,
    poolId,
    internalTx,
    callbackSuccess,
    callbackError,
    callbackProcessing,
  }: {
    library: any;
    signer: string;
    account: string;
    contractAddress: string;
    nftAddress: string;
    tokenId: string;
    signature: string;
    poolId: string;
    internalTx: string;
    callbackSuccess: () => void;
    callbackError: () => void;
    callbackProcessing: (hash: string) => void;
  }) => {
    try {
      const poolContract = getContract(contractAddress, PePoolAbi.output.abi, library, account);
      const claimRes = await poolContract.claimReward1155NFT(signer, account, nftAddress, tokenId, signature, [
        poolId,
        internalTx,
      ]);
      if (claimRes) {
        callbackProcessing(claimRes.hash);
        const receipt = await claimRes.wait();
        if (receipt.status) callbackSuccess();
        else callbackError();
      }
    } catch (error) {
      captureError(error, { poolId, address: account });
      callbackError();
    }
  };
  claimReward721NFT = async ({
    library,
    signer,
    account,
    contractAddress,
    nftAddress,
    tokenId,
    signature,
    poolId,
    internalTx,
    callbackSuccess,
    callbackError,
    callbackProcessing,
  }: {
    library: any;
    signer: string;
    account: string;
    contractAddress: string;
    nftAddress: string;
    tokenId: string;
    signature: string;
    poolId: string;
    internalTx: string;
    callbackSuccess: () => void;
    callbackError: () => void;
    callbackProcessing: (hash: string) => void;
  }) => {
    try {
      const poolContract = getContract(contractAddress, PePoolAbi.output.abi, library, account);
      const claimRes = await poolContract.claimReward721NFT(signer, account, nftAddress, tokenId, signature, [
        poolId,
        internalTx,
      ]);
      if (claimRes) {
        callbackProcessing(claimRes.hash);
        const receipt = await claimRes.wait();
        if (receipt.status) callbackSuccess();
        else callbackError();
      }
    } catch (error) {
      callbackError();
      captureError(error, { poolId, address: account });
    }
  };
}
