import { api } from './api';

export const getListPoolApi = (params) => {
  return api.get('/pool/user/nft', params);
};

export const getListNftsApi = (params) => {
  return api.get('/pool/detail', params);
};

export const getListNftsStakingApi = (params) => {
  return api.get('/pool/my-staking', params);
};

export const stakingApi = (params) => {
  return api.post('/staking/stake-nft', params);
};

export const updateTxidStakingApi = (params) => {
  return api.put('staking/update-txid-stake', params);
};

export const signStakingApi = (params) => {
  return api.post('/staking/signature', params);
};

export const unStakingApi = (params) => {
  return api.post('/staking/unstaking', params);
};

export const claimgApi = (params) => {
  return api.post('/staking/claim', params);
};

export const updateActionStatus = (params) => {
  return api.put('/staking/update-action-status-unstake', params);
};

export const revertUnstakeStatus = (params) => {
  return api.post('/staking/revert-action-status-stake', params);
};

export const updateClaimStatus = (params) => {
  return api.put('/staking/update-action-status-claim', params);
};
