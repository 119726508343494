import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import ConfirmSuccess from 'components/ConfirmSuccess';
import ButtonPE from 'components/ButtonPE';
import ModalPE from 'components/Modal';
import NumberFormat from 'components/NumberFormat';
import showMessage from 'components/Message';
import { tokenStaking, EVENT_NAME_SOCKET, TYPE_OF_ANT_DESIGN } from 'common/constant';
import { SocketContext } from 'context/socket';
import BaseWalletService, { getProvider } from 'utils/baseWallet';
import selectedAuthen from 'redux/authen/selector';
import { handleRevertUnstake, handleUpdateTxidStakingRequest, handleUpdateClaim } from 'redux/nftStaking/slice';

const ClaimButton = ({
  activeKey,
  setVisibleLoaingContract,
  pool,
  earnedToken,
  dataSearch,
  poolAddress,
  setDataSearch,
}) => {
  const { connector } = useWeb3React();
  const library = getProvider(connector?.provider);
  const wallet = new BaseWalletService().getInstance();
  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);
  const { t } = useTranslation();
  const socket = useContext(SocketContext);

  const [isClaim, setIsClaim] = useState(false);
  const [loadingActionClaim, setLoadingActionClaim] = useState(false);
  const [visibleConfirmSuccess, setConfirmSuccess] = useState(false);
  const [textSuccess, setTextSuccess] = useState('');
  const [textHash, setTxHash] = useState('');
  const [visibleClaim, setVisibleClaim] = useState(false);
  const [reloadIsClaim, setReloadIsClaim] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pool.tokenStakings) socket.on(`${EVENT_NAME_SOCKET.CLAIM}${pool.tokenStakings._id}`, callClaimSucsess);
    return () => {
      // clear listen
      if (pool.tokenStakings) socket.off(`${EVENT_NAME_SOCKET.CLAIM}${pool.tokenStakings._id}`, callClaimSucsess);
    };
  }, [socket, pool.tokenStakings]);

  useEffect(() => {
    setLoadingActionClaim(true);
    wallet.isClaimStaking({
      poolId: pool._id,
      type: tokenStaking,
      contractAddress: poolAddress,
      currentAccount: selectedAddressWallet,
      library,
      callback: (data) => {
        setIsClaim(data);
      },
    });
    setLoadingActionClaim(false);
  }, [activeKey[0], reloadIsClaim, dataSearch]);

  const handleClaim = async () => {
    setVisibleClaim(false);
    setVisibleLoaingContract(true);

    let data = {
      stakeId: pool?.tokenStakings?._id,
    };
    if (!data.stakeId) {
      showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.fetchDataError');
    } else
      dispatch(
        handleUpdateClaim({
          data,
          callback: callUpdateStatusSucsess,
          callbackFail,
        }),
      );
  };

  const callUpdateStatusSucsess = () => {
    wallet.claimStaking({
      poolId: pool._id,
      type: tokenStaking,
      contractAddress: poolAddress,
      currentAccount: selectedAddressWallet,
      library,
      data: pool.tokenStakings,
      callbackSuccess: (res) => {
        // call api claim.
      },
      callbackProcesing,
      callbackReject,
      callbackError: callbackFail,
    });
  };

  const callbackFail = (e) => {
    if (e?.code === 4000) message.error('An error occurred. Please try again later.');
    else if (e?.code === -32603 && e?.data?.code === 3) message.error(t('message.M29'));
    setVisibleLoaingContract(false);
    setVisibleClaim(false);
  };

  const callbackProcesing = (txId) => {
    setTxHash(txId);
    dispatch(
      handleUpdateTxidStakingRequest({
        txid: txId,
        idStake: pool.tokenStakings._id,
        callback: () => {},
        callbackFail: callbackFail,
      }),
    );
  };

  const callbackReject = (id) => {
    dispatch(
      handleRevertUnstake({
        data: { stakeId: id },
        callbackFail,
        callback: () => setVisibleLoaingContract(false),
      }),
    );
  };

  const callClaimSucsess = (res) => {
    setTextSuccess('Successfully claimed.');
    setVisibleLoaingContract(false);
    setTxHash(res.txId);
    setVisibleClaim(false);
    setReloadIsClaim(true);
    setConfirmSuccess(true);
  };

  const handleCallback = () => {
    setConfirmSuccess(false);
    setDataSearch({ ...dataSearch, uuid: uuidv4() });
  };

  const renderValueClaim = () => {
    let data = earnedToken.earned;

    return data;
  };

  return (
    <>
      <ButtonPE
        text="Claim"
        classNameStyle="btn-claim"
        variant="success"
        disabled={!isClaim || !earnedToken.earned}
        onClick={() => setVisibleClaim(true)}
        loading={loadingActionClaim}
      />

      <ModalPE
        visible={visibleClaim}
        onCancel={() => {
          setVisibleClaim(false);
        }}
        wrapClassName="model-claim-reward"
        width={448}
      >
        <p className="title-claime-reward">Congratulations!</p>
        <p className="receive">You have received</p>
        <div className="amount-token">
          {Math.floor(renderValueClaim() * Math.pow(10, -10)) === 0 ? (
            <div>
              <span>{renderValueClaim() * Math.pow(10, -18)}</span>
              <span>{` ${pool.currencyNameReward}`}</span>
            </div>
          ) : (
            <NumberFormat
              thousandSeparator
              value={renderValueClaim() * Math.pow(10, -18)}
              displayType="text"
              suffix={` ${pool.currencyNameReward}`}
              decimalScale={8}
            />
          )}
        </div>
        <ButtonPE
          text={t('nft-staking.txt_claim')}
          variant="default"
          onCLick={handleClaim}
          classNameStyle="btn-claim"
        />
      </ModalPE>
      <ConfirmSuccess
        onCancel={handleCallback}
        visible={visibleConfirmSuccess}
        title={`Success`}
        content={textSuccess}
        hash={textHash}
        textBtn="Done"
        onDone={handleCallback}
      />
    </>
  );
};

export default ClaimButton;
