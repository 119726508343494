import { createSlice, current } from '@reduxjs/toolkit';
import { NETWORK_DEFAULT } from 'common/constant';

const initialState = {
  summary: {
    totalAmountStaked: '0',
    totalAmountStakedUsd: 0,
    totalRewardClaimed: '0',
    totalRewardClaimedUsd: 0,
    zToken: 0,
    zTokenUsd: 0,
  },
  chains: [],
  network: NETWORK_DEFAULT,
};

export const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    handleGetSummaryRequest: (state, action) => {
      const { payload } = action;
      return { ...state };
    },
    handleGetSummaryResponse: (state, action) => {
      const { payload } = action;
      return { ...state, summary: payload.data };
    },

    handleGetChainsRequest: (state, action) => {
      const { payload } = action;
      return { ...state };
    },
    handleGetChainsResponse: (state, action) => {
      const { payload } = action;
      return { ...state, chains: payload.data };
    },
    handleChangeNetworkRequest: (state, action) => {
      const { payload } = action;
      const { network } = payload;

      return { ...state, network };
    },
  },
});

export const {
  handleGetSummaryRequest,
  handleGetSummaryResponse,
  handleGetChainsRequest,
  handleGetChainsResponse,
  handleChangeNetworkRequest,
} = HomeSlice.actions;

export default HomeSlice.reducer;
