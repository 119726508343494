import { all } from 'redux-saga/effects';
import watchAuthenSaga from './authen/saga';
import watchHomeSaga from './home/saga';
import watchNftStakingSaga from './nftStaking/saga';
import watchTokenStakingSaga from './tokenStaking/saga';

function* rootSaga() {
  yield all([watchAuthenSaga(), watchHomeSaga(), watchNftStakingSaga(), watchTokenStakingSaga()]);
}
export default rootSaga;
