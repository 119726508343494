import { Radio } from 'antd';
import React from 'react';

const SwitchComponent = ({ checked, data, onChange, ...props }) => {

  const handleChange = (e) => {
    onChange && onChange(e.target.value);
  };

  return (
    <div className="switch-wrap">
      <Radio.Group onChange={handleChange} defaultValue={checked} {...props}>
        {
          data.map((e, index) => (
            <Radio.Button key={index} value={e.value}>{e.name}</Radio.Button>)
          )
        }

      </Radio.Group>
    </div>
  )
}

export default SwitchComponent
