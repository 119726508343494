import { CURRENT_WALLET } from 'common/constant';

export const requestSupportNetwork = async (walletName: string, chains: any, chainId?: number) => {
  const isSupportChangeNetwork = walletName ? walletName === CURRENT_WALLET.METAMASK : true;

  const provider = isSupportChangeNetwork ? (window as any).ethereum : null;
  if (provider) {
    try {
      const networkInfo = chainId && chains[Number(chainId)];

      if (isSupportChangeNetwork && networkInfo) {
        try {
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: networkInfo?.chainId }],
          });
        } catch (error: any) {
          console.log('error:', error);

          //Reject metamask
          if (error.code === 4001) {
            return;
          }

          // This error code indicates that the chain has not been added to MetaMask.
          if (error.code === 4902) {
            try {
              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    ...(networkInfo || {}),
                  },
                ],
              });
            } catch (addError) {
              // showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E35');
            }
          } else {
            // showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E35');
          }
        }
      }
    } catch (e) {
      // showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E35');
    }
  } else {
    // showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E35');
  }
};
