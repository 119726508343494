import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { message } from 'antd';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { EVENT_NAME_SOCKET, finish, POOL_REWARD_TYPE } from 'common/constant';
import ButtonPE from 'components/ButtonPE';
import ConfirmSuccess from 'components/ConfirmSuccess';
import ModalPE from 'components/Modal';
import { SocketContext } from 'context/socket';
import selectedAuthen from 'redux/authen/selector';
import { handleRevertUnstake, handleUpdateTxidStakingRequest } from 'redux/nftStaking/slice';
import selectedToken from 'redux/tokenStaking/selector';
import { handleGetPoolTokensResponse, handleUpdateUnstake } from 'redux/tokenStaking/slice';
import BaseWalletService, { getProvider } from 'utils/baseWallet';

const UnStakeButton = ({
  setVisibleLoaingContract,
  pool,
  values,
  dataSearch,
  setDataSearch,
  resetFieldValue,
  stakedAmount,
  poolAddress,
}) => {
  const wallet = new BaseWalletService().getInstance();
  const { connector } = useWeb3React();
  const library = getProvider(connector?.provider);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);
  const listPoolTokens = useSelector(selectedToken.selectedpoolTokens);
  const socket = useContext(SocketContext);

  const [visibleConfirmSuccess, setConfirmSuccess] = useState(false);
  const [textSuccess, setTextSuccess] = useState('');
  const [textHash, setTxHash] = useState('');
  const [visibleUnstake, setVisibleUnstake] = useState(false);
  const [isUnstakeDisabled, setIsUnstakeDisabled] = useState(false);

  useEffect(() => {
    if (pool.tokenStakings) socket.on(`${EVENT_NAME_SOCKET.UNSTAKE}${pool.tokenStakings._id}`, handleSuccessUnstake);
    return () => {
      // clear listen
      if (pool.tokenStakings) socket.off(`${EVENT_NAME_SOCKET.UNSTAKE}${pool.tokenStakings._id}`, handleSuccessUnstake);
    };
  }, [socket, pool.tokenStakings]);

  const onHandleUnStake = async () => {
    setVisibleUnstake(false);
    setVisibleLoaingContract(true);

    let data = {
      stakeId: pool.tokenStakings._id,
      amountUnStake: pushMaxAmount(values.amountUnStake, stakedAmount.stakedBalance, true),
    };

    dispatch(handleUpdateUnstake({ data, callback: callbackUpdateStatusUnstake, callbackFail }));
  };

  useEffect(() => {
    if (pool?.tokenStakings) {
      if (pool?.isStop) setIsUnstakeDisabled(false);
      else {
        const isStakeFinished = moment().isBefore(moment(pool?.tokenStakings?.updatedAt).add(pool?.duration, 'days'));
        setIsUnstakeDisabled(isStakeFinished);
      }
    }
  }, [pool]);

  const pushMaxAmount = (amount, amountMax, callApi) => {
    if (amount >= amountMax || amountMax - amount <= 5 * Math.pow(10, -8))
      return callApi ? amountMax : stakedAmount.stakedBalanceString;
    return amount;
  };

  const callbackUpdateStatusUnstake = async () => {
    await wallet.unstakeToken({
      poolId: pool._id,
      tokenAddress: pool.stakeToken.tokenId,
      library,
      currentAccount: selectedAddressWallet,
      amount: pushMaxAmount(values.amountUnStake, stakedAmount.stakedBalance),
      contractAddress: poolAddress,
      stakeId: pool.tokenStakings._id,
      callbackSuccess: (res) => {},
      callbackProcesing,
      callbackReject,
      callbackError: callbackFail,
    });
  };

  const callbackProcesing = (txId) => {
    setTextSuccess('Successfully unstaked.');
    setTxHash(txId);
    dispatch(
      handleUpdateTxidStakingRequest({ txid: txId, idStake: pool.tokenStakings._id, callback: () => {}, callbackFail }),
    );
  };

  const callbackReject = (stakeId) => {
    let data = {
      stakeId,
    };

    dispatch(handleRevertUnstake({ data, callback: () => setVisibleLoaingContract(false), callbackFail }));
  };

  const callbackFail = (e) => {
    setVisibleLoaingContract(false);
    if (e.code === 4000) message.error('An error occurred. Please try again later.');
    else if (e.code === -32603 && e.data.code === 3) message.error(t('message.M29'));
  };

  const handleSuccessUnstake = (res) => {
    setVisibleLoaingContract(false);
    setConfirmSuccess(true);
    setVisibleUnstake(false);
    resetFieldValue();
    setDataPoolStakeDone(res);
  };

  const setDataPoolStakeDone = (res) => {
    let listPoolClone = JSON.parse(JSON.stringify(listPoolTokens.records));
    let poolClone = JSON.parse(JSON.stringify(pool));
    poolClone.tokenStakings = res;
    const index = listPoolClone.findIndex((e) => e._id === poolClone._id);
    listPoolClone[index] = poolClone;

    dispatch(
      handleGetPoolTokensResponse({
        data: { records: listPoolClone, total: listPoolTokens.total },
        loadMore: false,
      }),
    );
  };

  const handleCallback = () => {
    setConfirmSuccess(false);
    setDataSearch({ ...dataSearch, uuid: uuidv4() });
  };
  const renderTextConfirm = () => {
    let data;
    if (
      (pool.rewardType === POOL_REWARD_TYPE.NFT &&
        values.amountUnStake === stakedAmount.stakedBalance &&
        !pool?.rewardClaim) ||
      (pool.rewardType === POOL_REWARD_TYPE.NFT &&
        values.amountUnStake === stakedAmount.stakedBalance &&
        !pool?.rewardClaim &&
        dataSearch.status === finish)
    )
      data = 'Please test your luck before unstaking token. Otherwise, your chance of random NFT will be lost.';
    else if (
      (pool.rewardType === POOL_REWARD_TYPE.TOKEN && values.amountUnStake < stakedAmount.stakedBalance) ||
      (pool.rewardType === POOL_REWARD_TYPE.NFT &&
        pool?.rewardClaim &&
        values.amountUnStake < stakedAmount.stakedBalance)
    )
      data = `Are you sure to unstake ${values.amountUnStake} ${pool.currencyName}?`;
    else if (
      pool.rewardType === POOL_REWARD_TYPE.NFT &&
      values.amountUnStake < stakedAmount.stakedBalance &&
      !pool?.rewardClaim
    )
      data = `This will affect your current random NFT tier and decrease your chance to get NFT. Are you sure to unstake ${values.amountUnStake} ${pool.currencyName}?`;
    else if (
      dataSearch.status === finish ||
      (pool.rewardType === POOL_REWARD_TYPE.TOKEN && values.amountUnStake === stakedAmount.stakedBalance) ||
      (pool.rewardType === POOL_REWARD_TYPE.NFT &&
        pool?.rewardClaim &&
        values.amountUnStake === stakedAmount.stakedBalance)
    )
      data =
        ' The staking rewards you have earned will be transferred to your wallet address. Are you sure to unstake and claim rewards?';
    return data;
  };

  return (
    <>
      <ButtonPE
        text="Unstake"
        classNameStyle="btn-unstake"
        disabled={!values.amountUnStake || !pool.tokenStakings || isUnstakeDisabled}
        onClick={() => setVisibleUnstake(true)}
      />

      <ModalPE
        visible={visibleUnstake}
        onCancel={() => {
          setVisibleUnstake(false);
        }}
        wrapClassName="model-confirm-unstake"
      >
        <p className="title-confirm">Are you sure?</p>
        <p className="content">{renderTextConfirm()}</p>
        <div className="group-btn">
          <ButtonPE
            text={t('nft-staking.txt_cancel')}
            variant="default"
            onCLick={() => {
              setVisibleUnstake(false);
            }}
          />
          <ButtonPE text={t('nft-staking.txt_unstake')} onCLick={onHandleUnStake} classNameStyle="btn-unstake" />
        </div>
      </ModalPE>

      <ConfirmSuccess
        onCancel={handleCallback}
        visible={visibleConfirmSuccess}
        title={`Success`}
        content={textSuccess}
        hash={textHash}
        textBtn="Done"
        onDone={handleCallback}
      />
    </>
  );
};

export default UnStakeButton;
