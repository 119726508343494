import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { useWeb3React } from '@web3-react/core';
import { Divider, Popover, Tooltip } from 'antd';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import moment from 'moment';

import peAbi from 'common/abi/peAbi.json';
import {
  BREAKPOINT_SM,
  DATA_SEARCH_POOL_MECHANISM_TYPE,
  formatDate,
  numberToRound,
  POOL_REWARD_TYPE,
} from 'common/constant';
import CountdownTime from 'components/CountdownTime';
import NumberFormat from 'components/NumberFormat';
import selectedAuthen from 'redux/authen/selector';
import { useWindowSize } from 'utils';
import BaseWalletService, { getProvider } from 'utils/baseWallet';

import iconShow from 'resources/svg/icon-show.svg';
import IconDocument from 'resources/svg/IconDocument';

const PoolInfo = ({ data, onOpenModal, currentRpc, poolAddress }) => {
  const [width] = useWindowSize();

  const { connector, chainId } = useWeb3React();
  const library = getProvider(connector?.provider);
  const wallet = new BaseWalletService().getInstance();
  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);
  const network = useSelector((state) => state.HomeSlice.network);
  const chains = useSelector((state) => state.HomeSlice.chains);

  const [poolData, setPoolData] = useState({
    totalValueLocked: 0,
    myLiquidity: 0,
    rewardPerSecondToken: 0,
    rewardPerSecondUsd: 0,
  });

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setContext('Error Info', {
        poolId: data._id,
        address: selectedAddressWallet,
      });
    });
  }, [data]);

  useEffect(() => {
    const input = {
      library,
      poolId: data?._id,
      contractAddress: poolAddress,
      currentAccount: selectedAddressWallet,
      callbackError: (error) => {},
    };
    const getPoolInfo = async () => {
      const poolInfo = await Promise.all([wallet.getPoolInfo(input), wallet.getStakeBalancedPerUser(input)]);
      const totalValueLockedDecimal =
        (poolInfo[0]?.stakedBalance / Math.pow(10, +data?.stakeToken?.decimal)) * +data?.stakeToken?.exchangeRate;
      const myLiquidityDecimal =
        (poolInfo[1] / Math.pow(10, +data?.stakeToken?.decimal)) * +data?.stakeToken?.exchangeRate;
      const rewardPerSecondToken = +poolInfo[0]?.stakedBalance
        ? new BigNumber(poolInfo[0]?.initialFund.toString())
            .dividedBy(moment(data?.endDate).unix() - moment().unix())
            .dividedBy(Math.pow(10, 18))
            .toNumber()
        : 0;
      const rewardPerSecondUsd = rewardPerSecondToken * +data?.rewardToken?.exchangeRate;
      setPoolData({
        ...setPoolData,
        totalValueLocked: totalValueLockedDecimal,
        myLiquidity: myLiquidityDecimal,
        rewardPerSecondToken,
        rewardPerSecondUsd,
      });
    };
    if (selectedAddressWallet && connector && chainId === parseInt(network)) getPoolInfo();
  }, [data, connector, selectedAddressWallet, chainId]);

  useEffect(() => {
    const getPoolInfo = async () => {
      const web3Provider = new Web3(currentRpc);
      const contract = new web3Provider.eth.Contract(peAbi.output.abi, poolAddress);
      const poolInfo = await Promise.all([contract.methods.poolInfo(data._id).call()]);
      const totalValueLockedDecimal =
        (poolInfo[0].stakedBalance / Math.pow(10, +data?.stakeToken?.decimal)) * +data?.stakeToken?.exchangeRate;
      const rewardPerSecondToken = +poolInfo[0].stakedBalance
        ? new BigNumber(poolInfo[0].initialFund.toString())
            .dividedBy(moment(data?.endDate).unix() - moment().unix())
            .dividedBy(Math.pow(10, 18))
            .toNumber()
        : 0;
      const rewardPerSecondUsd = rewardPerSecondToken * +data?.rewardToken?.exchangeRate;
      setPoolData({
        ...setPoolData,
        totalValueLocked: totalValueLockedDecimal,
        rewardPerSecondToken,
        rewardPerSecondUsd,
      });
    };
    if (!connector && !selectedAddressWallet && currentRpc) {
      getPoolInfo();
    }
  }, [connector, data, selectedAddressWallet, currentRpc]);
  const isMobile = width < BREAKPOINT_SM;

  const networkSelected = useMemo(() => {
    const chain = chains.find((item) => item.chainId === network);

    return chain;
  }, [chains, network]);

  const renderTooltip = (data) => {
    return (
      <Tooltip title={data} placement="topLeft">
        {data}
      </Tooltip>
    );
  };

  const PopOverContent = (
    <div className="apr-popover__container">
      <div className="apr-popover__info">
        <p className="apr-popover__title">APR</p>

        <NumberFormat thousandSeparator value={numberToRound(data.apr, 2)} displayType="text" suffix={' %'} />
      </div>
      <div className="apr-popover__info">
        <p className="apr-popover__title">
          {data?.modelType === DATA_SEARCH_POOL_MECHANISM_TYPE.ALLOCATION ? 'Reward per second' : 'Reward Rate'}
        </p>
        {data?.modelType === DATA_SEARCH_POOL_MECHANISM_TYPE.ALLOCATION ? (
          <Tooltip
            title={
              <NumberFormat
                thousandSeparator
                value={poolData.rewardPerSecondToken}
                decimalScale={18}
                displayType="text"
                suffix={` ${data?.currencyNameReward}`}
              />
            }
          >
            <NumberFormat
              thousandSeparator
              value={poolData.rewardPerSecondToken}
              displayType="text"
              suffix={` ${data?.currencyNameReward}`}
              decimalScale={8}
            />
          </Tooltip>
        ) : (
          <span>
            1 {data?.currencyName} = {data?.rate} {data?.currencyNameReward}
          </span>
        )}
      </div>
      {data?.modelType === DATA_SEARCH_POOL_MECHANISM_TYPE.ALLOCATION && (
        <div className="apr-popover__usd">
          ~{' '}
          <Tooltip
            title={
              <NumberFormat
                value={poolData.rewardPerSecondUsd}
                thousandSeparator
                decimalScale={18}
                suffix=" USD"
                displayType="text"
              />
            }
          >
            <NumberFormat
              value={poolData.rewardPerSecondUsd}
              thousandSeparator
              decimalScale={8}
              suffix=" USD"
              displayType="text"
            />
          </Tooltip>
        </div>
      )}
      <Divider />
      <ul className="apr-popover__content">
        {data?.modelType === DATA_SEARCH_POOL_MECHANISM_TYPE.ALLOCATION ? (
          <>
            <li>
              The pool works under Allocation mechanism in which the more stakers the less reward can each staker
              receive.
            </li>
            <li>Reward will be calculated by Reward per second. The APR is for reference only.</li>
            <li>
              All figures are estimates provided for your convenience only, and by no means represent guaranteed
              returns.
            </li>
          </>
        ) : (
          <li>The pool works under Linear mechanism in which Rewards are calculated based on APR and reward rate</li>
        )}
      </ul>
    </div>
  );

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <div className="item-view-list">
        <div className="detail-item">
          <div className="name-item">
            {renderTooltip(data.name)}
            {data.rewardType === POOL_REWARD_TYPE.NFT && (
              <p className="view-nft" onClick={(e) => onOpenModal(e, data)}>
                View NFT Rewards
              </p>
            )}
          </div>
          <div className="view-title start">
            <p className="title">Start</p>
            <CountdownTime pool={data} className="content" />
          </div>
          <div className="view-title end">
            <p className="title">End</p>
            <p className="content">{moment(data.endDate).format(formatDate)}</p>
          </div>
          <div className="view-title apr">
            <p className="title">APR</p>
            <div className="content">
              {data?.apr !== null && data?.apr !== undefined ? (
                renderTooltip(
                  <NumberFormat
                    thousandSeparator
                    value={numberToRound(data.apr, 2)}
                    displayType="text"
                    suffix={' %'}
                  />,
                )
              ) : (
                <span>--</span>
              )}
              <Popover content={PopOverContent} title="ROI" trigger={['hover', 'click']} overlayClassName="apr-popover">
                <IconDocument />
              </Popover>
            </div>
          </div>
          <div className="view-title duration">
            <p className="title">Duration</p>
            <p className="content">{data.duration ? `${data.duration} days` : 'Flexible Time'}</p>
          </div>
          <div className="view-title liquidity">
            <p className="title">My Liquidity</p>
            <div className="content">
              {renderTooltip(
                <NumberFormat
                  thousandSeparator
                  value={numberToRound(poolData.myLiquidity, 2)}
                  displayType="text"
                  prefix={'$ '}
                />,
              )}
            </div>
          </div>
          <div className="view-title">
            <p className="title">Total Value Locked</p>
            <div className="content">
              {renderTooltip(
                <NumberFormat
                  thousandSeparator
                  value={numberToRound(poolData.totalValueLocked, 2)}
                  displayType="text"
                  prefix={'$ '}
                />,
              )}
            </div>
          </div>
          <div className="view-title contract">
            <p className="title">Contract</p>
            <a
              className="content"
              href={`${networkSelected?.blockExplorerUrls?.[0]}/address/${data?.contractAddress}`}
              target="_blank"
            >
              Here
            </a>
          </div>
          {!isMobile && (
            <div className="view-title detail">
              <p className="title">Detail</p>
              <div className="content">
                <img src={iconShow} />
              </div>
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default PoolInfo;
