import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  poolTokens: {
    total: 0,
    records: [],
  },
  listNftInPool: [],
  totalNftInPool: 0,
  isLoadingListNftInPool: false,
  loadingPoolToken: false,

  isApprovalForAllToken: false,
};

export const TokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    handleGetPoolTokensRequest: (state, action) => {
      const { payload } = action;
      return { ...state, loadingPoolToken: !payload.data.offset };
    },

    handleGetPoolTokensResponse: (state, action) => {
      const { payload } = action;
      const { data, loadMore } = payload;
      const { poolTokens } = current(state);

      let poolsData = loadMore ? { ...poolTokens, records: [...data.records], total: data.total } : data;

      return { ...state, poolTokens: poolsData, loadingPoolToken: false };
    },
    handleGetPoolTokenFail: (state) => {
      return { ...state, loadingPoolToken: false };
    },

    handleSetIsApprovepoolToken: (state, action) => {
      const { payload } = action;

      return { ...state, isApprovalForAllToken: payload };
    },

    handleStakingZTokenRequest: (state, action) => {
      return { ...state };
    },

    handleUnStakingZTokenRequest: (state, action) => {
      return { ...state };
    },
    handleClaimTokenRequest: (state, action) => {
      return { ...state };
    },
    handleUpdateUnstake: (state, action) => {
      return { ...state };
    },
    handleResetState: (state) => {
      return { ...initialState };
    },
    handleGetListNftInPoolStart: (state) => {
      state.isLoadingListNftInPool = true;
    },
    handleGetListNftInPoolSuccess: (state, action) => {
      const { payload } = action;
      state.listNftInPool = payload.items;
      state.totalNftInPool = payload.total;
      state.isLoadingListNftInPool = false;
    },
    handleGetListNftInPoolFail: (state) => {
      state.isLoadingListNftInPool = false;
    },
  },
});

export const {
  handleGetPoolTokensRequest,
  handleGetPoolTokensResponse,
  handleGetPoolTokenFail,
  handleSetIsApprovepoolToken,
  handleStakingZTokenRequest,
  handleUnStakingZTokenRequest,
  handleClaimTokenRequest,
  handleUpdateUnstake,
  handleResetState,
  handleGetListNftInPoolStart,
  handleGetListNftInPoolSuccess,
  handleGetListNftInPoolFail,
} = TokenSlice.actions;

export default TokenSlice.reducer;
