import React, { memo, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { routes, privateRoutes } from 'Routes';
import { Layout } from 'antd';
import HeaderCommon from 'components/Header';
import { useDispatch } from 'react-redux';
import { handleGetChainsRequest } from 'redux/home/slice';
// import FooterComponent from 'components/Footer';

const { Content } = Layout;

function LayoutCommon() {
  const dispatch = useDispatch();
  const token = null;

  useEffect(() => {
    dispatch(handleGetChainsRequest({}));
  }, []);

  return (
    <Layout className="layout">
      <Layout>
        <HeaderCommon />
        <Content>
          <Switch>
            {token
              ? privateRoutes.map((route, index) => (
                  <Route path={route.path} component={route.component} exact={route.exact} key={index} />
                ))
              : routes.map((route, index) => (
                  <Route path={route.path} component={route.component} exact={route.exact} key={index} />
                ))}
          </Switch>
        </Content>
        {/* <FooterComponent /> */}
      </Layout>
    </Layout>
  );
}
export default memo(withRouter(LayoutCommon));
