import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  handleGetPoolTokensRequest,
  handleGetPoolTokensResponse,
  handleStakingZTokenRequest,
  handleUnStakingZTokenRequest,
  handleClaimTokenRequest,
  handleUpdateUnstake,
  handleGetPoolTokenFail,
  handleResetState,
  handleGetListNftInPoolFail,
  handleGetListNftInPoolSuccess,
  handleGetListNftInPoolStart,
} from './slice';

import {
  getListPoolTokenApi,
  stakeTokenApi,
  unStakeTokenApi,
  claimTokenApi,
  updateUnstakeTokenApi,
  getListPoolTokenApiUnauthorized,
  getNftRewardInPoolApi,
} from 'services/tokenStaking';

// list poolTokens

export function* handleGetPoolTokensSaga(action) {
  const { payload } = action;
  const { data, isAuthorized } = payload;

  try {
    let response;
    if (isAuthorized) {
      response = yield getListPoolTokenApi(data);
    } else response = yield getListPoolTokenApiUnauthorized(data);
    if (response?.meta?.code === 0) {
      yield put(
        handleGetPoolTokensResponse({
          data: response?.data,
          loadMore: data.offset,
        }),
      );
    } else {
      if (response.statusCode === 401) {
        yield put(handleResetState());
      } else yield put(handleGetPoolTokenFail());
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// staking token
export function* handleStakingZTokenSaga(action) {
  const { payload } = action;
  const { data, callback, callbackFail } = payload;

  try {
    const response = yield stakeTokenApi(data);
    if (response?.meta?.code === 0) {
      callback(response.data._id);
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// unStaking token
export function* handleUnStakingZTokenSaga(action) {
  const { payload } = action;
  const { data, callback } = payload;

  try {
    const response = yield unStakeTokenApi(data);
    if (response?.meta?.code === 0) {
      callback(response.data);
    } else {
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// claim token
export function* handleClaimTokenSaga(action) {
  const { payload } = action;
  const { data, callback } = payload;

  try {
    const response = yield claimTokenApi(data);
    if (response?.meta?.code === 0) {
      callback();
    } else {
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// update status unstake
export function* handleUpdateUnstakeSaga(action) {
  const { payload } = action;
  const { data, callback, callbackFail } = payload;

  try {
    const response = yield updateUnstakeTokenApi(data);
    if (response?.meta?.code === 0) {
      callback();
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

function* handleGetListNftInPoolSaga(action) {
  const { payload } = action;
  try {
    const res = yield call(getNftRewardInPoolApi, payload.id, payload.params);
    if (res?.meta?.code) {
      yield put(handleGetListNftInPoolFail());
    } else yield put(handleGetListNftInPoolSuccess({ items: res.items, total: res.total }));
  } catch (error) {
    yield put(handleGetListNftInPoolFail());
  }
}

function* watchTokenStakingSaga() {
  yield all([takeLatest(handleGetPoolTokensRequest, handleGetPoolTokensSaga)]);
  yield all([takeLatest(handleStakingZTokenRequest, handleStakingZTokenSaga)]);
  yield all([takeLatest(handleUnStakingZTokenRequest, handleUnStakingZTokenSaga)]);
  yield all([takeLatest(handleClaimTokenRequest, handleClaimTokenSaga)]);
  yield all([takeLatest(handleUpdateUnstake, handleUpdateUnstakeSaga)]);
  yield all([takeLatest(handleGetListNftInPoolStart, handleGetListNftInPoolSaga)]);
}

export default watchTokenStakingSaga;
