import React from 'react';
import { Modal, Typography } from 'antd';

import ImageSuccess from 'resources/images/icon-success.png';
import ButtonPE from '../ButtonPE';
import { convertAddressWallet } from '../../utils';

const { Paragraph } = Typography;

const ConfirmSuccess = ({ hash, title, content, visible, textBtn, onDone, ...props }) => {
  return (
    <Modal visible={visible} footer={false} {...props} className="modal-confirm-success">
      <img className="icon" src={ImageSuccess} />
      <p className="title">{title}</p>
      <p className="content">{content}</p>
      <Paragraph className="tx-hash" copyable={{ text: hash }}>
        {convertAddressWallet(hash, 25)}
      </Paragraph>
      <ButtonPE text={textBtn} onCLick={onDone} />
    </Modal>
  );
};

export default ConfirmSuccess;
