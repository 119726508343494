import React from 'react';

const MenuOutlined = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M6.66699 9.3335H25.3337" stroke="#FF00A3" strokeLinecap="round" />
      <path d="M6.66699 16H25.3337" stroke="#FF00A3" strokeLinecap="round" />
      <path d="M6.66699 22.6665H25.3337" stroke="#FF00A3" strokeLinecap="round" />
    </svg>
  );
};

export default MenuOutlined;
