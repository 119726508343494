import * as React from 'react';

const EarnNft = (props) => (
  <svg width={75} height={86} viewBox="0 0 75 86" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_16968_7127)">
      <path d="M50.0304 83.3769H23.0869L30.7552 60.6082H42.6485L50.0304 83.3769Z" fill="#111111" />
      <path d="M23.0869 83.3769H23.263H24.8778L32.3114 60.6082H30.7552L23.0869 83.3769Z" fill="#606060" />
      <path d="M40.7732 60.6082H40.9492H42.5641L49.9953 83.3769H48.4415L40.7732 60.6082Z" fill="#444444" />
      <path d="M53.981 81.5963H17.8435V86.0001H53.981V81.5963Z" fill="#1E1E1E" />
      <path d="M19.3434 81.5963H17.8435V86.0001H19.3434V81.5963Z" fill="#606060" />
      <path d="M53.9808 81.5963H52.481V86.0001H53.9808V81.5963Z" fill="#444444" />
      <path
        d="M25.7371 75.5286C29.1335 76.826 32.8115 77.5377 36.6539 77.5377C40.4681 77.5377 44.1203 76.8379 47.4956 75.5596L36.7407 42.9549L25.7371 75.5286Z"
        fill="url(#paint0_radial_16968_7127)"
      />
      <path
        d="M35.9119 36.4206L38.1465 19.6315L40.381 2.84235H35.9119H31.4429L33.6774 19.6315L35.9119 36.4206Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4206L29.57 20.7479L23.2255 5.0752L19.3573 7.33898L15.4868 9.60514L25.6995 23.0141L35.9121 36.4206Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4206L22.6926 26.0634L9.47087 15.7062L7.23868 19.6315L5.00415 23.5544L20.4581 29.9887L35.9121 36.4206Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4206L19.3573 34.1545L2.80249 31.8883V36.4206V40.953L19.3573 38.6868L35.9121 36.4206Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4207L20.4581 42.8526L5.00415 49.2868L7.23868 53.2098L9.47087 57.1351L22.6926 46.7779L35.9121 36.4207Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4207L25.6995 49.8272L15.4868 63.2361L19.3573 65.4999L23.2255 67.7661L29.57 52.0934L35.9121 36.4207Z"
        fill="#E100A2"
      />
      <path
        d="M35.9119 36.4207L33.6774 53.2098L31.4429 69.9989H35.9119H40.381L38.1465 53.2098L35.9119 36.4207Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4207L42.2542 52.0934L48.5987 67.7661L52.4669 65.4999L56.3374 63.2361L46.1248 49.8272L35.9121 36.4207Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4207L49.1315 46.7779L62.3533 57.1351L64.5878 53.2098L66.82 49.2868L51.3661 42.8526L35.9121 36.4207Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4206L52.4669 38.6868L69.0217 40.953V36.4206V31.8883L52.4669 34.1545L35.9121 36.4206Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4206L51.3661 29.9887L66.82 23.5544L64.5878 19.6315L62.3533 15.7062L49.1315 26.0634L35.9121 36.4206Z"
        fill="#E100A2"
      />
      <path
        d="M35.9121 36.4206L46.1248 23.0141L56.3374 9.60514L52.4669 7.33898L48.5987 5.0752L42.2542 20.7479L35.9121 36.4206Z"
        fill="#E100A2"
      />
      <path
        d="M35.9122 36.4206L33.7857 19.6172L31.6591 2.81378L27.3426 3.98495L23.0261 5.1585L29.4692 20.7884L35.9122 36.4206Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9122 36.4206L25.7864 22.9474L15.6583 9.4718L12.4989 12.6759L9.3396 15.8799L22.6271 26.1514L35.9122 36.4206Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4207L20.4981 29.8864L5.0864 23.3521L3.92924 27.7297L2.77441 32.1073L19.3433 34.264L35.9121 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4207L19.3433 38.5773L2.77441 40.734L3.92924 45.1116L5.0864 49.4892L20.4981 42.9549L35.9121 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9122 36.4207L22.6271 46.6898L9.3396 56.9613L12.4989 60.1654L15.6583 63.3694L25.7864 49.8939L35.9122 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9122 36.4207L29.4692 52.0505L23.0261 67.6828L27.3426 68.8563L31.6591 70.0275L33.7857 53.2241L35.9122 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4207L38.0387 53.2241L40.1652 70.0275L44.4817 68.8563L48.7982 67.6828L42.3552 52.0505L35.9121 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4207L46.0379 49.8939L56.1661 63.3694L59.3254 60.1654L62.4847 56.9613L49.1973 46.6898L35.9121 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4207L51.3262 42.9549L66.7378 49.4892L67.895 45.1116L69.0498 40.734L52.481 38.5773L35.9121 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4207L52.481 34.264L69.0498 32.1073L67.895 27.7297L66.7378 23.3521L51.3262 29.8864L35.9121 36.4207Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4206L49.1973 26.1514L62.4847 15.8799L59.3254 12.6759L56.1661 9.4718L46.0379 22.9474L35.9121 36.4206Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9121 36.4206L42.3552 20.7884L48.7982 5.1585L44.4817 3.98495L40.1652 2.81378L38.0387 19.6172L35.9121 36.4206Z"
        fill="#FFC9FA"
      />
      <path
        d="M35.9615 7.60319C36.3944 7.60319 36.7454 7.24722 36.7454 6.80812C36.7454 6.36902 36.3944 6.01306 35.9615 6.01306C35.5285 6.01306 35.1775 6.36902 35.1775 6.80812C35.1775 7.24722 35.5285 7.60319 35.9615 7.60319Z"
        fill="#EDB9FF"
      />
      <path
        d="M22.0332 10.3549C22.2491 10.7358 22.1224 11.2214 21.7468 11.4404C21.3713 11.6594 20.8924 11.5309 20.6765 11.15C20.4605 10.7691 20.5873 10.2835 20.9628 10.0645C21.3384 9.84315 21.8172 9.97408 22.0332 10.3549Z"
        fill="#EDB9FF"
      />
      <path
        d="M11.041 20.8836C11.4166 21.1026 11.5457 21.5906 11.3274 21.969C11.1115 22.3499 10.6303 22.4808 10.2571 22.2595C9.88387 22.0381 9.75243 21.5525 9.97072 21.174C10.1867 20.7955 10.6655 20.6646 11.041 20.8836Z"
        fill="#EDB9FF"
      />
      <path
        d="M6.71292 37.1657C7.14589 37.1657 7.49688 36.8097 7.49688 36.3706C7.49688 35.9315 7.14589 35.5756 6.71292 35.5756C6.27995 35.5756 5.92896 35.9315 5.92896 36.3706C5.92896 36.8097 6.27995 37.1657 6.71292 37.1657Z"
        fill="#EDB9FF"
      />
      <path
        d="M10.2102 50.4961C10.5858 50.2771 11.0646 50.4056 11.2806 50.7865C11.4965 51.1674 11.3697 51.653 10.9942 51.872C10.6186 52.091 10.1398 51.9624 9.92387 51.5816C9.70558 51.2007 9.83468 50.7151 10.2102 50.4961Z"
        fill="#EDB9FF"
      />
      <path
        d="M20.592 61.6436C20.8079 61.2628 21.2891 61.1342 21.6623 61.3532C22.0379 61.5722 22.167 62.0602 21.9487 62.4387C21.7304 62.8172 21.2516 62.9505 20.8783 62.7291C20.5051 62.5101 20.3761 62.0245 20.592 61.6436Z"
        fill="#EDB9FF"
      />
      <path
        d="M35.865 66.8281C36.298 66.8281 36.649 66.4722 36.649 66.0331C36.649 65.594 36.298 65.238 35.865 65.238C35.432 65.238 35.0811 65.594 35.0811 66.0331C35.0811 66.4722 35.432 66.8281 35.865 66.8281Z"
        fill="#EDB9FF"
      />
      <path
        d="M49.791 62.4863C49.575 62.1054 49.7018 61.6198 50.0773 61.4008C50.4529 61.1818 50.9317 61.3104 51.1477 61.6912C51.3636 62.0721 51.2369 62.5577 50.8613 62.7767C50.4858 62.9981 50.0069 62.8671 49.791 62.4863Z"
        fill="#EDB9FF"
      />
      <path
        d="M60.7829 51.9576C60.4074 51.7386 60.2806 51.2506 60.4966 50.8722C60.7125 50.4913 61.1937 50.3604 61.5669 50.5817C61.9424 50.8007 62.0692 51.2887 61.8532 51.6672C61.6373 52.0457 61.1585 52.1766 60.7829 51.9576Z"
        fill="#EDB9FF"
      />
      <path
        d="M65.1111 37.2633C65.5441 37.2633 65.8951 36.9073 65.8951 36.4682C65.8951 36.0291 65.5441 35.6732 65.1111 35.6732C64.6781 35.6732 64.3271 36.0291 64.3271 36.4682C64.3271 36.9073 64.6781 37.2633 65.1111 37.2633Z"
        fill="#EDB9FF"
      />
      <path
        d="M61.614 22.3452C61.2384 22.5642 60.7596 22.4356 60.5437 22.0548C60.3277 21.6739 60.4545 21.1883 60.83 20.9693C61.2056 20.7503 61.6844 20.8788 61.9003 21.2597C62.1186 21.6406 61.9895 22.1262 61.614 22.3452Z"
        fill="#EDB9FF"
      />
      <path
        d="M50.8179 11.5463C51.2255 11.3982 51.4376 10.9431 51.2915 10.5297C51.1455 10.1163 50.6967 9.90128 50.2891 10.0494C49.8815 10.1975 49.6694 10.6527 49.8155 11.066C49.9615 11.4794 50.4103 11.6944 50.8179 11.5463Z"
        fill="#EDB9FF"
      />
      <path
        d="M28.4009 8.60056C28.8339 8.60056 29.1849 8.2446 29.1849 7.8055C29.1849 7.3664 28.8339 7.01044 28.4009 7.01044C27.9679 7.01044 27.6169 7.3664 27.6169 7.8055C27.6169 8.2446 27.9679 8.60056 28.4009 8.60056Z"
        fill="#910707"
      />
      <path
        d="M15.8553 14.8849C16.1604 15.1943 16.1604 15.699 15.8553 16.0084C15.5502 16.3179 15.0525 16.3179 14.7474 16.0084C14.4423 15.699 14.4423 15.1943 14.7474 14.8849C15.0525 14.5754 15.5478 14.5754 15.8553 14.8849Z"
        fill="#910707"
      />
      <path
        d="M7.92389 27.9415C8.34169 28.0558 8.59049 28.4914 8.47782 28.9151C8.36516 29.3388 7.93562 29.5912 7.51782 29.4769C7.10002 29.3626 6.85122 28.927 6.96388 28.5033C7.07655 28.0796 7.50608 27.8272 7.92389 27.9415Z"
        fill="#910707"
      />
      <path
        d="M7.49212 43.2691C7.90992 43.1548 8.33946 43.4071 8.45212 43.8309C8.56479 44.2546 8.31599 44.6902 7.89818 44.8044C7.48038 44.9187 7.05084 44.6664 6.93818 44.2427C6.82786 43.819 7.07432 43.3833 7.49212 43.2691Z"
        fill="#910707"
      />
      <path
        d="M14.6769 56.7613C14.982 56.4519 15.4796 56.4519 15.7847 56.7613C16.0899 57.0708 16.0899 57.5754 15.7847 57.8849C15.4796 58.1943 14.982 58.1943 14.6769 57.8849C14.3717 57.5754 14.3717 57.0732 14.6769 56.7613Z"
        fill="#910707"
      />
      <path
        d="M27.5513 64.8048C27.664 64.3811 28.0935 64.1288 28.5113 64.243C28.9291 64.3573 29.1779 64.7929 29.0652 65.2166C28.9526 65.6403 28.523 65.8926 28.1052 65.7784C27.6874 65.6641 27.4386 65.2285 27.5513 64.8048Z"
        fill="#910707"
      />
      <path
        d="M42.6648 65.2428C42.5521 64.8191 42.8009 64.3835 43.2187 64.2692C43.6366 64.1549 44.0661 64.4073 44.1788 64.831C44.2914 65.2547 44.0426 65.6903 43.6248 65.8046C43.207 65.9165 42.7775 65.6665 42.6648 65.2428Z"
        fill="#910707"
      />
      <path
        d="M55.9688 57.9563C55.6637 57.6468 55.6637 57.1422 55.9688 56.8327C56.274 56.5233 56.7716 56.5233 57.0767 56.8327C57.3818 57.1422 57.3818 57.6468 57.0767 57.9563C56.7716 58.2658 56.2763 58.2658 55.9688 57.9563Z"
        fill="#910707"
      />
      <path
        d="M63.8999 44.8997C63.4821 44.7854 63.2333 44.3498 63.346 43.9261C63.4586 43.5024 63.8882 43.25 64.306 43.3643C64.7238 43.4786 64.9726 43.9142 64.8599 44.3379C64.7472 44.7616 64.3177 45.0139 63.8999 44.8997Z"
        fill="#910707"
      />
      <path
        d="M64.3318 29.5721C63.914 29.6864 63.4845 29.434 63.3718 29.0103C63.2592 28.5866 63.508 28.151 63.9258 28.0367C64.3436 27.9225 64.7731 28.1748 64.8858 28.5985C64.9961 29.0222 64.7496 29.4578 64.3318 29.5721Z"
        fill="#910707"
      />
      <path
        d="M57.147 16.0775C56.8419 16.3869 56.3443 16.3869 56.0392 16.0775C55.734 15.768 55.734 15.2633 56.0392 14.9539C56.3443 14.6444 56.8419 14.6444 57.147 14.9539C57.4522 15.2657 57.4522 15.768 57.147 16.0775Z"
        fill="#910707"
      />
      <path
        d="M44.2728 8.03637C44.1601 8.46009 43.7305 8.71241 43.3127 8.59815C42.8949 8.48389 42.6461 8.04827 42.7588 7.62456C42.8715 7.20084 43.301 6.94852 43.7188 7.06278C44.1366 7.17704 44.3854 7.61265 44.2728 8.03637Z"
        fill="#910707"
      />
      <path
        d="M36.398 69.8918C54.4311 69.8918 69.0498 55.0661 69.0498 36.7777C69.0498 18.4893 54.4311 3.66357 36.398 3.66357C18.3648 3.66357 3.74609 18.4893 3.74609 36.7777C3.74609 55.0661 18.3648 69.8918 36.398 69.8918Z"
        fill="url(#paint1_radial_16968_7127)"
      />
      <path
        d="M35.9121 72.8411C16.1112 72.8411 0 56.5019 0 36.4206C0 16.3394 16.1112 0.00012207 35.9121 0.00012207C55.7131 0.00012207 71.8242 16.3394 71.8242 36.4206C71.8242 56.5019 55.7131 72.8411 35.9121 72.8411ZM35.9121 4.21109C18.3997 4.21109 4.15219 18.6603 4.15219 36.4206C4.15219 54.181 18.3997 68.6302 35.9121 68.6302C53.4246 68.6302 67.6721 54.181 67.6721 36.4206C67.6721 18.6603 53.4246 4.21109 35.9121 4.21109Z"
        fill="#952169"
      />
      <path
        d="M35.9122 72.4698C16.3131 72.4698 0.366211 56.2972 0.366211 36.4206C0.366211 16.5441 16.3131 0.37146 35.9122 0.37146C55.5113 0.37146 71.4581 16.5441 71.4581 36.4206C71.4581 56.2972 55.5113 72.4698 35.9122 72.4698ZM35.9122 0.607122C16.4398 0.607122 0.598583 16.6726 0.598583 36.4206C0.598583 56.1686 16.4398 72.2341 35.9122 72.2341C55.3845 72.2341 71.2257 56.1686 71.2257 36.4206C71.2257 16.6726 55.3845 0.607122 35.9122 0.607122Z"
        fill="#FCC5C5"
      />
      <path
        opacity={0.43}
        d="M35.9613 41.0244C37.9382 41.0244 39.5408 39.3991 39.5408 37.3942C39.5408 35.3894 37.9382 33.7641 35.9613 33.7641C33.9844 33.7641 32.3818 35.3894 32.3818 37.3942C32.3818 39.3991 33.9844 41.0244 35.9613 41.0244Z"
        fill="url(#paint2_radial_16968_7127)"
      />
      <path
        d="M35.9613 40.0817C37.9382 40.0817 39.5408 38.4564 39.5408 36.4516C39.5408 34.4467 37.9382 32.8214 35.9613 32.8214C33.9844 32.8214 32.3818 34.4467 32.3818 36.4516C32.3818 38.4564 33.9844 40.0817 35.9613 40.0817Z"
        fill="url(#paint3_radial_16968_7127)"
      />
      <path
        d="M35.9121 2.81381C36.2686 2.81381 36.5576 2.52073 36.5576 2.15919C36.5576 1.79766 36.2686 1.50458 35.9121 1.50458C35.5556 1.50458 35.2666 1.79766 35.2666 2.15919C35.2666 2.52073 35.5556 2.81381 35.9121 2.81381Z"
        fill="url(#paint4_linear_16968_7127)"
      />
      <path
        d="M27.725 3.17801C27.8166 3.52794 27.6124 3.885 27.2697 3.98022C26.9246 4.07305 26.5725 3.86596 26.4787 3.51842C26.3871 3.16849 26.5913 2.80905 26.934 2.71621C27.2791 2.62099 27.6311 2.82809 27.725 3.17801Z"
        fill="url(#paint5_linear_16968_7127)"
      />
      <path
        d="M19.4536 6.48202C19.632 6.79624 19.5264 7.19615 19.2165 7.37706C18.9067 7.55798 18.5124 7.45086 18.334 7.13664C18.1556 6.82242 18.2612 6.42251 18.5711 6.2416C18.8785 6.06069 19.2752 6.16781 19.4536 6.48202Z"
        fill="url(#paint6_linear_16968_7127)"
      />
      <path
        d="M12.3063 11.8452C12.5574 12.0999 12.5574 12.5164 12.3063 12.7711C12.0551 13.0259 11.6443 13.0259 11.3932 12.7711C11.142 12.5164 11.142 12.0999 11.3932 11.8452C11.6443 11.5881 12.0528 11.5881 12.3063 11.8452Z"
        fill="url(#paint7_linear_16968_7127)"
      />
      <path
        d="M6.76928 18.9007C7.07911 19.0816 7.18473 19.4815 7.00635 19.7958C6.82796 20.11 6.43363 20.2171 6.1238 20.0362C5.81397 19.8553 5.70835 19.4554 5.88673 19.1411C6.06747 18.8269 6.4618 18.7198 6.76928 18.9007Z"
        fill="url(#paint8_linear_16968_7127)"
      />
      <path
        d="M3.22497 27.1679C3.57001 27.2608 3.77422 27.6202 3.68033 27.9701C3.58879 28.3201 3.23436 28.5272 2.88932 28.4319C2.54429 28.3391 2.34008 27.9797 2.43397 27.6297C2.52551 27.2822 2.87993 27.0751 3.22497 27.1679Z"
        fill="url(#paint9_linear_16968_7127)"
      />
      <path
        d="M1.91062 37.3943C2.26711 37.3943 2.5561 37.1012 2.5561 36.7396C2.5561 36.3781 2.26711 36.085 1.91062 36.085C1.55413 36.085 1.26514 36.3781 1.26514 36.7396C1.26514 37.1012 1.55413 37.3943 1.91062 37.3943Z"
        fill="url(#paint10_linear_16968_7127)"
      />
      <path
        d="M2.9152 45.0426C3.26024 44.9497 3.61232 45.1568 3.70621 45.5044C3.79775 45.8543 3.59354 46.2114 3.25085 46.3066C2.90581 46.3994 2.55139 46.1923 2.45985 45.8448C2.36596 45.4948 2.57016 45.1354 2.9152 45.0426Z"
        fill="url(#paint11_linear_16968_7127)"
      />
      <path
        d="M6.17312 53.4312C6.48295 53.2503 6.87728 53.3574 7.05566 53.6716C7.23405 53.9858 7.12843 54.3857 6.8186 54.5666C6.50877 54.7476 6.11444 54.6404 5.93605 54.3262C5.75766 54.012 5.86329 53.6121 6.17312 53.4312Z"
        fill="url(#paint12_linear_16968_7127)"
      />
      <path
        d="M11.9167 61.7984C12.2732 61.7984 12.5622 61.5053 12.5622 61.1437C12.5622 60.7822 12.2732 60.4891 11.9167 60.4891C11.5602 60.4891 11.2712 60.7822 11.2712 61.1437C11.2712 61.5053 11.5602 61.7984 11.9167 61.7984Z"
        fill="url(#paint13_linear_16968_7127)"
      />
      <path
        d="M18.4185 66.2926C18.5969 65.9783 18.9912 65.8712 19.301 66.0521C19.6109 66.2331 19.7165 66.633 19.5381 66.9472C19.3597 67.2614 18.9654 67.3685 18.6555 67.1876C18.3457 67.0067 18.2401 66.6068 18.4185 66.2926Z"
        fill="url(#paint14_linear_16968_7127)"
      />
      <path
        d="M26.5702 69.8894C26.6618 69.5395 27.0162 69.3324 27.3612 69.4276C27.7063 69.5205 27.9105 69.8799 27.8166 70.2299C27.725 70.5798 27.3706 70.7869 27.0256 70.6917C26.6829 70.5964 26.4787 70.2394 26.5702 69.8894Z"
        fill="url(#paint15_linear_16968_7127)"
      />
      <path
        d="M36.0083 71.8771C36.3648 71.8771 36.6538 71.584 36.6538 71.2225C36.6538 70.861 36.3648 70.5679 36.0083 70.5679C35.6518 70.5679 35.3628 70.861 35.3628 71.2225C35.3628 71.584 35.6518 71.8771 36.0083 71.8771Z"
        fill="url(#paint16_linear_16968_7127)"
      />
      <path
        d="M44.1955 70.2037C44.1039 69.8537 44.3081 69.4967 44.6508 69.4015C44.9959 69.3086 45.3479 69.5157 45.4418 69.8633C45.5334 70.2132 45.3292 70.5726 44.9865 70.6655C44.6414 70.7583 44.2893 70.5512 44.1955 70.2037Z"
        fill="url(#paint17_linear_16968_7127)"
      />
      <path
        d="M52.4668 66.8996C52.2884 66.5854 52.394 66.1855 52.7039 66.0045C53.0137 65.8236 53.408 65.9307 53.5864 66.245C53.7648 66.5592 53.6592 66.9591 53.3494 67.14C53.0395 67.3209 52.6452 67.2114 52.4668 66.8996Z"
        fill="url(#paint18_linear_16968_7127)"
      />
      <path
        d="M59.6141 61.5365C59.363 61.2818 59.363 60.8652 59.6141 60.6105C59.8653 60.3558 60.2761 60.3558 60.5272 60.6105C60.7784 60.8652 60.7784 61.2818 60.5272 61.5365C60.2761 61.7936 59.8676 61.7936 59.6141 61.5365Z"
        fill="url(#paint19_linear_16968_7127)"
      />
      <path
        d="M65.1487 54.4809C64.8389 54.3 64.7332 53.9001 64.9116 53.5859C65.09 53.2717 65.4844 53.1646 65.7942 53.3455C66.104 53.5264 66.2096 53.9263 66.0313 54.2405C65.8529 54.5547 65.4585 54.6619 65.1487 54.4809Z"
        fill="url(#paint20_linear_16968_7127)"
      />
      <path
        d="M68.6955 46.2137C68.3504 46.1209 68.1462 45.7615 68.2401 45.4115C68.3317 45.0616 68.6861 44.8545 69.0311 44.9497C69.3762 45.0426 69.5804 45.402 69.4865 45.7519C69.3949 46.0995 69.0405 46.3066 68.6955 46.2137Z"
        fill="url(#paint21_linear_16968_7127)"
      />
      <path
        d="M70.0097 37.2967C70.3662 37.2967 70.6552 37.0036 70.6552 36.642C70.6552 36.2805 70.3662 35.9874 70.0097 35.9874C69.6533 35.9874 69.3643 36.2805 69.3643 36.642C69.3643 37.0036 69.6533 37.2967 70.0097 37.2967Z"
        fill="url(#paint22_linear_16968_7127)"
      />
      <path
        d="M69.0052 28.3391C68.6602 28.4319 68.3058 28.2248 68.2143 27.8773C68.1227 27.5274 68.3269 27.1703 68.6696 27.0751C69.0146 26.9823 69.3667 27.1894 69.4606 27.5369C69.5545 27.8844 69.3503 28.2439 69.0052 28.3391Z"
        fill="url(#paint23_linear_16968_7127)"
      />
      <path
        d="M65.7473 19.9481C65.4375 20.129 65.0431 20.0219 64.8648 19.7077C64.6864 19.3935 64.792 18.9936 65.1018 18.8126C65.4116 18.6317 65.806 18.7389 65.9844 19.0531C66.1627 19.3673 66.0571 19.7672 65.7473 19.9481Z"
        fill="url(#paint24_linear_16968_7127)"
      />
      <path
        d="M60.0036 12.8925C60.3601 12.8925 60.6491 12.5995 60.6491 12.2379C60.6491 11.8764 60.3601 11.5833 60.0036 11.5833C59.6471 11.5833 59.3582 11.8764 59.3582 12.2379C59.3582 12.5995 59.6471 12.8925 60.0036 12.8925Z"
        fill="url(#paint25_linear_16968_7127)"
      />
      <path
        d="M53.502 7.08665C53.3236 7.40087 52.9292 7.50799 52.6194 7.32707C52.3096 7.14616 52.204 6.74625 52.3823 6.43203C52.5607 6.11782 52.9551 6.0107 53.2649 6.19161C53.5747 6.3749 53.6803 6.77482 53.502 7.08665Z"
        fill="url(#paint26_linear_16968_7127)"
      />
      <path
        d="M45.3502 3.49223C45.2587 3.84215 44.9043 4.04925 44.5592 3.95403C44.2142 3.8612 44.01 3.50175 44.1039 3.15183C44.1954 2.80191 44.5499 2.59481 44.8949 2.69003C45.2376 2.78286 45.4418 3.14231 45.3502 3.49223Z"
        fill="url(#paint27_linear_16968_7127)"
      />
      <g opacity={0.48}>
        <path
          opacity={0.38}
          d="M58.6423 37.5942L64.3178 39.42L71.9462 41.2458V37.5942V33.9402L64.3178 35.766L58.6423 37.5942Z"
          fill="white"
        />
        <path
          opacity={0.3824}
          d="M58.6541 37.5942L64.3296 39.42L71.9486 41.2434L71.9533 37.5942L71.9462 33.9426L64.3296 35.766L58.6541 37.5942Z"
          fill="#FEFEFE"
        />
        <path
          opacity={0.3849}
          d="M58.6658 37.5942L64.3436 39.42L71.9486 41.241L71.9626 37.5942L71.9462 33.9474L64.3436 35.766L58.6658 37.5942Z"
          fill="#FDFDFD"
        />
        <path
          opacity={0.3873}
          d="M58.6775 37.5942L64.3554 39.42L71.9485 41.2386L71.9697 37.5942L71.9462 33.9498L64.3554 35.766L58.6775 37.5942Z"
          fill="#FCFCFC"
        />
        <path
          opacity={0.3897}
          d="M58.6892 37.5942L64.3694 39.42L71.9485 41.2363L71.9767 37.5942L71.9462 33.9521L64.3694 35.766L58.6892 37.5942Z"
          fill="#FBFBFB"
        />
        <path
          opacity={0.3922}
          d="M58.7009 37.5942L64.3835 39.42L71.9485 41.2339L71.9837 37.5942L71.9485 33.9545L64.3835 35.766L58.7009 37.5942Z"
          fill="#FAFAFA"
        />
        <path
          opacity={0.3946}
          d="M58.7126 37.5942L64.3952 39.42L71.9485 41.2291L71.9907 37.5942L71.9485 33.9593L64.3952 35.766L58.7126 37.5942Z"
          fill="#F9F9F9"
        />
        <path
          opacity={0.397}
          d="M58.7244 37.5942L64.4093 39.42L71.9508 41.2267L71.9978 37.5942L71.9485 33.9617L64.4093 35.766L58.7244 37.5942Z"
          fill="#F8F8F8"
        />
        <path
          opacity={0.3995}
          d="M58.7363 37.5942L64.4236 39.42L71.951 41.2243L72.005 37.5942L71.9487 33.9641L64.4236 35.766L58.7363 37.5942Z"
          fill="#F7F7F7"
        />
        <path
          opacity={0.4019}
          d="M58.748 37.5942L64.4353 39.42L71.951 41.222L72.0121 37.5942L71.9487 33.9688L64.4353 35.766L58.748 37.5942Z"
          fill="#F6F6F6"
        />
        <path
          opacity={0.4043}
          d="M58.7598 37.5942L64.4494 39.42L71.951 41.2196L72.0191 37.5942L71.9487 33.9712L64.4494 35.766L58.7598 37.5942Z"
          fill="#F5F5F5"
        />
        <path
          opacity={0.4067}
          d="M58.769 37.5942L64.4633 39.42L71.9509 41.2172L72.026 37.5942L71.9485 33.9736L64.4633 35.766L58.769 37.5942Z"
          fill="#F4F4F4"
        />
        <path
          opacity={0.4092}
          d="M58.7808 37.5942L64.4751 39.42L71.9509 41.2148L72.033 37.5942L71.9485 33.976L64.4751 35.766L58.7808 37.5942Z"
          fill="#F3F3F3"
        />
        <path
          opacity={0.4116}
          d="M58.7925 37.5942L64.4891 39.42L71.9532 41.2124L72.0401 37.5942L71.9485 33.9807L64.4891 35.766L58.7925 37.5942Z"
          fill="#F2F2F2"
        />
        <path
          opacity={0.414}
          d="M58.8042 37.5942L64.5008 39.42L71.9532 41.2077L72.0471 37.5942L71.9485 33.9831L64.5008 35.766L58.8042 37.5942Z"
          fill="#F1F1F1"
        />
        <path
          opacity={0.4165}
          d="M58.8159 37.5942L64.5149 39.42L71.9532 41.2053L72.0541 37.5942L71.9485 33.9855L64.5149 35.766L58.8159 37.5942Z"
          fill="#F0F0F0"
        />
        <path
          opacity={0.4189}
          d="M58.8276 37.5942L64.529 39.42L71.9532 41.2029L72.0611 37.5942L71.9485 33.9879L64.529 35.766L58.8276 37.5942Z"
          fill="#EFEFEF"
        />
        <path
          opacity={0.4213}
          d="M58.8396 37.5942L64.5409 39.42L71.9534 41.2005L72.0707 37.5942L71.9487 33.9926L64.5409 35.766L58.8396 37.5942Z"
          fill="#EEEEEE"
        />
        <path
          opacity={0.4238}
          d="M58.8513 37.5942L64.555 39.42L71.9557 41.1982L72.0778 37.5942L71.9487 33.995L64.555 35.766L58.8513 37.5942Z"
          fill="#EDEDED"
        />
        <path
          opacity={0.4262}
          d="M58.863 37.5942L64.5691 39.42L71.9557 41.1958L72.0848 37.5942L71.9487 33.9974L64.5691 35.766L58.863 37.5942Z"
          fill="#ECECEC"
        />
        <path
          opacity={0.4286}
          d="M58.8748 37.5942L64.5808 39.42L71.9557 41.1934L72.0918 37.5942L71.9487 33.9998L64.5808 35.766L58.8748 37.5942Z"
          fill="#EBEBEB"
        />
        <path
          opacity={0.4311}
          d="M58.8865 37.5942L64.5949 39.42L71.9557 41.191L72.0989 37.5942L71.9486 34.0045L64.5949 35.766L58.8865 37.5942Z"
          fill="#EAEAEA"
        />
        <path
          opacity={0.4335}
          d="M58.8982 37.5942L64.6089 39.42L71.9557 41.1863L72.1059 37.5942L71.9486 34.0069L64.6089 35.766L58.8982 37.5942Z"
          fill="#E9E9E9"
        />
        <path
          opacity={0.4359}
          d="M58.9099 37.5942L64.6206 39.42L71.9556 41.1839L72.1129 37.5942L71.9486 34.0093L64.6206 35.766L58.9099 37.5942Z"
          fill="#E8E8E8"
        />
        <path
          opacity={0.4384}
          d="M58.9216 37.5942L64.6347 39.42L71.958 41.1815L72.1199 37.5942L71.9486 34.0117L64.6347 35.766L58.9216 37.5942Z"
          fill="#E7E7E7"
        />
        <path
          opacity={0.4408}
          d="M58.9333 37.5942L64.6464 39.42L71.9579 41.1791L72.1269 37.5942L71.9485 34.0164L64.6464 35.766L58.9333 37.5942Z"
          fill="#E6E6E6"
        />
        <path
          opacity={0.4432}
          d="M58.9429 37.5942L64.6606 39.42L71.9581 41.1767L72.1341 37.5942L71.9487 34.0188L64.6606 35.766L58.9429 37.5942Z"
          fill="#E5E5E5"
        />
        <path
          opacity={0.4456}
          d="M58.9546 37.5942L64.6747 39.42L71.9581 41.1744L72.1411 37.5942L71.9487 34.0212L64.6747 35.766L58.9546 37.5942Z"
          fill="#E4E4E4"
        />
        <path
          opacity={0.4481}
          d="M58.9663 37.5942L64.6864 39.42L71.958 41.172L72.1482 37.5942L71.9486 34.0236L64.6864 35.766L58.9663 37.5942Z"
          fill="#E3E3E3"
        />
        <path
          opacity={0.4505}
          d="M58.978 37.5942L64.7005 39.42L71.958 41.1696L72.1552 37.5942L71.9486 34.0283L64.7005 35.766L58.978 37.5942Z"
          fill="#E2E2E2"
        />
        <path
          opacity={0.4529}
          d="M58.9897 37.5942L64.7146 39.42L71.9603 41.1672L72.1622 37.5942L71.9486 34.0307L64.7146 35.766L58.9897 37.5942Z"
          fill="#E1E1E1"
        />
        <path
          opacity={0.4554}
          d="M59.0015 37.5942L64.7263 39.42L71.9603 41.1625L72.1692 37.5942L71.9486 34.0331L64.7263 35.766L59.0015 37.5942Z"
          fill="#E0E0E0"
        />
        <path
          opacity={0.4578}
          d="M59.0132 37.5942L64.7403 39.42L71.9603 41.1601L72.1763 37.5942L71.9486 34.0355L64.7403 35.766L59.0132 37.5942Z"
          fill="#DFDFDF"
        />
        <path
          opacity={0.4602}
          d="M59.0249 37.5942L64.7544 39.42L71.9603 41.1577L72.1856 37.5942L71.9486 34.0402L64.7544 35.766L59.0249 37.5942Z"
          fill="#DEDEDE"
        />
        <path
          opacity={0.4627}
          d="M59.0366 37.5942L64.7661 39.42L71.9603 41.1553L72.1927 37.5942L71.9485 34.0426L64.7661 35.766L59.0366 37.5942Z"
          fill="#DDDDDD"
        />
        <path
          opacity={0.4651}
          d="M59.0483 37.5942L64.7802 39.42L71.9603 41.1529L72.1997 37.5942L71.9485 34.045L64.7802 35.766L59.0483 37.5942Z"
          fill="#DCDCDC"
        />
        <path
          opacity={0.4675}
          d="M59.0601 37.5942L64.7919 39.42L71.9626 41.1506L72.2067 37.5942L71.9485 34.0497L64.7919 35.766L59.0601 37.5942Z"
          fill="#DBDBDB"
        />
        <path
          opacity={0.47}
          d="M59.0718 37.5942L64.806 39.42L71.9626 41.1482L72.2138 37.5942L71.9485 34.0521L64.806 35.766L59.0718 37.5942Z"
          fill="#DADADA"
        />
        <path
          opacity={0.4724}
          d="M59.0835 37.5942L64.82 39.42L71.9626 41.1458L72.2208 37.5942L71.9485 34.0545L64.82 35.766L59.0835 37.5942Z"
          fill="#D9D9D9"
        />
        <path
          opacity={0.4748}
          d="M59.0952 37.5942L64.8318 39.42L71.9625 41.141L72.2278 37.5942L71.9485 34.0569L64.8318 35.766L59.0952 37.5942Z"
          fill="#D8D8D8"
        />
        <path
          opacity={0.4773}
          d="M59.1072 37.5942L64.8461 39.42L71.9628 41.1387L72.235 37.5942L71.9487 34.0616L64.8461 35.766L59.1072 37.5942Z"
          fill="#D7D7D7"
        />
        <path
          opacity={0.4797}
          d="M59.1189 37.5942L64.8601 39.42L71.9651 41.1363L72.2421 37.5942L71.9487 34.064L64.8601 35.766L59.1189 37.5942Z"
          fill="#D6D6D6"
        />
        <path
          opacity={0.4821}
          d="M59.1282 37.5942L64.8718 39.42L71.965 41.1339L72.249 37.5942L71.9509 34.0664L64.8718 35.766L59.1282 37.5942Z"
          fill="#D5D5D5"
        />
        <path
          opacity={0.4845}
          d="M59.1399 37.5942L64.8858 39.42L71.965 41.1315L72.2561 37.5942L71.9509 34.0688L64.8858 35.766L59.1399 37.5942Z"
          fill="#D4D4D4"
        />
        <path
          opacity={0.487}
          d="M59.1516 37.5942L64.8999 39.42L71.965 41.1291L72.2631 37.5942L71.9509 34.0735L64.8999 35.766L59.1516 37.5942Z"
          fill="#D3D3D3"
        />
        <path
          opacity={0.4894}
          d="M59.1633 37.5942L64.9116 39.42L71.965 41.1267L72.2701 37.5942L71.9509 34.0759L64.9116 35.766L59.1633 37.5942Z"
          fill="#D2D2D2"
        />
        <path
          opacity={0.4918}
          d="M59.175 37.5942L64.9257 39.42L71.9649 41.1244L72.2771 37.5942L71.9508 34.0783L64.9257 35.766L59.175 37.5942Z"
          fill="#D1D1D1"
        />
        <path
          opacity={0.4943}
          d="M59.1868 37.5942L64.9374 39.42L71.9673 41.122L72.2841 37.5942L71.9508 34.0807L64.9374 35.766L59.1868 37.5942Z"
          fill="#D0D0D0"
        />
        <path
          opacity={0.4967}
          d="M59.1987 37.5942L64.9517 39.42L71.9675 41.1172L72.2937 37.5942L71.951 34.0854L64.9517 35.766L59.1987 37.5942Z"
          fill="#CFCFCF"
        />
        <path
          opacity={0.4991}
          d="M59.2104 37.5942L64.9658 39.42L71.9675 41.1148L72.3008 37.5942L71.951 34.0878L64.9658 35.766L59.2104 37.5942Z"
          fill="#CECECE"
        />
        <path
          opacity={0.5016}
          d="M59.2222 37.5942L64.9775 39.42L71.9674 41.1125L72.3078 37.5942L71.951 34.0902L64.9775 35.766L59.2222 37.5942Z"
          fill="#CDCDCD"
        />
        <path
          opacity={0.504}
          d="M59.2339 37.5942L64.9916 39.42L71.9674 41.1101L72.3148 37.5942L71.951 34.0926L64.9916 35.766L59.2339 37.5942Z"
          fill="#CCCCCC"
        />
        <path
          opacity={0.5064}
          d="M59.2456 37.5942L65.0056 39.42L71.9674 41.1077L72.3218 37.5942L71.951 34.0974L65.0056 35.766L59.2456 37.5942Z"
          fill="#CBCBCB"
        />
        <path
          opacity={0.5089}
          d="M59.2573 37.5942L65.0173 39.42L71.9697 41.1053L72.3289 37.5942L71.951 34.0997L65.0173 35.766L59.2573 37.5942Z"
          fill="#CACACA"
        />
        <path
          opacity={0.5113}
          d="M59.269 37.5942L65.0314 39.42L71.9697 41.1029L72.3359 37.5942L71.951 34.1021L65.0314 35.766L59.269 37.5942Z"
          fill="#C9C9C9"
        />
        <path
          opacity={0.5137}
          d="M59.2808 37.5942L65.0431 39.42L71.9697 41.1006L72.3429 37.5942L71.951 34.1045L65.0431 35.766L59.2808 37.5942Z"
          fill="#C8C8C8"
        />
        <path
          opacity={0.5162}
          d="M59.2925 37.5942L65.0572 39.42L71.9697 41.0958L72.3499 37.5942L71.9509 34.1093L65.0572 35.766L59.2925 37.5942Z"
          fill="#C7C7C7"
        />
        <path
          opacity={0.5186}
          d="M59.3042 37.5942L65.0713 39.42L71.9697 41.0934L72.357 37.5942L71.9509 34.1116L65.0713 35.766L59.3042 37.5942Z"
          fill="#C6C6C6"
        />
        <path
          opacity={0.521}
          d="M59.3137 37.5942L65.0831 39.42L71.9698 41.091L72.3641 37.5942L71.951 34.114L65.0831 35.766L59.3137 37.5942Z"
          fill="#C5C5C5"
        />
        <path
          opacity={0.5235}
          d="M59.3254 37.5942L65.0972 39.42L71.9721 41.0887L72.3712 37.5942L71.951 34.1164L65.0972 35.766L59.3254 37.5942Z"
          fill="#C4C4C4"
        />
        <path
          opacity={0.5259}
          d="M59.3372 37.5942L65.1113 39.42L71.9721 41.0863L72.3782 37.5942L71.951 34.1212L65.1113 35.766L59.3372 37.5942Z"
          fill="#C3C3C3"
        />
        <path
          opacity={0.5283}
          d="M59.3489 37.5942L65.123 39.42L71.9721 41.0839L72.3852 37.5942L71.951 34.1235L65.123 35.766L59.3489 37.5942Z"
          fill="#C2C2C2"
        />
        <path
          opacity={0.5307}
          d="M59.3606 37.5942L65.137 39.42L71.9721 41.0815L72.3922 37.5942L71.9509 34.1259L65.137 35.766L59.3606 37.5942Z"
          fill="#C1C1C1"
        />
        <path
          opacity={0.5332}
          d="M59.3723 37.5942L65.1511 39.42L71.9721 41.0791L72.4016 37.5942L71.9509 34.1307L65.1511 35.766L59.3723 37.5942Z"
          fill="#C0C0C0"
        />
        <path
          opacity={0.5356}
          d="M59.384 37.5942L65.1628 39.42L71.9721 41.0768L72.4086 37.5942L71.9509 34.1331L65.1628 35.766L59.384 37.5942Z"
          fill="#BFBFBF"
        />
        <path
          opacity={0.538}
          d="M59.3958 37.5942L65.1769 39.42L71.9744 41.072L72.4157 37.5942L71.9509 34.1354L65.1769 35.766L59.3958 37.5942Z"
          fill="#BEBEBE"
        />
        <path
          opacity={0.5405}
          d="M59.4075 37.5942L65.1886 39.42L71.9743 41.0696L72.4227 37.5942L71.9509 34.1378L65.1886 35.766L59.4075 37.5942Z"
          fill="#BDBDBD"
        />
        <path
          opacity={0.5429}
          d="M59.4192 37.5942L65.2027 39.42L71.9743 41.0672L72.4297 37.5942L71.9509 34.1426L65.2027 35.766L59.4192 37.5942Z"
          fill="#BCBCBC"
        />
        <path
          opacity={0.5453}
          d="M59.4309 37.5942L65.2168 39.42L71.9743 41.0649L72.4367 37.5942L71.9509 34.145L65.2168 35.766L59.4309 37.5942Z"
          fill="#BBBBBB"
        />
        <path
          opacity={0.5478}
          d="M59.4426 37.5942L65.2285 39.42L71.9743 41.0625L72.4438 37.5942L71.9509 34.1473L65.2285 35.766L59.4426 37.5942Z"
          fill="#BABABA"
        />
        <path
          opacity={0.5502}
          d="M59.4543 37.5942L65.2425 39.42L71.9766 41.0601L72.4508 37.5942L71.9508 34.1497L65.2425 35.766L59.4543 37.5942Z"
          fill="#B9B9B9"
        />
        <path
          opacity={0.5526}
          d="M59.4663 37.5942L65.2568 39.42L71.9769 41.0577L72.458 37.5942L71.951 34.1545L65.2568 35.766L59.4663 37.5942Z"
          fill="#B8B8B8"
        />
        <path
          opacity={0.5551}
          d="M59.478 37.5942L65.2686 39.42L71.9769 41.0553L72.4651 37.5942L71.951 34.1569L65.2686 35.766L59.478 37.5942Z"
          fill="#B7B7B7"
        />
        <path
          opacity={0.5575}
          d="M59.4873 37.5942L65.2825 39.42L71.9767 41.0506L72.472 37.5942L71.9509 34.1592L65.2825 35.766L59.4873 37.5942Z"
          fill="#B6B6B6"
        />
        <path
          opacity={0.5599}
          d="M59.499 37.5942L65.2966 39.42L71.9767 41.0482L72.479 37.5942L71.9509 34.1616L65.2966 35.766L59.499 37.5942Z"
          fill="#B5B5B5"
        />
        <path
          opacity={0.5624}
          d="M59.5107 37.5942L65.3083 39.42L71.9767 41.0458L72.4861 37.5942L71.9509 34.1664L65.3083 35.766L59.5107 37.5942Z"
          fill="#B4B4B4"
        />
        <path
          opacity={0.5648}
          d="M59.5225 37.5942L65.3224 39.42L71.979 41.0434L72.4931 37.5942L71.9509 34.1688L65.3224 35.766L59.5225 37.5942Z"
          fill="#B3B3B3"
        />
        <path
          opacity={0.5672}
          d="M59.5342 37.5942L65.3341 39.42L71.979 41.0411L72.5001 37.5942L71.9509 34.1711L65.3341 35.766L59.5342 37.5942Z"
          fill="#B2B2B2"
        />
        <path
          opacity={0.5696}
          d="M59.5459 37.5942L65.3482 39.42L71.979 41.0387L72.5095 37.5942L71.9508 34.1735L65.3482 35.766L59.5459 37.5942Z"
          fill="#B1B1B1"
        />
        <path
          opacity={0.5721}
          d="M59.5576 37.5942L65.3622 39.42L71.979 41.0363L72.5165 37.5942L71.9508 34.1783L65.3622 35.766L59.5576 37.5942Z"
          fill="#B0B0B0"
        />
        <path
          opacity={0.5745}
          d="M59.5696 37.5942L65.3742 39.42L71.9792 41.0339L72.5238 37.5942L71.9534 34.1807L65.3742 35.766L59.5696 37.5942Z"
          fill="#AFAFAF"
        />
        <path
          opacity={0.5769}
          d="M59.5813 37.5942L65.3883 39.42L71.9792 41.0291L72.5308 37.5942L71.9534 34.183L65.3883 35.766L59.5813 37.5942Z"
          fill="#AEAEAE"
        />
        <path
          opacity={0.5794}
          d="M59.593 37.5942L65.4023 39.42L71.9815 41.0268L72.5378 37.5942L71.9534 34.1854L65.4023 35.766L59.593 37.5942Z"
          fill="#ADADAD"
        />
        <path
          opacity={0.5818}
          d="M59.6047 37.5942L65.414 39.42L71.9815 41.0244L72.5448 37.5942L71.9533 34.1902L65.414 35.766L59.6047 37.5942Z"
          fill="#ACACAC"
        />
        <path
          opacity={0.5842}
          d="M59.6165 37.5942L65.4281 39.42L71.9815 41.022L72.5519 37.5942L71.9533 34.1926L65.4281 35.766L59.6165 37.5942Z"
          fill="#ABABAB"
        />
        <path
          opacity={0.5867}
          d="M59.6282 37.5942L65.4422 39.42L71.9815 41.0196L72.5589 37.5942L71.9533 34.1949L65.4422 35.766L59.6282 37.5942Z"
          fill="#AAAAAA"
        />
        <path
          opacity={0.5891}
          d="M59.6399 37.5942L65.4539 39.42L71.9815 41.0172L72.5659 37.5942L71.9533 34.1973L65.4539 35.766L59.6399 37.5942Z"
          fill="#A9A9A9"
        />
        <path
          opacity={0.5915}
          d="M59.6516 37.5942L65.468 39.42L71.9814 41.0149L72.5729 37.5942L71.9533 34.2021L65.468 35.766L59.6516 37.5942Z"
          fill="#A8A8A8"
        />
        <path
          opacity={0.594}
          d="M59.6633 37.5942L65.4797 39.42L71.9838 41.0125L72.5799 37.5942L71.9532 34.2045L65.4797 35.766L59.6633 37.5942Z"
          fill="#A7A7A7"
        />
        <path
          opacity={0.5964}
          d="M59.6729 37.5942L65.4939 39.42L71.9839 41.0101L72.5871 37.5942L71.9534 34.2068L65.4939 35.766L59.6729 37.5942Z"
          fill="#A6A6A6"
        />
        <path
          opacity={0.5988}
          d="M59.6846 37.5942L65.508 39.42L71.9839 41.0053L72.5942 37.5942L71.9534 34.2116L65.508 35.766L59.6846 37.5942Z"
          fill="#A5A5A5"
        />
        <path
          opacity={0.6013}
          d="M59.6963 37.5942L65.5197 39.42L71.9839 41.003L72.6012 37.5942L71.9534 34.214L65.5197 35.766L59.6963 37.5942Z"
          fill="#A4A4A4"
        />
        <path
          opacity={0.6037}
          d="M59.708 37.5942L65.5338 39.42L71.9839 41.0006L72.6082 37.5942L71.9533 34.2164L65.5338 35.766L59.708 37.5942Z"
          fill="#A3A3A3"
        />
        <path
          opacity={0.6061}
          d="M59.7197 37.5942L65.5478 39.42L71.9862 40.9982L72.6152 37.5942L71.9533 34.2188L65.5478 35.766L59.7197 37.5942Z"
          fill="#A2A2A2"
        />
        <path
          opacity={0.6085}
          d="M59.7314 37.5942L65.5595 39.42L71.9862 40.9958L72.6246 37.5942L71.9533 34.2235L65.5595 35.766L59.7314 37.5942Z"
          fill="#A1A1A1"
        />
        <path
          opacity={0.611}
          d="M59.7432 37.5942L65.5736 39.42L71.9862 40.9934L72.6316 37.5942L71.9533 34.2259L65.5736 35.766L59.7432 37.5942Z"
          fill="#A0A0A0"
        />
        <path
          opacity={0.6134}
          d="M59.7549 37.5942L65.5877 39.42L71.9861 40.9911L72.6386 37.5942L71.9533 34.2283L65.5877 35.766L59.7549 37.5942Z"
          fill="#9F9F9F"
        />
        <path
          opacity={0.6158}
          d="M59.7666 37.5942L65.5994 39.42L71.9861 40.9887L72.6457 37.5942L71.9532 34.2307L65.5994 35.766L59.7666 37.5942Z"
          fill="#9E9E9E"
        />
        <path
          opacity={0.6183}
          d="M59.7783 37.5942L65.6135 39.42L71.9861 40.9839L72.6527 37.5942L71.9532 34.2354L65.6135 35.766L59.7783 37.5942Z"
          fill="#9D9D9D"
        />
        <path
          opacity={0.6207}
          d="M59.79 37.5942L65.6252 39.42L71.9884 40.9815L72.6597 37.5942L71.9532 34.2378L65.6252 35.766L59.79 37.5942Z"
          fill="#9C9C9C"
        />
        <path
          opacity={0.6231}
          d="M59.8018 37.5942L65.6392 39.42L71.9884 40.9792L72.6668 37.5942L71.9532 34.2402L65.6392 35.766L59.8018 37.5942Z"
          fill="#9B9B9B"
        />
        <path
          opacity={0.6256}
          d="M59.8135 37.5942L65.6533 39.42L71.9884 40.9768L72.6738 37.5942L71.9532 34.2426L65.6533 35.766L59.8135 37.5942Z"
          fill="#9A9A9A"
        />
        <path
          opacity={0.628}
          d="M59.8252 37.5942L65.665 39.42L71.9884 40.9744L72.6808 37.5942L71.9532 34.2473L65.665 35.766L59.8252 37.5942Z"
          fill="#999999"
        />
        <path
          opacity={0.6304}
          d="M59.8372 37.5942L65.6793 39.42L71.9886 40.972L72.6881 37.5942L71.9534 34.2497L65.6793 35.766L59.8372 37.5942Z"
          fill="#989898"
        />
        <path
          opacity={0.6329}
          d="M59.8489 37.5942L65.6934 39.42L71.9886 40.9696L72.6951 37.5942L71.9534 34.2521L65.6934 35.766L59.8489 37.5942Z"
          fill="#979797"
        />
        <path
          opacity={0.6353}
          d="M59.8582 37.5942L65.705 39.42L71.9908 40.9673L72.702 37.5942L71.9533 34.2545L65.705 35.766L59.8582 37.5942Z"
          fill="#969696"
        />
        <path
          opacity={0.6377}
          d="M59.8699 37.5942L65.7191 39.42L71.9908 40.9649L72.709 37.5942L71.9532 34.2592L65.7191 35.766L59.8699 37.5942Z"
          fill="#959595"
        />
        <path
          opacity={0.6402}
          d="M59.8816 37.5942L65.7332 39.42L71.9908 40.9601L72.7161 37.5942L71.9532 34.2616L65.7332 35.766L59.8816 37.5942Z"
          fill="#949494"
        />
        <path
          opacity={0.6426}
          d="M59.8933 37.5942L65.7449 39.42L71.9908 40.9577L72.7231 37.5942L71.9532 34.264L65.7449 35.766L59.8933 37.5942Z"
          fill="#939393"
        />
        <path
          opacity={0.645}
          d="M59.905 37.5942L65.7589 39.42L71.9908 40.9553L72.7325 37.5942L71.9532 34.2664L65.7589 35.766L59.905 37.5942Z"
          fill="#929292"
        />
        <path
          opacity={0.6475}
          d="M59.9167 37.5942L65.7707 39.42L71.9907 40.953L72.7395 37.5942L71.9532 34.2711L65.7707 35.766L59.9167 37.5942Z"
          fill="#919191"
        />
        <path
          opacity={0.6499}
          d="M59.9287 37.5942L65.785 39.42L71.9933 40.9506L72.7467 37.5942L71.9534 34.2735L65.785 35.766L59.9287 37.5942Z"
          fill="#909090"
        />
        <path
          opacity={0.6523}
          d="M59.9404 37.5942L65.799 39.42L71.9933 40.9482L72.7538 37.5942L71.9534 34.2759L65.799 35.766L59.9404 37.5942Z"
          fill="#8F8F8F"
        />
        <path
          opacity={0.6547}
          d="M59.9521 37.5942L65.8108 39.42L71.9933 40.9458L72.7608 37.5942L71.9534 34.2783L65.8108 35.766L59.9521 37.5942Z"
          fill="#8E8E8E"
        />
        <path
          opacity={0.6572}
          d="M59.9639 37.5942L65.8248 39.42L71.9932 40.9435L72.7678 37.5942L71.9533 34.283L65.8248 35.766L59.9639 37.5942Z"
          fill="#8D8D8D"
        />
        <path
          opacity={0.6596}
          d="M59.9756 37.5942L65.8389 39.42L71.9932 40.9387L72.7748 37.5942L71.9533 34.2854L65.8389 35.766L59.9756 37.5942Z"
          fill="#8C8C8C"
        />
        <path
          opacity={0.662}
          d="M59.9873 37.5942L65.8506 39.42L71.9956 40.9363L72.7819 37.5942L71.9533 34.2878L65.8506 35.766L59.9873 37.5942Z"
          fill="#8B8B8B"
        />
        <path
          opacity={0.6645}
          d="M59.999 37.5942L65.8647 39.42L71.9956 40.9339L72.7889 37.5942L71.9533 34.2925L65.8647 35.766L59.999 37.5942Z"
          fill="#8A8A8A"
        />
        <path
          opacity={0.6669}
          d="M60.0107 37.5942L65.8787 39.42L71.9955 40.9315L72.7959 37.5942L71.9556 34.2949L65.8787 35.766L60.0107 37.5942Z"
          fill="#898989"
        />
        <path
          opacity={0.6693}
          d="M60.0225 37.5942L65.8904 39.42L71.9955 40.9292L72.8029 37.5942L71.9556 34.2973L65.8904 35.766L60.0225 37.5942Z"
          fill="#888888"
        />
        <path
          opacity={0.6718}
          d="M60.0342 37.5942L65.9045 39.42L71.9955 40.9268L72.81 37.5942L71.9556 34.2997L65.9045 35.766L60.0342 37.5942Z"
          fill="#878787"
        />
        <path
          opacity={0.6742}
          d="M60.0437 37.5942L65.9164 39.42L71.9956 40.9244L72.8171 37.5942L71.9557 34.3044L65.9164 35.766L60.0437 37.5942Z"
          fill="#868686"
        />
        <path
          opacity={0.6766}
          d="M60.0554 37.5942L65.9305 39.42L71.998 40.922L72.8242 37.5942L71.9557 34.3068L65.9305 35.766L60.0554 37.5942Z"
          fill="#858585"
        />
        <path
          opacity={0.6791}
          d="M60.0671 37.5942L65.9445 39.42L71.9979 40.9173L72.8312 37.5942L71.9557 34.3092L65.9445 35.766L60.0671 37.5942Z"
          fill="#848484"
        />
        <path
          opacity={0.6815}
          d="M60.0789 37.5942L65.9562 39.42L71.9979 40.9149L72.8406 37.5942L71.9557 34.3116L65.9562 35.766L60.0789 37.5942Z"
          fill="#838383"
        />
        <path
          opacity={0.6839}
          d="M60.0906 37.5942L65.9703 39.42L71.9979 40.9125L72.8476 37.5942L71.9557 34.3163L65.9703 35.766L60.0906 37.5942Z"
          fill="#828282"
        />
        <path
          opacity={0.6864}
          d="M60.1023 37.5942L65.9844 39.42L71.9979 40.9101L72.8546 37.5942L71.9556 34.3187L65.9844 35.766L60.1023 37.5942Z"
          fill="#818181"
        />
        <path
          opacity={0.6888}
          d="M60.114 37.5942L65.9961 39.42L71.9979 40.9077L72.8617 37.5942L71.9556 34.3211L65.9961 35.766L60.114 37.5942Z"
          fill="#808080"
        />
        <path
          opacity={0.6912}
          d="M60.1257 37.5942L66.0102 39.42L72.0002 40.9054L72.8687 37.5942L71.9556 34.3235L66.0102 35.766L60.1257 37.5942Z"
          fill="#7F7F7F"
        />
        <path
          opacity={0.6936}
          d="M60.1375 37.5942L66.0219 39.42L72.0002 40.903L72.8757 37.5942L71.9556 34.3282L66.0219 35.766L60.1375 37.5942Z"
          fill="#7E7E7E"
        />
        <path
          opacity={0.6961}
          d="M60.1492 37.5942L66.0359 39.42L72.0002 40.9006L72.8827 37.5942L71.9556 34.3306L66.0359 35.766L60.1492 37.5942Z"
          fill="#7D7D7D"
        />
        <path
          opacity={0.6985}
          d="M60.1609 37.5942L66.05 39.42L72.0001 40.8982L72.8897 37.5942L71.9555 34.333L66.05 35.766L60.1609 37.5942Z"
          fill="#7C7C7C"
        />
        <path
          opacity={0.7009}
          d="M60.1726 37.5942L66.0617 39.42L72.0001 40.8935L72.8968 37.5942L71.9556 34.3354L66.0617 35.766L60.1726 37.5942Z"
          fill="#7B7B7B"
        />
        <path
          opacity={0.7034}
          d="M60.1843 37.5942L66.0758 39.42L72.0001 40.8911L72.9038 37.5942L71.9555 34.3401L66.0758 35.766L60.1843 37.5942Z"
          fill="#7A7A7A"
        />
        <path
          opacity={0.7058}
          d="M60.196 37.5942L66.0899 39.42L72.0025 40.8887L72.9108 37.5942L71.9555 34.3425L66.0899 35.766L60.196 37.5942Z"
          fill="#797979"
        />
        <path
          opacity={0.7082}
          d="M60.208 37.5942L66.1018 39.42L72.0027 40.8863L72.9181 37.5942L71.9557 34.3449L66.1018 35.766L60.208 37.5942Z"
          fill="#787878"
        />
        <path
          opacity={0.7107}
          d="M60.2173 37.5942L66.1158 39.42L72.0026 40.8839L72.925 37.5942L71.9556 34.3473L66.1158 35.766L60.2173 37.5942Z"
          fill="#777777"
        />
        <path
          opacity={0.7131}
          d="M60.229 37.5942L66.1299 39.42L72.0025 40.8815L72.932 37.5942L71.9556 34.3521L66.1299 35.766L60.229 37.5942Z"
          fill="#767676"
        />
        <path
          opacity={0.7155}
          d="M60.2407 37.5942L66.1416 39.42L72.0025 40.8792L72.9391 37.5942L71.9556 34.3544L66.1416 35.766L60.2407 37.5942Z"
          fill="#757575"
        />
        <path
          opacity={0.718}
          d="M60.2524 37.5942L66.1556 39.42L72.0049 40.8768L72.9484 37.5942L71.9556 34.3568L66.1556 35.766L60.2524 37.5942Z"
          fill="#747474"
        />
        <path
          opacity={0.7204}
          d="M60.2642 37.5942L66.1674 39.42L72.0048 40.872L72.9555 37.5942L71.9556 34.3592L66.1674 35.766L60.2642 37.5942Z"
          fill="#737373"
        />
        <path
          opacity={0.7228}
          d="M60.2759 37.5942L66.1814 39.42L72.0048 40.8697L72.9625 37.5942L71.9555 34.364L66.1814 35.766L60.2759 37.5942Z"
          fill="#727272"
        />
        <path
          opacity={0.7253}
          d="M60.2876 37.5942L66.1955 39.42L72.0048 40.8673L72.9695 37.5942L71.9555 34.3663L66.1955 35.766L60.2876 37.5942Z"
          fill="#717171"
        />
        <path
          opacity={0.7277}
          d="M60.2993 37.5942L66.2072 39.42L72.0048 40.8649L72.9765 37.5942L71.9555 34.3687L66.2072 35.766L60.2993 37.5942Z"
          fill="#707070"
        />
        <path
          opacity={0.7301}
          d="M60.3113 37.5942L66.2215 39.42L72.005 40.8625L72.9838 37.5942L71.9557 34.3735L66.2215 35.766L60.3113 37.5942Z"
          fill="#6F6F6F"
        />
        <path
          opacity={0.7325}
          d="M60.323 37.5942L66.2356 39.42L72.0073 40.8601L72.9908 37.5942L71.9557 34.3759L66.2356 35.766L60.323 37.5942Z"
          fill="#6E6E6E"
        />
        <path
          opacity={0.735}
          d="M60.3347 37.5942L66.2473 39.42L72.0073 40.8577L72.9979 37.5942L71.9557 34.3782L66.2473 35.766L60.3347 37.5942Z"
          fill="#6D6D6D"
        />
        <path
          opacity={0.7374}
          d="M60.3464 37.5942L66.2614 39.42L72.0073 40.8554L73.0049 37.5942L71.9557 34.3806L66.2614 35.766L60.3464 37.5942Z"
          fill="#6C6C6C"
        />
        <path
          opacity={0.7398}
          d="M60.3582 37.5942L66.2754 39.42L72.0073 40.853L73.0119 37.5942L71.9557 34.3854L66.2754 35.766L60.3582 37.5942Z"
          fill="#6B6B6B"
        />
        <path
          opacity={0.7423}
          d="M60.3699 37.5942L66.2872 39.42L72.0073 40.8482L73.0189 37.5942L71.9556 34.3878L66.2872 35.766L60.3699 37.5942Z"
          fill="#6A6A6A"
        />
        <path
          opacity={0.7447}
          d="M60.3816 37.5942L66.3012 39.42L72.0073 40.8459L73.026 37.5942L71.9556 34.3901L66.3012 35.766L60.3816 37.5942Z"
          fill="#696969"
        />
        <path
          opacity={0.7471}
          d="M60.3933 37.5942L66.3129 39.42L72.0096 40.8435L73.033 37.5942L71.9556 34.3925L66.3129 35.766L60.3933 37.5942Z"
          fill="#686868"
        />
        <path
          opacity={0.7496}
          d="M60.4028 37.5942L66.3272 39.42L72.0097 40.8411L73.0401 37.5942L71.9557 34.3973L66.3272 35.766L60.4028 37.5942Z"
          fill="#676767"
        />
        <path
          opacity={0.752}
          d="M60.4146 37.5942L66.3412 39.42L72.0097 40.8387L73.0472 37.5942L71.9557 34.3997L66.3412 35.766L60.4146 37.5942Z"
          fill="#666666"
        />
        <path
          opacity={0.7544}
          d="M60.4263 37.5942L66.3529 39.42L72.0097 40.8363L73.0565 37.5942L71.9557 34.402L66.3529 35.766L60.4263 37.5942Z"
          fill="#656565"
        />
        <path
          opacity={0.7569}
          d="M60.438 37.5942L66.367 39.42L72.0097 40.834L73.0636 37.5942L71.9557 34.4044L66.367 35.766L60.438 37.5942Z"
          fill="#646464"
        />
        <path
          opacity={0.7593}
          d="M60.4497 37.5942L66.3811 39.42L72.0097 40.8316L73.0706 37.5942L71.958 34.4092L66.3811 35.766L60.4497 37.5942Z"
          fill="#636363"
        />
        <path
          opacity={0.7617}
          d="M60.4614 37.5942L66.3928 39.42L72.012 40.8268L73.0776 37.5942L71.958 34.4116L66.3928 35.766L60.4614 37.5942Z"
          fill="#626262"
        />
        <path
          opacity={0.7642}
          d="M60.4731 37.5942L66.4069 39.42L72.012 40.8244L73.0846 37.5942L71.958 34.4139L66.4069 35.766L60.4731 37.5942Z"
          fill="#616161"
        />
        <path
          opacity={0.7666}
          d="M60.4849 37.5942L66.4209 39.42L72.012 40.822L73.0917 37.5942L71.958 34.4163L66.4209 35.766L60.4849 37.5942Z"
          fill="#606060"
        />
        <path
          opacity={0.769}
          d="M60.4966 37.5942L66.4326 39.42L72.0119 40.8197L73.0987 37.5942L71.9579 34.4211L66.4326 35.766L60.4966 37.5942Z"
          fill="#5F5F5F"
        />
        <path
          opacity={0.7715}
          d="M60.5083 37.5942L66.4467 39.42L72.0119 40.8173L73.1057 37.5942L71.9579 34.4235L66.4467 35.766L60.5083 37.5942Z"
          fill="#5E5E5E"
        />
        <path
          opacity={0.7739}
          d="M60.52 37.5942L66.4584 39.42L72.0142 40.8149L73.1127 37.5942L71.9579 34.4258L66.4584 35.766L60.52 37.5942Z"
          fill="#5D5D5D"
        />
        <path
          opacity={0.7763}
          d="M60.5317 37.5942L66.4725 39.42L72.0142 40.8125L73.1198 37.5942L71.9579 34.4282L66.4725 35.766L60.5317 37.5942Z"
          fill="#5C5C5C"
        />
        <path
          opacity={0.7787}
          d="M60.5435 37.5942L66.4866 39.42L72.0142 40.8101L73.1268 37.5942L71.9579 34.433L66.4866 35.766L60.5435 37.5942Z"
          fill="#5B5B5B"
        />
        <path
          opacity={0.7812}
          d="M60.5552 37.5942L66.4983 39.42L72.0142 40.8078L73.1338 37.5942L71.9579 34.4354L66.4983 35.766L60.5552 37.5942Z"
          fill="#5A5A5A"
        />
        <path
          opacity={0.7836}
          d="M60.5671 37.5942L66.5126 39.42L72.0144 40.803L73.1411 37.5942L71.9581 34.4377L66.5126 35.766L60.5671 37.5942Z"
          fill="#595959"
        />
        <path
          opacity={0.786}
          d="M60.5789 37.5942L66.5267 39.42L72.0144 40.8006L73.1481 37.5942L71.9581 34.4401L66.5267 35.766L60.5789 37.5942Z"
          fill="#585858"
        />
        <path
          opacity={0.7885}
          d="M60.5881 37.5942L66.5383 39.42L72.0166 40.7982L73.155 37.5942L71.958 34.4449L66.5383 35.766L60.5881 37.5942Z"
          fill="#575757"
        />
        <path
          opacity={0.7909}
          d="M60.5999 37.5942L66.5524 39.42L72.0166 40.7959L73.1621 37.5942L71.9579 34.4473L66.5524 35.766L60.5999 37.5942Z"
          fill="#565656"
        />
        <path
          opacity={0.7933}
          d="M60.6116 37.5942L66.5664 39.42L72.0166 40.7935L73.1714 37.5942L71.9579 34.4496L66.5664 35.766L60.6116 37.5942Z"
          fill="#555555"
        />
        <path
          opacity={0.7958}
          d="M60.6233 37.5942L66.5781 39.42L72.0166 40.7911L73.1785 37.5942L71.9579 34.4544L66.5781 35.766L60.6233 37.5942Z"
          fill="#545454"
        />
        <path
          opacity={0.7982}
          d="M60.635 37.5942L66.5922 39.42L72.0166 40.7887L73.1855 37.5942L71.9579 34.4568L66.5922 35.766L60.635 37.5942Z"
          fill="#535353"
        />
        <path
          opacity={0.8006}
          d="M60.6467 37.5942L66.6039 39.42L72.0166 40.7863L73.1925 37.5942L71.9579 34.4592L66.6039 35.766L60.6467 37.5942Z"
          fill="#525252"
        />
        <path
          opacity={0.8031}
          d="M60.6584 37.5942L66.618 39.42L72.0189 40.7816L73.1995 37.5942L71.9579 34.4615L66.618 35.766L60.6584 37.5942Z"
          fill="#515151"
        />
        <path
          opacity={0.8055}
          d="M60.6704 37.5942L66.6323 39.42L72.0191 40.7792L73.2068 37.5942L71.9581 34.4663L66.6323 35.766L60.6704 37.5942Z"
          fill="#505050"
        />
        <path
          opacity={0.8079}
          d="M60.6821 37.5942L66.644 39.42L72.0191 40.7768L73.2138 37.5942L71.9581 34.4687L66.644 35.766L60.6821 37.5942Z"
          fill="#4F4F4F"
        />
        <path
          opacity={0.8104}
          d="M60.6938 37.5942L66.6581 39.42L72.0191 40.7744L73.2208 37.5942L71.958 34.4711L66.6581 35.766L60.6938 37.5942Z"
          fill="#4E4E4E"
        />
        <path
          opacity={0.8128}
          d="M60.7056 37.5942L66.6721 39.42L72.0191 40.7721L73.2279 37.5942L71.958 34.4734L66.6721 35.766L60.7056 37.5942Z"
          fill="#4D4D4D"
        />
        <path
          opacity={0.8152}
          d="M60.7173 37.5942L66.6839 39.42L72.019 40.7697L73.2349 37.5942L71.958 34.4782L66.6839 35.766L60.7173 37.5942Z"
          fill="#4C4C4C"
        />
        <path
          opacity={0.8176}
          d="M60.729 37.5942L66.6979 39.42L72.0214 40.7673L73.2419 37.5942L71.958 34.4806L66.6979 35.766L60.729 37.5942Z"
          fill="#4B4B4B"
        />
        <path
          opacity={0.8201}
          d="M60.7407 37.5942L66.712 39.42L72.0214 40.7649L73.2489 37.5942L71.958 34.483L66.712 35.766L60.7407 37.5942Z"
          fill="#4A4A4A"
        />
        <path
          opacity={0.8225}
          d="M60.7524 37.5942L66.7237 39.42L72.0213 40.7602L73.256 37.5942L71.958 34.4854L66.7237 35.766L60.7524 37.5942Z"
          fill="#494949"
        />
        <path
          opacity={0.8249}
          d="M60.7642 37.5942L66.7378 39.42L72.0213 40.7578L73.263 37.5942L71.9579 34.4901L66.7378 35.766L60.7642 37.5942Z"
          fill="#484848"
        />
        <path
          opacity={0.8274}
          d="M60.7737 37.5942L66.7496 39.42L72.0214 40.7554L73.2701 37.5942L71.9581 34.4925L66.7496 35.766L60.7737 37.5942Z"
          fill="#474747"
        />
        <path
          opacity={0.8298}
          d="M60.7854 37.5942L66.7637 39.42L72.0214 40.753L73.2795 37.5942L71.9581 34.4949L66.7637 35.766L60.7854 37.5942Z"
          fill="#464646"
        />
        <path
          opacity={0.8322}
          d="M60.7971 37.5942L66.7778 39.42L72.0238 40.7506L73.2866 37.5942L71.958 34.4973L66.7778 35.766L60.7971 37.5942Z"
          fill="#454545"
        />
        <path
          opacity={0.8347}
          d="M60.8088 37.5942L66.7895 39.42L72.0237 40.7483L73.2936 37.5942L71.958 34.502L66.7895 35.766L60.8088 37.5942Z"
          fill="#444444"
        />
        <path
          opacity={0.8371}
          d="M60.8206 37.5942L66.8036 39.42L72.0237 40.7459L73.3006 37.5942L71.958 34.5044L66.8036 35.766L60.8206 37.5942Z"
          fill="#434343"
        />
        <path
          opacity={0.8395}
          d="M60.8323 37.5942L66.8176 39.42L72.0237 40.7435L73.3076 37.5942L71.958 34.5068L66.8176 35.766L60.8323 37.5942Z"
          fill="#424242"
        />
        <path
          opacity={0.842}
          d="M60.844 37.5942L66.8294 39.42L72.0237 40.7411L73.3147 37.5942L71.958 34.5092L66.8294 35.766L60.844 37.5942Z"
          fill="#414141"
        />
        <path
          opacity={0.8444}
          d="M60.8557 37.5942L66.8434 39.42L72.026 40.7364L73.3217 37.5942L71.958 34.5139L66.8434 35.766L60.8557 37.5942Z"
          fill="#404040"
        />
        <path
          opacity={0.8468}
          d="M60.8674 37.5942L66.8575 39.42L72.026 40.734L73.3287 37.5942L71.9579 34.5163L66.8575 35.766L60.8674 37.5942Z"
          fill="#3F3F3F"
        />
        <path
          opacity={0.8493}
          d="M60.8792 37.5942L66.8692 39.42L72.026 40.7316L73.3357 37.5942L71.9579 34.5187L66.8692 35.766L60.8792 37.5942Z"
          fill="#3E3E3E"
        />
        <path
          opacity={0.8517}
          d="M60.8909 37.5942L66.8833 39.42L72.026 40.7292L73.3427 37.5942L71.9602 34.5211L66.8833 35.766L60.8909 37.5942Z"
          fill="#3D3D3D"
        />
        <path
          opacity={0.8541}
          d="M60.9026 37.5942L66.895 39.42L72.0259 40.7268L73.3498 37.5942L71.9602 34.5258L66.895 35.766L60.9026 37.5942Z"
          fill="#3C3C3C"
        />
        <path
          opacity={0.8565}
          d="M60.9143 37.5942L66.9091 39.42L72.026 40.7244L73.3568 37.5942L71.9602 34.5282L66.9091 35.766L60.9143 37.5942Z"
          fill="#3B3B3B"
        />
        <path
          opacity={0.859}
          d="M60.9263 37.5942L66.9234 39.42L72.0285 40.7221L73.3641 37.5942L71.9604 34.5306L66.9234 35.766L60.9263 37.5942Z"
          fill="#3A3A3A"
        />
        <path
          opacity={0.8614}
          d="M60.938 37.5942L66.9351 39.42L72.0285 40.7197L73.3711 37.5942L71.9604 34.5353L66.9351 35.766L60.938 37.5942Z"
          fill="#393939"
        />
        <path
          opacity={0.8638}
          d="M60.9473 37.5942L66.949 39.42L72.0284 40.7149L73.378 37.5942L71.9603 34.5377L66.949 35.766L60.9473 37.5942Z"
          fill="#383838"
        />
        <path
          opacity={0.8663}
          d="M60.959 37.5942L66.9631 39.42L72.0284 40.7125L73.3874 37.5942L71.9603 34.5401L66.9631 35.766L60.959 37.5942Z"
          fill="#373737"
        />
        <path
          opacity={0.8687}
          d="M60.9707 37.5942L66.9748 39.42L72.0283 40.7102L73.3944 37.5942L71.9603 34.5425L66.9748 35.766L60.9707 37.5942Z"
          fill="#363636"
        />
        <path
          opacity={0.8711}
          d="M60.9824 37.5942L66.9889 39.42L72.0283 40.7078L73.4015 37.5942L71.9603 34.5472L66.9889 35.766L60.9824 37.5942Z"
          fill="#353535"
        />
        <path
          opacity={0.8736}
          d="M60.9941 37.5942L67.0006 39.42L72.0307 40.7054L73.4085 37.5942L71.9602 34.5496L67.0006 35.766L60.9941 37.5942Z"
          fill="#343434"
        />
        <path
          opacity={0.876}
          d="M61.0059 37.5942L67.0147 39.42L72.0306 40.703L73.4155 37.5942L71.9602 34.552L67.0147 35.766L61.0059 37.5942Z"
          fill="#333333"
        />
        <path
          opacity={0.8784}
          d="M61.0176 37.5942L67.0288 39.42L72.0306 40.7006L73.4225 37.5942L71.9602 34.5544L67.0288 35.766L61.0176 37.5942Z"
          fill="#323232"
        />
        <path
          opacity={0.8809}
          d="M61.0293 37.5942L67.0405 39.42L72.0306 40.6983L73.4295 37.5942L71.9602 34.5591L67.0405 35.766L61.0293 37.5942Z"
          fill="#313131"
        />
        <path
          opacity={0.8833}
          d="M61.0413 37.5942L67.0548 39.42L72.0308 40.6959L73.4368 37.5942L71.9604 34.5615L67.0548 35.766L61.0413 37.5942Z"
          fill="#303030"
        />
        <path
          opacity={0.8857}
          d="M61.053 37.5942L67.0688 39.42L72.0308 40.6911L73.4438 37.5942L71.9604 34.5639L67.0688 35.766L61.053 37.5942Z"
          fill="#2F2F2F"
        />
        <path
          opacity={0.8882}
          d="M61.0647 37.5942L67.0806 39.42L72.0332 40.6887L73.4509 37.5942L71.9604 34.5663L67.0806 35.766L61.0647 37.5942Z"
          fill="#2E2E2E"
        />
        <path
          opacity={0.8906}
          d="M61.0764 37.5942L67.0946 39.42L72.0331 40.6864L73.4579 37.5942L71.9604 34.571L67.0946 35.766L61.0764 37.5942Z"
          fill="#2D2D2D"
        />
        <path
          opacity={0.893}
          d="M61.0881 37.5942L67.1087 39.42L72.0331 40.684L73.4649 37.5942L71.9603 34.5734L67.1087 35.766L61.0881 37.5942Z"
          fill="#2C2C2C"
        />
        <path
          opacity={0.8955}
          d="M61.0999 37.5942L67.1204 39.42L72.0331 40.6816L73.4719 37.5942L71.9603 34.5758L67.1204 35.766L61.0999 37.5942Z"
          fill="#2B2B2B"
        />
        <path
          opacity={0.8979}
          d="M61.1116 37.5942L67.1345 39.42L72.0331 40.6792L73.479 37.5942L71.9603 34.5782L67.1345 35.766L61.1116 37.5942Z"
          fill="#2A2A2A"
        />
        <path
          opacity={0.9003}
          d="M61.1233 37.5942L67.1462 39.42L72.0354 40.6768L73.486 37.5942L71.9603 34.5829L67.1462 35.766L61.1233 37.5942Z"
          fill="#292929"
        />
        <path
          opacity={0.9027}
          d="M61.1328 37.5942L67.1604 39.42L72.0355 40.6745L73.4955 37.5942L71.9604 34.5853L67.1604 35.766L61.1328 37.5942Z"
          fill="#282828"
        />
        <path
          opacity={0.9052}
          d="M61.1445 37.5942L67.1745 39.42L72.0355 40.6697L73.5025 37.5942L71.9604 34.5877L67.1745 35.766L61.1445 37.5942Z"
          fill="#272727"
        />
        <path
          opacity={0.9076}
          d="M61.1562 37.5942L67.1862 39.42L72.0355 40.6673L73.5096 37.5942L71.9604 34.5901L67.1862 35.766L61.1562 37.5942Z"
          fill="#262626"
        />
        <path
          opacity={0.91}
          d="M61.168 37.5942L67.2003 39.42L72.0355 40.6649L73.5166 37.5942L71.9604 34.5948L67.2003 35.766L61.168 37.5942Z"
          fill="#252525"
        />
        <path
          opacity={0.9125}
          d="M61.1797 37.5942L67.2143 39.42L72.0355 40.6626L73.5236 37.5942L71.9604 34.5972L67.2143 35.766L61.1797 37.5942Z"
          fill="#242424"
        />
        <path
          opacity={0.9149}
          d="M61.1914 37.5942L67.226 39.42L72.0378 40.6602L73.5306 37.5942L71.9603 34.5996L67.226 35.766L61.1914 37.5942Z"
          fill="#232323"
        />
        <path
          opacity={0.9173}
          d="M61.2031 37.5942L67.2401 39.42L72.0378 40.6578L73.5376 37.5942L71.9603 34.602L67.2401 35.766L61.2031 37.5942Z"
          fill="#222222"
        />
        <path
          opacity={0.9198}
          d="M61.2148 37.5942L67.2542 39.42L72.0378 40.6554L73.5447 37.5942L71.9603 34.6068L67.2542 35.766L61.2148 37.5942Z"
          fill="#212121"
        />
        <path
          opacity={0.9222}
          d="M61.2266 37.5942L67.2659 39.42L72.0378 40.653L73.5517 37.5942L71.9603 34.6091L67.2659 35.766L61.2266 37.5942Z"
          fill="#202020"
        />
        <path
          opacity={0.9246}
          d="M61.2383 37.5942L67.28 39.42L72.0377 40.6483L73.5587 37.5942L71.9603 34.6115L67.28 35.766L61.2383 37.5942Z"
          fill="#1F1F1F"
        />
        <path
          opacity={0.9271}
          d="M61.25 37.5942L67.2917 39.42L72.0377 40.6459L73.5657 37.5942L71.9602 34.6163L67.2917 35.766L61.25 37.5942Z"
          fill="#1E1E1E"
        />
        <path
          opacity={0.9295}
          d="M61.2617 37.5942L67.3058 39.42L72.0401 40.6435L73.5728 37.5942L71.9602 34.6187L67.3058 35.766L61.2617 37.5942Z"
          fill="#1D1D1D"
        />
        <path
          opacity={0.9319}
          d="M61.2734 37.5942L67.3198 39.42L72.04 40.6411L73.5798 37.5942L71.9602 34.621L67.3198 35.766L61.2734 37.5942Z"
          fill="#1C1C1C"
        />
        <path
          opacity={0.9344}
          d="M61.2852 37.5942L67.3315 39.42L72.04 40.6388L73.5868 37.5942L71.9602 34.6234L67.3315 35.766L61.2852 37.5942Z"
          fill="#1B1B1B"
        />
        <path
          opacity={0.9368}
          d="M61.2969 37.5942L67.3456 39.42L72.04 40.6364L73.5938 37.5942L71.9602 34.6282L67.3456 35.766L61.2969 37.5942Z"
          fill="#1A1A1A"
        />
        <path
          opacity={0.9392}
          d="M61.3088 37.5942L67.3599 39.42L72.0402 40.634L73.6011 37.5942L71.9604 34.6306L67.3599 35.766L61.3088 37.5942Z"
          fill="#191919"
        />
        <path
          opacity={0.9416}
          d="M61.3181 37.5942L67.3715 39.42L72.0401 40.6316L73.6104 37.5942L71.9627 34.6329L67.3715 35.766L61.3181 37.5942Z"
          fill="#181818"
        />
        <path
          opacity={0.9441}
          d="M61.3298 37.5942L67.3856 39.42L72.0424 40.6292L73.6174 37.5942L71.9626 34.6353L67.3856 35.766L61.3298 37.5942Z"
          fill="#171717"
        />
        <path
          opacity={0.9465}
          d="M61.3416 37.5942L67.3997 39.42L72.0424 40.6245L73.6244 37.5942L71.9626 34.6401L67.3997 35.766L61.3416 37.5942Z"
          fill="#161616"
        />
        <path
          opacity={0.9489}
          d="M61.3533 37.5942L67.4114 39.42L72.0424 40.6221L73.6315 37.5942L71.9626 34.6425L67.4114 35.766L61.3533 37.5942Z"
          fill="#151515"
        />
        <path
          opacity={0.9514}
          d="M61.365 37.5942L67.4255 39.42L72.0424 40.6197L73.6385 37.5942L71.9626 34.6448L67.4255 35.766L61.365 37.5942Z"
          fill="#141414"
        />
        <path
          opacity={0.9538}
          d="M61.3767 37.5942L67.4372 39.42L72.0424 40.6173L73.6455 37.5942L71.9626 34.6472L67.4372 35.766L61.3767 37.5942Z"
          fill="#131313"
        />
        <path
          opacity={0.9562}
          d="M61.3884 37.5942L67.4512 39.42L72.0447 40.6149L73.6525 37.5942L71.9626 34.652L67.4512 35.766L61.3884 37.5942Z"
          fill="#121212"
        />
        <path
          opacity={0.9587}
          d="M61.4004 37.5942L67.4655 39.42L72.0449 40.6126L73.6598 37.5942L71.9628 34.6544L67.4655 35.766L61.4004 37.5942Z"
          fill="#111111"
        />
        <path
          opacity={0.9611}
          d="M61.4121 37.5942L67.4773 39.42L72.0449 40.6102L73.6668 37.5942L71.9628 34.6567L67.4773 35.766L61.4121 37.5942Z"
          fill="#101010"
        />
        <path
          opacity={0.9635}
          d="M61.4238 37.5942L67.4913 39.42L72.0449 40.6078L73.6739 37.5942L71.9627 34.6591L67.4913 35.766L61.4238 37.5942Z"
          fill="#0F0F0F"
        />
        <path
          opacity={0.966}
          d="M61.4355 37.5942L67.5054 39.42L72.0449 40.603L73.6809 37.5942L71.9627 34.6639L67.5054 35.766L61.4355 37.5942Z"
          fill="#0E0E0E"
        />
        <path
          opacity={0.9684}
          d="M61.4473 37.5942L67.5171 39.42L72.0449 40.6007L73.6879 37.5942L71.9627 34.6663L67.5171 35.766L61.4473 37.5942Z"
          fill="#0D0D0D"
        />
        <path
          opacity={0.9708}
          d="M61.459 37.5942L67.5312 39.42L72.0472 40.5983L73.6949 37.5942L71.9627 34.6686L67.5312 35.766L61.459 37.5942Z"
          fill="#0C0C0C"
        />
        <path
          opacity={0.9733}
          d="M61.4707 37.5942L67.5452 39.42L72.0472 40.5959L73.7019 37.5942L71.9627 34.671L67.5452 35.766L61.4707 37.5942Z"
          fill="#0B0B0B"
        />
        <path
          opacity={0.9757}
          d="M61.4824 37.5942L67.557 39.42L72.0472 40.5935L73.709 37.5942L71.9627 34.6758L67.557 35.766L61.4824 37.5942Z"
          fill="#0A0A0A"
        />
        <path
          opacity={0.9781}
          d="M61.4917 37.5942L67.5709 39.42L72.047 40.5911L73.7182 37.5942L71.9625 34.6782L67.5709 35.766L61.4917 37.5942Z"
          fill="#090909"
        />
        <path
          opacity={0.9805}
          d="M61.5037 37.5942L67.5829 39.42L72.0473 40.5888L73.7255 37.5942L71.9628 34.6805L67.5829 35.766L61.5037 37.5942Z"
          fill="#080808"
        />
        <path
          opacity={0.983}
          d="M61.5154 37.5942L67.597 39.42L72.0473 40.5864L73.7326 37.5942L71.9628 34.6829L67.597 35.766L61.5154 37.5942Z"
          fill="#070707"
        />
        <path
          opacity={0.9854}
          d="M61.5271 37.5942L67.611 39.42L72.0496 40.584L73.7396 37.5942L71.9627 34.6877L67.611 35.766L61.5271 37.5942Z"
          fill="#060606"
        />
        <path
          opacity={0.9878}
          d="M61.5388 37.5942L67.6228 39.42L72.0496 40.5792L73.7466 37.5942L71.9627 34.6901L67.6228 35.766L61.5388 37.5942Z"
          fill="#050505"
        />
        <path
          opacity={0.9903}
          d="M61.5505 37.5942L67.6368 39.42L72.0496 40.5769L73.7536 37.5942L71.9627 34.6924L67.6368 35.766L61.5505 37.5942Z"
          fill="#040404"
        />
        <path
          opacity={0.9927}
          d="M61.5623 37.5942L67.6509 39.42L72.0495 40.5745L73.7606 37.5942L71.9627 34.6972L67.6509 35.766L61.5623 37.5942Z"
          fill="#030303"
        />
        <path
          opacity={0.9951}
          d="M61.574 37.5942L67.6626 39.42L72.0495 40.5721L73.7677 37.5942L71.9627 34.6996L67.6626 35.766L61.574 37.5942Z"
          fill="#020202"
        />
        <path
          opacity={0.9976}
          d="M61.5857 37.5942L67.6767 39.42L72.0495 40.5697L73.7747 37.5942L71.9627 34.702L67.6767 35.766L61.5857 37.5942Z"
          fill="#010101"
        />
        <path
          opacity={0.48}
          d="M61.5974 37.5942L67.6907 39.42L72.0518 40.5673L73.7817 37.5942L71.9626 34.7043L67.6907 35.766L61.5974 37.5942Z"
          fill="black"
        />
      </g>
      <path
        d="M61.3816 37.6323L68.1908 39.6057L75 41.579V37.6323V33.6832L68.1908 35.6589L61.3816 37.6323Z"
        fill="url(#paint28_linear_16968_7127)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_16968_7127"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.8503 61.5556) scale(17.381 14.3555)"
      >
        <stop offset={0.4779} stopOpacity={0} />
        <stop offset={0.6242} stopColor="#020202" />
        <stop offset={0.7087} stopColor="#0B0B0B" />
        <stop offset={1} stopColor="#191919" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_16968_7127"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.3979 36.7772) scale(32.653 33.1152)"
      >
        <stop offset={0.916667} stopOpacity={0} />
        <stop offset={1} />
      </radialGradient>
      <radialGradient
        id="paint2_radial_16968_7127"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.9601 37.3938) scale(3.57948 3.63015)"
      >
        <stop offset={0.8346} />
        <stop offset={0.8447} stopColor="#101010" />
        <stop offset={1} stopColor="white" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_16968_7127"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.9601 36.4523) scale(3.57948 3.63015)"
      >
        <stop stopColor="#FDEAC8" />
        <stop offset={0.1637} stopColor="#FCDCA1" />
        <stop offset={0.4521} stopColor="#FFB624" />
        <stop offset={0.4669} stopColor="#5B2909" />
        <stop offset={0.7787} stopColor="#B57128" />
        <stop offset={0.8603} stopColor="#FDCD6F" />
        <stop offset={0.9359} stopColor="#FFB624" />
        <stop offset={0.9882} stopColor="#B57128" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_16968_7127"
        x1={35.2666}
        y1={2.1581}
        x2={36.5576}
        y2={2.1581}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_16968_7127"
        x1={26.4774}
        y1={3.5168}
        x2={27.7266}
        y2={3.18674}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_16968_7127"
        x1={18.3345}
        y1={7.13621}
        x2={19.4603}
        y2={6.49529}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_16968_7127"
        x1={11.3925}
        y1={12.7698}
        x2={12.3182}
        y2={11.857}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_16968_7127"
        x1={6.12497}
        y1={20.0334}
        x2={6.78417}
        y2={18.9076}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_16968_7127"
        x1={2.89058}
        y1={28.4322}
        x2={3.23356}
        y2={27.17}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_16968_7127"
        x1={1.90991}
        y1={37.3938}
        x2={1.90991}
        y2={36.0846}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_16968_7127"
        x1={3.24965}
        y1={46.3075}
        x2={2.90667}
        y2={45.0453}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_16968_7127"
        x1={6.81857}
        y1={54.5658}
        x2={6.15935}
        y2={53.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_16968_7127"
        x1={12.3735}
        y1={61.6058}
        x2={11.4478}
        y2={60.6931}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_16968_7127"
        x1={19.5357}
        y1={66.948}
        x2={18.4099}
        y2={66.3071}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_16968_7127"
        x1={27.8172}
        y1={70.2282}
        x2={26.5679}
        y2={69.8981}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_16968_7127"
        x1={36.6537}
        y1={71.2228}
        x2={35.3627}
        y2={71.2228}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_16968_7127"
        x1={45.4431}
        y1={69.8641}
        x2={44.1938}
        y2={70.1941}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_16968_7127"
        x1={53.5859}
        y1={66.2446}
        x2={52.4601}
        y2={66.8855}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_16968_7127"
        x1={60.5279}
        y1={60.6111}
        x2={59.6022}
        y2={61.5239}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_16968_7127"
        x1={65.7954}
        y1={53.3475}
        x2={65.1362}
        y2={54.4732}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_16968_7127"
        x1={69.0298}
        y1={44.9486}
        x2={68.6869}
        y2={46.2109}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_16968_7127"
        x1={70.0104}
        y1={35.987}
        x2={70.0104}
        y2={37.2963}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_16968_7127"
        x1={68.6708}
        y1={27.0734}
        x2={69.0138}
        y2={28.3356}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_16968_7127"
        x1={65.1018}
        y1={18.8151}
        x2={65.761}
        y2={19.9409}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_16968_7127"
        x1={59.5469}
        y1={11.775}
        x2={60.4726}
        y2={12.6878}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_16968_7127"
        x1={52.3847}
        y1={6.43282}
        x2={53.5105}
        y2={7.07374}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_16968_7127"
        x1={44.1032}
        y1={3.15271}
        x2={45.3525}
        y2={3.48276}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEDC2" />
        <stop offset={0.1767} stopColor="#F7EFA6" />
        <stop offset={0.3763} stopColor="#F3D16E" />
        <stop offset={0.4657} stopColor="#EEC65E" />
        <stop offset={0.5676} stopColor="#E4B650" />
        <stop offset={0.6237} stopColor="#AF7121" />
        <stop offset={0.6528} stopColor="#AF7121" />
        <stop offset={0.7152} stopColor="#F2D76C" />
        <stop offset={0.7921} stopColor="#F8E77D" />
        <stop offset={0.8441} stopColor="#F7F1A4" />
        <stop offset={0.8919} stopColor="#FFF2C5" />
        <stop offset={0.9376} stopColor="#E6AD06" />
        <stop offset={0.9439} stopColor="#E6AD06" />
        <stop offset={1} stopColor="#FBCB4A" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_16968_7127"
        x1={68.1905}
        y1={34.072}
        x2={68.1905}
        y2={41.6893}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDEAC8" />
        <stop offset={0.1637} stopColor="#FCDCA1" />
        <stop offset={0.4521} stopColor="#FFB624" />
        <stop offset={0.4669} stopColor="#5B2909" />
        <stop offset={0.7787} stopColor="#B57128" />
        <stop offset={0.8603} stopColor="#FDCD6F" />
        <stop offset={0.9359} stopColor="#FFB624" />
        <stop offset={0.9882} stopColor="#B57128" />
      </linearGradient>
      <clipPath id="clip0_16968_7127">
        <rect width={75} height={86} fill="white" transform="translate(0 0.00012207)" />
      </clipPath>
    </defs>
  </svg>
);

export default EarnNft;
