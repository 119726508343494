import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { v4 as uuidv4 } from 'uuid';
import { Image, Tooltip } from 'antd';
import moment from 'moment';

import { SocketContext } from 'context/socket';
import { EVENT_NAME_SOCKET, NFT_TYPE, TYPE_OF_ANT_DESIGN } from 'common/constant';
import LoadingContract from 'components/LoadingContract';
import ConfirmSuccess from 'components/ConfirmSuccess';
import showMessage from 'components/Message';
import ButtonPE from 'components/ButtonPE';
import ModalPE from 'components/Modal';
import { getClaimSignatureApi, testLuckApi, updateTxUserClaim } from 'services/nft';
import selectedAuthen from 'redux/authen/selector';

import BaseWalletService, { getProvider } from 'utils/baseWallet';

import EarnNft from 'resources/svg/EarnNft';
import EmptyBox from 'resources/images/box-empty.png';

const ClaimNftButton = ({ pool, dataSearch, setDataSearch, stakedBalance, poolAddress }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isTestLuckDisabled, setIsTestLuckDisabled] = useState(true);
  const [isLoadingTestLuckVisible, setIsLoadingTestLuckVisible] = useState(false);
  const [nftInfo, setNftInfo] = useState({ image: '', name: '', tokenId: '', address: '', type: null });
  const [isLoadingClaimVisible, setIsLoadingClaimVisible] = useState(false);
  const [txHash, setTxHash] = useState('');
  const [visibleConfirmSuccess, setVisibleConfirmSuccess] = useState(false);

  const { connector } = useWeb3React();
  const library = getProvider(connector?.provider);

  const wallet = new BaseWalletService().getInstance();

  const socket = useContext(SocketContext);

  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);

  useEffect(() => {
    const claimSuccess = (payload) => {
      setIsLoadingTestLuckVisible(false);
      const { success, pool } = payload;
      setIsSuccess(success);
      setIsModalVisible(true);
      if (success) {
        setNftInfo({
          image: pool?.userReward?.imageUrlM || pool?.userReward?.imageUrl,
          name: pool?.userReward?.name,
          address: pool?.userReward?.contractAddress,
          tokenId: pool?.userReward?.tokenId,
          type: pool?.userReward?.type,
        });
      }
    };
    if (pool?._id) {
      socket.on(`${EVENT_NAME_SOCKET.RANDOM}${selectedAddressWallet.toLowerCase()}${pool._id}`, claimSuccess);
    }
    return () => {
      socket.off(`${EVENT_NAME_SOCKET.RANDOM}${selectedAddressWallet.toLowerCase()}${pool._id}`, claimSuccess);
    };
  }, [pool]);

  useEffect(() => {
    if (pool?.rewardClaim?.status === 4 || pool?.rewardClaim?.status === 1) {
      setNftInfo({
        image: pool?.userReward?.imageUrlM || pool?.userReward?.imageUrl,
        name: pool?.userReward?.name,
        address: pool?.userReward?.contractAddress,
        tokenId: pool?.userReward?.tokenId,
        type: pool?.userReward?.type,
      });
    }
  }, [pool]);

  useEffect(() => {
    const isDurationSatisfied = moment().isAfter(moment(pool?.tokenStakings?.updatedAt).add(pool?.duration, 'days'));
    const isTierSatisfied = stakedBalance >= pool.tier1;
    if (!pool.rewardClaim) {
      if (pool?.isStop) return setIsTestLuckDisabled(false);
      if (isTierSatisfied && isDurationSatisfied) return setIsTestLuckDisabled(false);
      return setIsTestLuckDisabled(true);
    }
    if (pool.rewardClaim.status === 4) return setIsTestLuckDisabled(false);
    return setIsTestLuckDisabled(true);
  }, [pool, stakedBalance]);

  const onCloseModal = () => {
    setIsModalVisible(!isModalVisible);
    setDataSearch({ ...dataSearch, uuid: uuidv4() });
  };

  const handleTestLuck = async () => {
    setIsLoadingTestLuckVisible(true);
    try {
      const res = await testLuckApi({ poolId: pool._id });
      if (!res?.meta?.code) {
      } else {
        setIsLoadingTestLuckVisible(false);
      }
    } catch (error) {
      setIsLoadingTestLuckVisible(false);
    }
  };

  const handleCallback = () => {
    setVisibleConfirmSuccess(false);
    setDataSearch({ ...dataSearch, uuid: uuidv4() });
  };

  const callbackProcessing = async (hash) => {
    setTxHash(hash);
    const res = await updateTxUserClaim({ poolId: pool._id, txId: hash });
  };

  const callbackSuccess = () => {
    setTimeout(() => {
      setIsLoadingClaimVisible(false);
      setVisibleConfirmSuccess(true);
    }, 7000);
  };

  const callbackError = () => {
    setIsLoadingClaimVisible(false);
    showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'Failed to claim NFT.');
    setDataSearch({ ...dataSearch, uuid: uuidv4() });
  };

  const onClaimNFT = async () => {
    setIsLoadingClaimVisible(true);
    setIsModalVisible(false);
    try {
      const res = await getClaimSignatureApi({ poolId: pool._id });
      if (!res?.meta?.code) {
        if (nftInfo.type === NFT_TYPE.ERC_1155) {
          await wallet.claimReward1155NFT({
            library,
            signer: res.data.address,
            account: selectedAddressWallet,
            contractAddress: poolAddress,
            nftAddress: nftInfo.address,
            tokenId: nftInfo.tokenId,
            signature: res.data.signature,
            poolId: pool._id,
            internalTx: res.data.internalId,
            callbackSuccess: callbackSuccess,
            callbackError: callbackError,
            callbackProcessing: callbackProcessing,
          });
        } else {
          await wallet.claimReward721NFT({
            library,
            signer: res.data.address,
            account: selectedAddressWallet,
            contractAddress: poolAddress,
            nftAddress: nftInfo.address,
            tokenId: nftInfo.tokenId,
            signature: res.data.signature,
            poolId: pool._id,
            internalTx: res.data.internalId,
            callbackSuccess: callbackSuccess,
            callbackError: callbackError,
            callbackProcessing: callbackProcessing,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="claim-nft">
      {(pool?.rewardClaim?.status === 4 || pool?.rewardClaim?.status === 1) && (
        <Tooltip title={nftInfo.name}>
          <p className="title text-ellipsis" style={{ textAlign: 'center' }}>
            {nftInfo.name}
          </p>
        </Tooltip>
      )}
      {pool?.rewardClaim?.status === 3 && (
        <p className="title" style={{ textAlign: 'center' }}>
          Wish you luck next time!
        </p>
      )}
      {!pool?.rewardClaim && (
        <p className="title" style={{ textAlign: 'center' }}>
          Test your luck to earn NFT
        </p>
      )}

      {(pool?.rewardClaim?.status === 4 || pool?.rewardClaim?.status === 1) && (
        <Image
          src={nftInfo.image}
          width="100%"
          className="nft-image"
          preview={{
            visible: false,
            onVisibleChange: () => {
              window.open(nftInfo.image);
            },
          }}
        />
      )}
      {pool?.rewardClaim?.status === 3 && <img src={EmptyBox} className="empty-box" />}
      {!pool?.rewardClaim && <EarnNft className="earn-nft" />}
      <ButtonPE
        text={pool?.rewardClaim?.status === 4 || pool?.rewardClaim?.status === 1 ? 'Claim' : 'Test your luck'}
        classNameStyle="btn-claim"
        variant="success"
        onClick={pool?.rewardClaim?.status === 4 ? onClaimNFT : handleTestLuck}
        disabled={isTestLuckDisabled}
      />
      <ModalPE visible={isModalVisible}>
        <div className="claim-nft-modal">
          {isSuccess ? (
            <>
              <h2 className="title">Congratulations!</h2>
              <p className="content"> Today is a lucky day, let's claim your NFT </p>
              <img src={nftInfo.image} alt="nft" />
              <Tooltip title={nftInfo.name}>
                <span className="nft-name">{nftInfo.name}</span>
              </Tooltip>
              <div className="nft-actions">
                <ButtonPE text="Claim" classNameStyle="btn-claim" variant="success" onClick={onClaimNFT} />
                <span className="later" onClick={onCloseModal}>
                  Later
                </span>
              </div>
            </>
          ) : (
            <>
              <img src={EmptyBox} />
              <p className="next-time">
                Unfortunately, you don't receive any NFT in this pool.
                <br />
                Wish you luck next time!
              </p>
              <div className="nft-actions">
                <ButtonPE text="Close" classNameStyle="btn-claim" variant="success" onClick={onCloseModal} />
              </div>
            </>
          )}
        </div>
      </ModalPE>
      {/* <ModalPE visible={false} className="nft-detail-modal">
        <IconClose className="close-icon" />
        <div className="nft-detail-modal__body">
          <img src={Nft} className="nft-detail-modal__image" />
          <div className="nft-detail-modal__content">
            <h2 className="nft-detail-modal__name">NFT Name</h2>
            <div>
              <p className="nft-detail-modal__property">NFT Format</p>
              <p className="nft-detail-modal__property--value">ERC-1155</p>
            </div>
            <div>
              <p className="nft-detail-modal__property">Contract Address</p>
              <Paragraph
                copyable={{
                  tooltips: false,
                  text: '0x4C11...9D8F',
                  icon: [<IconCopy key="copy" />, <IconChecked key="checked" />],
                }}
                className="nft-detail-modal__property--value"
              >
                0x4C11...9D8F
              </Paragraph>
            </div>
            <div>
              <p className="nft-detail-modal__property">NFT Token ID</p>
              <Paragraph
                copyable={{
                  tooltips: false,
                  text: '0x4C11...9D8F',
                  icon: [<IconCopy key="copy" />, <IconChecked key="checked" />],
                }}
                className="nft-detail-modal__property--value"
              >
                1238712
              </Paragraph>
            </div>
          </div>
        </div>
      </ModalPE> */}
      <LoadingContract visible={isLoadingTestLuckVisible} title="Processing to test your luck" content="Please wait" />
      <LoadingContract
        visible={isLoadingClaimVisible}
        title="Transaction submitting"
        content="Waiting for transaction"
      />
      <ConfirmSuccess
        onCancel={handleCallback}
        visible={visibleConfirmSuccess}
        title={`Success`}
        content="Claimed successfully."
        hash={txHash}
        textBtn="Done"
        onDone={handleCallback}
      />
    </div>
  );
};

export default ClaimNftButton;
