import React from 'react';
import NodataImage from 'resources/images/image-nodata.png';

const Nodata = () => {
  return (
    <div className="wrap-no-data" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img className="image-no-data" src={NodataImage} style={{ marginTop: 54 }} />
    </div>
  );
};

export default Nodata;
