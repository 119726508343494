import { api } from './api';

export const stakeTokenApi = (params) => {
  return api.post('/staking/stake-token', params);
};

export const unStakeTokenApi = (params) => {
  return api.post('/staking/unstaking-token', params);
};

export const claimTokenApi = (params) => {
  return api.post('/staking/claim-token', params);
};

export const getListPoolTokenApi = (params) => {
  return api.get('/pool/user/token', params);
};

export const getListPoolTokenApiUnauthorized = (params) => {
  return api.get('/pool/all-token', params);
};

export const updateUnstakeTokenApi = (params) => {
  return api.put('/staking/update-action-status-unstake-token', params);
};

export const getNftRewardInPoolApi = (id, params) => {
  return api.get(`/pool/${id}/reward-list`, params);
};
