import Web3 from 'web3';

export const LIMIT = 5;
export const TIME_REQUEST_API_STATUS_KYC = 30 * 1000;
export const FORMAT_DATE_DMY_HMS = 'DD-MM-YYYY HH:mm:ss';
export const FORMAT_DATE_DMY = 'DD/MM/YYYY';
export const LIMIT_UPLOAD_FILE = 10;
export const ACCEPT_TYPE = '.jpg, .jpeg, .png';
export const TIME_RESEND_CODE = 60;
export const REGEX_NUMBER = /^\d+\.?\d*$/;
export const REGEX_ONLY_DECIMAL_PART = /^[.]?\d*$/;
export const REGEX_ALL_CHARACTER_FROM_INDEX_8 = /(?:^.{8}).*/g;
export const REGEX_ALL_CHARACTER_FROM_INDEX_18 = /(?:^.{18}).*/g;
export const REGEX_INPUT_NUMBER = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'ArrowLeft', 'ArrowRight'];
export const LENGTH_NUMBER = 12;
export const HTTP_RESPONSE = {
  OK: 200,
  ERROR_CODE_401: 401,
  ERROR_CODE_400: 400,
};

export const Language = {
  EN: 'en',
  JA: 'ja',
  KO: 'ko',
  CN: 'cn',
};

export const TYPE_EXTRA_INFO = {
  NAME: 'name',
  VALUE: 'value',
};

export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  IMG_DONE: 'done',
};

export const VERIFIED_STATUS = {
  UNVERIFIED: 'UNVERIFIED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export const KYC_STATUS = {
  UNVERIFIED: 'unverified',
  VERIFYING: 'verifying',
  REJECTED: 'rejected',
  VERIFIED: 'verified',
};

export const KYC_DOCUMENT = {
  LICENSE: 'license',
  PASSPORT: 'passport',
  IDCARD: 'card',
};

export const RESET_PASSWORD_STATUS = {
  NEW_PASSWORD: 'new_password',
  SUCCESS: 'success',
};

export const COIN_TYPE = {
  BIV: 'biv',
  WBIV: 'wbiv',
  ETH: 'eth',
};

export const CONFIRM_MODAL = {
  VERIFIED_TRANSACTION: 'verified',
  SUBMIT_COMPLETED: 'completed',
  CONFIRM_SUBMIT: 'confirm',
  WITHDRAW_NOTICE: 'notice',
};

export const BALANCE_TABLE_TITLE = {
  TOKEN: 'token',
  DEPOSIT: 'deposit',
  BALANCE: 'balance',
  ACTIONS: 'actions',
};

export const DEFAULT_PAGE_SIZE = 10;

export const PAGE_SIZE_OPTION = [10, 20, 50];

export const HISTORY_TAB = {
  DEPOSIT: 'DEPOSIT',
  SWAP: 'SWAP',
  WITHDRAW: 'WITHDRAW',
};

export const KYC_DOCUMENT_SELECT = {
  card: 'ID Card',
  license: 'Driver’s License',
  passport: 'Passport',
};

export const COIN_TYPE_HISTORY = {
  biv: 'BIV',
  wbiv: 'WBIV',
  ether: 'ETH',
  eth: 'ETH',
};

export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const NETWORK_URL_BSC_HIDE_API_KEY = 'https://bitter-solitary-sunset.bsc.quiknode.pro/';

export const LIST_NETWORK_RPC_MAINNET: any = {
  56: process.env.REACT_APP_IS_DEV
    ? 'https://bsc.getblock.io/mainnet/?api_key=07c1f2e2-8e3d-4614-8393-88385c1e5e52'
    : `${NETWORK_URL_BSC_HIDE_API_KEY}${process.env.REACT_APP_GETBLOCK_API_KEY}`,
  137: 'https://rpc-mainnet.maticvigil.com/',
  1: 'https://mainnet.infura.io/v3/50f6131e95134c0fba1a009b561a31d9',
};

export const randomRPCTesnet = (listRPC: any) => {
  const lengthList = listRPC?.length;
  const randomNumber = Math.floor(Math.random() * 10) % lengthList;
  return listRPC[randomNumber];
};

export const LIST_BSC_TESTNET = [
  'https://data-seed-prebsc-1-s1.binance.org:8545/',
  'https://data-seed-prebsc-2-s1.binance.org:8545/',
  'https://data-seed-prebsc-1-s2.binance.org:8545/',
  'https://data-seed-prebsc-2-s2.binance.org:8545/',
  'https://data-seed-prebsc-1-s3.binance.org:8545/',
  'https://data-seed-prebsc-2-s3.binance.org:8545/',
];

export const LIST_NETWORK_RPC_TESTNET: any = {
  97: randomRPCTesnet(LIST_BSC_TESTNET),
  80001: 'https://rpc-mumbai.maticvigil.com/',
  5: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
};

export const METAMASK_DEEPLINK = 'https://metamask.io/download';

export const BRIDGE_URL = 'https://pancakeswap.bridge.walletconnect.org';

export const NETWORK_URL_BSC = process.env.REACT_APP_NETWORK_URL_BSC;

export const KEY_WALLETCONNECT = 'walletconnect';

export const KEY_DISCONNECT = 'disconnect';

export const SIGN_MESSAGE =
  'Welcome. By signing this message you are verifying your digital identity. This is completely secure and does not cost anything!';

export const maxBalance = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const PROVIDERS_BSC = [
  new Web3(NETWORK_URL_BSC as string),
  new Web3(process.env.REACT_APP_PROVIDER_GETBLOCK as string),
  new Web3(process.env.REACT_APP_PROVIDER_QUICKNODE as string),
];

export const DATA_SEARCH_STATUS = [
  {
    name: 'Live',
    value: 1,
  },
  {
    name: 'Finished',
    value: 3,
  },
];

export const DEFAULT_SEARCH_STATUS = 1;

export const nftStaking = 'NftStaking';
export const tokenStaking = 'TokenStaking';

export const live = 1;
export const finish = 3;

export const lpToken = 'lp_token';
export const zToken = 'z_token';

export const DATA_SEARCH_TOKEN_STAKING_STATUS = [
  {
    name: 'Single token',
    value: zToken,
  },
  {
    name: 'LP token',
    value: lpToken,
  },
];

export const DATA_SEARCH_POOL_MECHANISM = [
  {
    name: 'Allocation',
    value: 1,
  },
  {
    name: 'Linear',
    value: 2,
  },
];

export const DATA_SEARCH_POOL_MECHANISM_TYPE = {
  LINEAR: 2,
  ALLOCATION: 1,
};

export const POOL_REWARD_TYPE = {
  TOKEN: 1,
  NFT: 2,
};

export const TIER_TYPE = {
  TIER_1: '1',
  TIER_2: '2',
  TIER_3: '3',
  TIER_4: '4',
};

export const NFT_TYPE = {
  ERC_721: 1,
  ERC_1155: 2,
};

export const formatDateTime = 'DD/MM/YYYY HH:mm:ss';

export const DEFAULT_SEARCH_TOKEN_STATUS = 'z_token';

export const numberToRound = (num = 0, fix: number) => {
  return Number(num)
    .toFixed(fix)
    .replace(/(\.0+|0+)$/, '');
};

export const BREAKPOINT_LG = 1024;
export const BREAKPOINT_SM = 577;

export const linkRouters = {
  home: '/',
  nftStaking: 'nft-staking',
  tokenStaking: 'token-staking',
  myNft: 'my-nft',
};

export const BSCMainnetChainId = '0x38';
export const BSCMainnetChainIdDec = 56;
export const BSCTestnetChainId = '0x61';
export const BSCTestnetChainIdDec = 97;
export const EtherMainnetChainIdDec = 1;

export const EtherTestnetChainId = '0x4';
export const EtherTestnetChainIdDec = 4;

export const PolygonMainnetChainIdDec = 137;
export const PolygonTestnetChainId = '0x13881';
export const PolygonTestnetChainIdDec = 80001;

export const CHAIN_INFO = {
  BSC: {
    name: 'Binance Smart Chain',
    value: BSCMainnetChainIdDec,
    valueString: BSCMainnetChainIdDec.toString(),
    textWarning: 'Binance Smart Chain - Mainnet',
    url: 'https://bscscan.com',
    suffixToken: 'BEP-20',
    explorerName: 'BSCscan',
  },
  BSC_TESTNET: {
    name: 'Binance Smart Chain Testnet',
    value: BSCTestnetChainIdDec,
    valueString: BSCTestnetChainIdDec.toString(),
    textWarning: 'Binance Smart Chain - Testnet',
    url: 'https://testnet.bscscan.com',
    suffixToken: 'BEP-20',
    explorerName: 'BSCscan',
  },
  ETHER: {
    name: 'Ethereum',
    value: EtherMainnetChainIdDec,
    valueString: EtherMainnetChainIdDec.toString(),
    textWarning: 'Ethereum - Mainnet',
    url: 'https://etherscan.io',
    suffixToken: 'ERC-20',
    explorerName: 'Etherscan',
  },
  ETHER_TESTNET: {
    name: 'Ethereum Testnet (Rinkeby)',
    value: EtherTestnetChainIdDec,
    valueString: EtherTestnetChainIdDec.toString(),

    textWarning: 'Ethereum (Rinkeby) - Testnet',
    url: 'https://rinkeby.etherscan.io',
    suffixToken: 'ERC-20',
    explorerName: 'Etherscan',
  },
  POLYGON: {
    name: 'Polygon',
    value: PolygonMainnetChainIdDec,
    valueString: PolygonMainnetChainIdDec.toString(),
    textWarning: 'Polygon - Mainnet',
    url: 'https://polygonscan.com',
    suffixToken: 'ERC-20',
    explorerName: 'Polygonscan',
  },
  POLYGON_TESTNET: {
    name: 'Polygon Mumbai Testnet',
    value: PolygonTestnetChainIdDec,
    valueString: PolygonTestnetChainIdDec.toString(),
    textWarning: 'Polygon Mumbai - Testnet',
    url: 'https://mumbai.polygonscan.com',
    suffixToken: 'ERC-20',
    explorerName: 'Polygonscan',
  },
};

export const LIST_NETWORK_PRODUCTION = [CHAIN_INFO.BSC, CHAIN_INFO.ETHER, CHAIN_INFO.POLYGON];
export const LIST_NETWORK_TESTNET = [CHAIN_INFO.BSC_TESTNET, CHAIN_INFO.ETHER_TESTNET, CHAIN_INFO.POLYGON_TESTNET];

export const LIST_NETWORK_DEV = [CHAIN_INFO.BSC_TESTNET, CHAIN_INFO.ETHER_TESTNET, CHAIN_INFO.POLYGON_TESTNET];

export const formatDate = 'DD/MM/YYYY';

export const erc721Collection = '0x25722372aBEE915AC4e82345890c794bEf49A72f';

export const EVENT_NAME_SOCKET = {
  CREATE_POOL: 'createPool',
  UPDATE_POOL: 'updatePool',
  STAKE: 'stake',
  UNSTAKE: 'unStake',
  CLAIM: 'claim',
  RANDOM: 'random',
};

export const LINK_BY_TOKEN = process.env.REACT_APP_BY_TOKEN;
export const LINK_BY_LP_TOKEN = process.env.REACT_APP_BY_LP_TOKEN;
export const NETWORK_DEFAULT = process.env.REACT_APP_IS_DEV ? 97 : 56;
export const CURRENT_WALLET = {
  METAMASK: 'metamask',
  WALLET_CONNECT: 'wallet_connect',
};
