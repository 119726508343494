import * as React from 'react';

const MyNft = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99984 3.33335C8.37298 3.33335 6.87331 3.46752 5.66959 3.6265C4.58627 3.76957 3.76939 4.58646 3.62632 5.66978C3.46734 6.87349 3.33317 8.37316 3.33317 10C3.33317 11.6269 3.46734 13.1266 3.62632 14.3303C3.76939 15.4136 4.58627 16.2305 5.66959 16.3735C6.87331 16.5325 8.37298 16.6667 9.99984 16.6667C11.6267 16.6667 13.1264 16.5325 14.3301 16.3735C15.4134 16.2305 16.2303 15.4136 16.3734 14.3303C16.5323 13.1266 16.6665 11.6269 16.6665 10C16.6665 8.37316 16.5323 6.87349 16.3734 5.66978C16.2303 4.58646 15.4134 3.76957 14.3301 3.6265C13.1264 3.46752 11.6267 3.33335 9.99984 3.33335ZM5.45137 1.97418C3.62071 2.21596 2.21577 3.62089 1.974 5.45155C1.80778 6.71009 1.6665 8.28442 1.6665 10C1.6665 11.7156 1.80778 13.29 1.974 14.5485C2.21577 16.3792 3.62071 17.7841 5.45137 18.0259C6.70991 18.1921 8.28423 18.3334 9.99984 18.3334C11.7154 18.3334 13.2898 18.1921 14.5483 18.0259C16.379 17.7841 17.7839 16.3792 18.0257 14.5485C18.1919 13.29 18.3332 11.7156 18.3332 10C18.3332 8.28442 18.1919 6.71009 18.0257 5.45155C17.7839 3.62089 16.379 2.21596 14.5483 1.97418C13.2898 1.80797 11.7154 1.66669 9.99984 1.66669C8.28423 1.66669 6.70991 1.80797 5.45137 1.97418Z"
      fill="#212121"
    />
    <path
      d="M7.50002 9.16665C8.4205 9.16665 9.16669 8.42045 9.16669 7.49998C9.16669 6.57951 8.4205 5.83331 7.50002 5.83331C6.57955 5.83331 5.83335 6.57951 5.83335 7.49998C5.83335 8.42045 6.57955 9.16665 7.50002 9.16665Z"
      fill="#212121"
    />
    <path
      d="M15.1011 10.5894L16.5964 12.0847C16.5448 12.8648 16.4695 13.5887 16.3864 14.2317L13.9226 11.7679C13.5972 11.4425 13.0695 11.4425 12.7441 11.7679L10.9345 13.5775C9.95814 14.5539 8.37523 14.5539 7.39892 13.5775L7.25594 13.4346C6.9305 13.1091 6.40287 13.1091 6.07743 13.4346L4.06987 15.4421C3.83731 15.1268 3.68171 14.7484 3.62648 14.3302C3.59637 14.1022 3.56714 13.8635 3.53955 13.6154L4.89892 12.2561C5.87523 11.2797 7.45814 11.2797 8.43445 12.2561L8.57743 12.399C8.90287 12.7245 9.4305 12.7245 9.75594 12.399L11.5656 10.5894C12.5419 9.61308 14.1248 9.61308 15.1011 10.5894Z"
      fill="#212121"
    />
  </svg>
);

export default MyNft;
