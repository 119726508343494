import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { Col, Collapse, Row, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import * as Yup from 'yup';

import { BREAKPOINT_SM, finish, numberToRound, POOL_REWARD_TYPE } from 'common/constant';
import InputComponent from 'components/Input';
import LoadingContract from 'components/LoadingContract';
import NumberFormat from 'components/NumberFormat';
import selectedAuthen from 'redux/authen/selector';
import selectedToken from 'redux/tokenStaking/selector';
import { useWindowSize } from 'utils';
import BaseWalletService, { getProvider } from 'utils/baseWallet';

import PoolInfo from '../PoolInfo';
import ApproveButton from './ActionStaking/ApproveButton';
import ClaimButton from './ActionStaking/ClaimButton';
import StakeButton from './ActionStaking/StakeButton';
import UnStakeButton from './ActionStaking/UnStakeButton';
import ClaimNftButton from './ActionStaking/ClaimNftButton';

const { Panel } = Collapse;

const PoolDetail = ({
  pool,
  index,
  activeKey,
  setActiveKey,
  dataSearch,
  setDataSearch,
  onOpenModal,
  currentRpc,
  poolAddress,
  ...props
}) => {
  const wallet = new BaseWalletService().getInstance();
  const { connector, chainId } = useWeb3React();
  const library = getProvider(connector?.provider);

  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);
  const listPoolTokens = useSelector(selectedToken.selectedpoolTokens);
  const network = useSelector((state) => state.HomeSlice.network);

  const [width] = useWindowSize();
  const isMobile = width < BREAKPOINT_SM;
  const [initialValuesForm, setInitialValuesForm] = useState({ amountStake: '', amountUnStake: '' });
  const [isDisableUnstake, setIsDisableUnstake] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceString, setBalanceString] = useState('');
  const [isApproved, setIsApproved] = useState(false);
  const [stakedAmount, setStakedAmount] = useState({ stakedBalance: 0, stakedBalanceString: '' });
  const [stakingLimit, setStakingLimit] = useState(0);
  const [stakingLimitString, setStakingLimitString] = useState('');
  const [visibleLoaingContract, setVisibleLoaingContract] = useState(false);
  const [earnedToken, setEarnedToken] = useState({ earned: 0, earnedTokenUsd: 0 });

  useEffect(() => {
    const tokenId = pool?.stakeToken?.tokenId;
    const checkAllowanceAndGetBalance = async () => {
      const poolData = await Promise.all([
        wallet.isAllowance({
          tokenAddress: tokenId,
          library,
          account: selectedAddressWallet,
          contractAddress: poolAddress,
          callback: (data) => {
            setIsApproved(data);
          },
        }),
        wallet.checkBuyerBalance(library, selectedAddressWallet, tokenId),
        wallet.getMaxAmountStake({
          poolId: pool?._id,
          contractAddress: poolAddress,
          currentAccount: selectedAddressWallet,
          library,
        }),
        wallet.getMaxTVL({
          library,
          poolId: pool?._id,
          contractAddress: poolAddress,
          currentAccount: selectedAddressWallet,
          callbackError: () => {},
        }),
        wallet.getPoolInfo({
          library,
          poolId: pool?._id,
          contractAddress: poolAddress,
          currentAccount: selectedAddressWallet,
          callbackError: () => {},
        }),
      ]);
      const valueLockedLeft = new BigNumber(poolData[3]?.toString())
        .minus(poolData[4]?.stakedBalance?.toString())
        .dividedBy(Math.pow(10, 18));
      if (valueLockedLeft.toNumber() >= poolData[1]?.balance) {
        setStakingLimit(poolData[1]?.balance);
        setStakingLimitString(poolData[1]?.balanceString);
      } else {
        setStakingLimit(valueLockedLeft.toNumber());
        setStakingLimitString(valueLockedLeft.multipliedBy(Math.pow(10, 18)).toString());
      }
      setBalance(poolData[1]?.balance);
      setBalanceString(poolData[1]?.balanceString);
      setStakedAmount({
        stakedBalance: poolData[2]?.stakedBalance,
        stakedBalanceString: poolData[2]?.stakedBalanceString,
      });
    };
    if (connector && selectedAddressWallet && chainId === parseInt(network) && tokenId) checkAllowanceAndGetBalance();
  }, [connector, pool, selectedAddressWallet, chainId]);

  useEffect(() => {
    const { stakedBalance } = stakedAmount;
    if (stakedBalance) {
      setInitialValuesForm({ ...initialValuesForm, amountUnStake: stakedBalance });
    }
  }, [stakedAmount]);

  useEffect(() => {
    if (pool.endDate && dataSearch.status === finish) {
      const isPoolEnded = moment(Date.now()).isAfter(pool.endDate);
      setIsDisableUnstake(isPoolEnded);
    }
  }, [pool]);

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setContext('Error Info', {
        poolId: pool._id,
        address: selectedAddressWallet,
      });
    });
  }, [pool]);

  const maxAmountStake = (setfieldvalue) => {
    if (dataSearch.status !== finish) {
      setfieldvalue('amountStake', stakingLimit);
    }
  };

  const maxAmountUnStake = (setfieldvalue) => {
    if (isApproved && !dataSearch.status !== finish) setfieldvalue('amountUnStake', stakedAmount.stakedBalance);
  };

  //get reward claim
  useEffect(() => {
    const getRewardClaim = (data = null) => {
      if (data !== null && data == index) {
        wallet.rewardClaim({
          pool: listPoolTokens.records[data],
          type: 'TokenStaking',
          currentAccount: selectedAddressWallet,
          library,
          contractAddress: poolAddress,
          callback: (data) => {
            setEarnedToken(data);
          },
        });
      }
    };
    let callClaim;
    if (selectedAddressWallet) {
      callClaim = setInterval(function () {
        getRewardClaim(activeKey[0]);
      }, 3000);
    }

    if (!activeKey.length) {
      clearInterval(callClaim);
    }

    return () => clearInterval(callClaim);
  }, [activeKey[0], selectedAddressWallet]);

  const renderValueClaim = () => {
    let data = earnedToken.earned;
    return data;
  };

  const resetAmountStake = (setFieldValue) => {
    setFieldValue('amountStake', '');
  };

  const resetAmountUnstake = (setFieldValue) => {
    setFieldValue('amountUnStake', '');
  };

  const renderBalance = () => {
    return (
      <Tooltip title={<NumberFormat thousandSeparator value={balance} displayType="text" />}>
        <NumberFormat
          thousandSeparator
          value={numberToRound(balance, 2)}
          displayType="text"
          suffix={` ${pool.currencyName}`}
        />
      </Tooltip>
    );
  };

  function numberWithCommas(number) {
    if (number) {
      let positiveNumber = number.toString().split('.')[0];
      let decimalNumber = number.toString().split('.')[1];

      return decimalNumber
        ? `${positiveNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimalNumber}`
        : positiveNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return 0;
  }

  const validationSchema = Yup.object().shape({
    amountStake: Yup.number()
      .nullable()
      .min(
        pool.minimumAmountStake ? pool.minimumAmountStake : 0,
        `Please input number of token greater than ${numberWithCommas(pool.minimumAmountStake)}`,
      ),
  });

  const renderStakingLimit = () => {
    return (
      <Tooltip title={<NumberFormat thousandSeparator value={stakingLimit} displayType="text" decimalScale={18} />}>
        <NumberFormat
          thousandSeparator
          value={stakingLimit || 0}
          decimalScale={2}
          displayType="text"
          suffix={` ${pool.currencyName}`}
        />
      </Tooltip>
    );
  };

  const renderMinStakeToken = () => {
    return (
      <Tooltip
        title={<NumberFormat thousandSeparator value={pool?.minimumAmountStake} displayType="text" decimalScale={18} />}
      >
        <NumberFormat
          thousandSeparator
          value={pool?.minimumAmountStake || 0}
          decimalScale={2}
          displayType="text"
          suffix={` ${pool.currencyName}`}
        />
      </Tooltip>
    );
  };

  return (
    <Panel
      {...props}
      className={`${activeKey.includes(index.toString()) && isMobile && 'rotate-180'}`}
      showArrow={false}
      header={
        <PoolInfo
          data={pool}
          activeKey={activeKey}
          onOpenModal={onOpenModal}
          currentRpc={currentRpc}
          poolAddress={poolAddress}
        />
      }
      key={index}
    >
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <Row className="content-item">
          <Col xxl={16} xl={16} md={14} sm={24} className="content--left">
            <Formik
              enableReinitialize
              initialValues={initialValuesForm}
              validationSchema={validationSchema}
              validateOnChange
            >
              {({ setFieldValue, values, errors }) => {
                return (
                  <Form onSubmit={() => {}}>
                    <div className="stake">
                      <div className="available-stake">
                        <span className="available-stake-title">
                          <p className="title">Available to stake</p>
                        </span>
                        <div className="stake-amount">
                          <Field
                            className=""
                            placeholder={'00.00'}
                            name="amountStake"
                            component={InputComponent}
                            typeNumber
                            button="Max"
                            maxClick={() => maxAmountStake(setFieldValue)}
                            maxLengthNumber={stakingLimit}
                            disabled={dataSearch.status === finish}
                          />
                          {/* <p className="balance">Balance: {renderBalance()}</p> */}
                          {pool.minimumAmountStake ? (
                            <div className="balance">
                              Minimum Staking Amount: {renderMinStakeToken()}&nbsp;{' '}
                              <Tooltip
                                title={`The minimum number of ${pool.currencyName} that you can stake into this pool.`}
                              >
                                <QuestionCircleFilled style={{ color: '#c52b8b' }} />
                              </Tooltip>
                            </div>
                          ) : (
                            ''
                          )}

                          <div className="balance">
                            Staking limit: {renderStakingLimit()} &nbsp;{' '}
                            <Tooltip
                              title={`The maximum number of ${pool.currencyName} that you can stake into this pool.`}
                            >
                              <QuestionCircleFilled style={{ color: '#c52b8b' }} />
                            </Tooltip>
                          </div>

                          {isApproved ? (
                            <StakeButton
                              values={values}
                              errors={errors}
                              setVisibleLoaingContract={setVisibleLoaingContract}
                              pool={pool}
                              dataSearch={dataSearch}
                              setDataSearch={setDataSearch}
                              maxAmountStake={() => maxAmountStake(setFieldValue)}
                              resetFieldValue={() => resetAmountStake(setFieldValue)}
                              balance={stakingLimit}
                              balanceString={stakingLimitString}
                              poolAddress={poolAddress}
                            />
                          ) : (
                            <ApproveButton
                              setVisibleLoaingContract={setVisibleLoaingContract}
                              dataSearch={dataSearch}
                              tokenId={pool?.stakeToken?.tokenId}
                              setIsApproved={setIsApproved}
                              poolAddress={poolAddress}
                            />
                          )}
                        </div>
                      </div>

                      <div className="staked-amount">
                        <p className="title">Staked amount</p>
                        <div className="unstake-amount">
                          <Field
                            className=""
                            placeholder={'00.00'}
                            name="amountUnStake"
                            component={InputComponent}
                            typeNumber
                            button="Max"
                            maxClick={() => maxAmountUnStake(setFieldValue)}
                            maxLengthNumber={stakedAmount.stakedBalance}
                            disabled={!isApproved || !pool.tokenStakings || isDisableUnstake}
                          />
                          <UnStakeButton
                            isApproved={isApproved}
                            setVisibleLoaingContract={setVisibleLoaingContract}
                            pool={pool}
                            stakedAmount={stakedAmount}
                            values={values}
                            dataSearch={dataSearch}
                            setDataSearch={setDataSearch}
                            resetFieldValue={() => resetAmountUnstake(setFieldValue)}
                            poolAddress={poolAddress}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
          <Col xxl={8} xl={8} md={10} sm={24} className="content--right z-token">
            <div className="total-reward">
              <div className={`${pool.rewardType === POOL_REWARD_TYPE.TOKEN ? 'full-width' : ''}`}>
                <p className="title">
                  Total Rewards <b>({pool.currencyNameReward})</b>
                </p>
                <div className="claim-reward">
                  <div className="total-rewards">
                    <Tooltip
                      title={
                        <NumberFormat
                          thousandSeparator
                          value={renderValueClaim() * Math.pow(10, -18)}
                          displayType="text"
                          suffix={` ${pool.currencyNameReward}`}
                          decimalScale={18}
                        />
                      }
                    >
                      {Math.floor(renderValueClaim() * Math.pow(10, -10)) === 0 ? (
                        <div>
                          <span>{renderValueClaim() * Math.pow(10, -18)}</span>
                        </div>
                      ) : (
                        <div>
                          <NumberFormat
                            thousandSeparator
                            value={renderValueClaim() * Math.pow(10, -18)}
                            displayType="text"
                            decimalScale={8}
                          />
                        </div>
                      )}
                    </Tooltip>
                  </div>
                  <ClaimButton
                    isApproved={isApproved}
                    pool={pool}
                    dataSearch={dataSearch}
                    setVisibleLoaingContract={setVisibleLoaingContract}
                    activeKey={activeKey}
                    earnedToken={earnedToken}
                    poolAddress={poolAddress}
                    setDataSearch={setDataSearch}
                  />
                </div>
              </div>
              {pool.rewardType === POOL_REWARD_TYPE.NFT && (
                <div>
                  <ClaimNftButton
                    pool={pool}
                    dataSearch={dataSearch}
                    setDataSearch={setDataSearch}
                    stakedBalance={stakedAmount.stakedBalance}
                    poolAddress={poolAddress}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <LoadingContract
          visible={visibleLoaingContract}
          title={`Transaction submitting`}
          content={`Waiting for confirmation`}
        />
      </ErrorBoundary>
    </Panel>
  );
};

export default React.memo(PoolDetail);
