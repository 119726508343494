import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import { createFilter } from 'redux-persist-transform-filter';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

/**
 *  blacklist config redux
 *  whitelist config redux persit
 */

const saveAuthenSliceFilter = createFilter('AuthenSlice', ['address', 'token', 'currentWallet']);
const saveHomeSliceFilter = createFilter('HomeSlice', ['chains', 'network']);

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['AuthenSlice', 'HomeSlice'],
  blacklist: [''],
  transforms: [saveAuthenSliceFilter, saveHomeSliceFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const sagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(logger),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);
