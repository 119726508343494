import { Redirect } from 'react-router-dom';
import React from 'react';
import HomePage from './pages/home';
import TokenStaking from './pages/tokenStaking/index';
import MyNft from './pages/myNft/index';
import { linkRouters } from 'common/constant';

const routes = [
  {
    name: 'Staking',
    path: '/staking',
    component: TokenStaking,
    exact: true,
  },
  {
    name: 'Home',
    path: '/',
    component: () => <Redirect to="staking" />,
    exact: false,
  },
];

const privateRoutes = [
  {
    name: 'Home',
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    name: 'My Nft',
    path: linkRouters.myNft,
    component: MyNft,
    exact: true,
  },
];

export { routes, privateRoutes };
