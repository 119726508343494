import { Modal } from 'antd';
import React from 'react';

import ImageLoaing from 'resources/images/icon-loading.png';

const LoadingContract = ({ visible, content, title, className }) => {
  return (
    <Modal visible={visible} footer={false} closable={false} className={`modal-loading-contract ${className}`}>
      <img className="loading-rotate rotating" src={ImageLoaing} />
      <p className="title">{title}</p>
      <p className="content">{content}</p>
    </Modal>
  );
};

export default LoadingContract;
