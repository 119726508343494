import { put, takeLatest, call, all } from 'redux-saga/effects';
import { handleConnectRequest, handleConnectResponse, handleDisConnectRequest } from './slice';
import { postLoginApi } from 'services/Authentication';
import { KEY_WALLETCONNECT } from 'common/constant';
import { getToken } from '../../services/api';
import { handleResetState } from 'redux/tokenStaking/slice';

export function* handleConnectRequestSaga(action) {
  const { payload } = action;
  const { address, chainId, signature } = payload;

  try {
    const response = yield postLoginApi({ address: address.toLowerCase(), chainId, signature });
    if (response?.meta?.code === 0) {
      yield put(handleConnectResponse(response?.data));
      getToken(response?.data.token);
    } else {
      localStorage.removeItem(KEY_WALLETCONNECT);
    }
  } catch (error) {
  } finally {
    yield put(handleConnectResponse({ loading: false }));
  }
}

// function* handleDisconnectRequetSaga() {
//   yield put(handleResetState());
// }
function* watchAuthenSaga() {
  yield all([
    takeLatest(handleConnectRequest, handleConnectRequestSaga),
    // takeLatest(handleDisConnectRequest, handleDisconnectRequetSaga),
  ]);
}

export default watchAuthenSaga;
