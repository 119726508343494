import * as React from 'react';

const AddIcon = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
      fill="url(#paint0_linear_16349_2680)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16349_2680"
        x1={2}
        y1={2}
        x2={23.9057}
        y2={4.39305}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#912066" />
        <stop offset={0.755208} stopColor="#C52B8B" />
      </linearGradient>
    </defs>
  </svg>
);

export default AddIcon;
