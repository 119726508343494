import React, { useMemo } from 'react';
import { Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CHAIN_INFO } from 'common/constant';
import { useSelector } from 'react-redux';

const { Paragraph } = Typography;

const NetworkNotice = ({}) => {
  const { t } = useTranslation();

  const antIcon = <LoadingOutlined spin />;

  const chains = useSelector((state) => state.HomeSlice.chains);
  const network = useSelector((state) => state.HomeSlice.network);

  const networkSelected = useMemo(() => {
    const chain = chains.find((item) => item.chainId === network);

    return chain;
  }, [chains, network]);

  return (
    <div className="network_notice">
      <Spin indicator={antIcon} />
      <Paragraph className="title">{t('header.network_notice_title')}</Paragraph>
      <span
        dangerouslySetInnerHTML={{
          __html: t('header.network_notice_desc', {
            networkName: networkSelected?.chainName,
          }),
        }}
        className="desc"
      />
    </div>
  );
};

export default NetworkNotice;
