import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  address: '',
  token: '',
  visibleConnectWallet: false,
  currentWallet: ""
};

export const AuthenSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleConnectModalRequest: (state, action) => {
      const { visibleConnectWallet } = current(state);
      const { payload } = action;
      const { data } = payload;

      return {
        ...state,
        visibleConnectWallet: data === null ? !visibleConnectWallet : data,
      };
    },
    handleConnectRequest: (state, action) => {
      const { payload } = action;

      return { ...state };
    },
    handleConnectResponse: (state, action) => {
      const { payload } = action;
      // const { auction } = current(state);

      return {
        ...state,
        ...payload,
      };
    },
    handleDisConnectRequest: (state, action) => {
      return { ...state, address: '', token: '', currentWallet: '' };
    },

    handleChangeDisconnectStatus: (state, action) => {
      const { payload } = action;
      return { ...state, selectedDisconnectStatus: false };
    },
    setCurrentWallet: (state, action) => ({
      ...state,
      currentWallet: action.payload,
    }),
  },
});

export const {
  handleConnectRequest,
  handleConnectResponse,
  handleDisConnectRequest,
  handleChangeDisconnectStatus,
  handleConnectModalRequest,
  setCurrentWallet
} = AuthenSlice.actions;

export default AuthenSlice.reducer;
