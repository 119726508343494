import * as React from 'react';

const ConnectWalletIcon = (props) => (
  <svg width={17} height={12} viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.47363 11.7988H14.4522C14.9893 11.7988 15.4258 11.3623 15.4258 10.8252V8.62803H10.767C10.4181 8.62803 10.0773 8.55989 9.75764 8.42356C9.44933 8.29375 9.17184 8.10551 8.93492 7.86861C8.698 7.63169 8.50977 7.35419 8.37995 7.04587C8.24527 6.72619 8.17549 6.38542 8.17549 6.03654V5.76229C8.17549 5.41341 8.24364 5.07264 8.37995 4.75296C8.50977 4.44464 8.69802 4.16716 8.93492 3.93024C9.17186 3.69332 9.44933 3.50509 9.75764 3.37527C10.0773 3.24058 10.4181 3.1708 10.767 3.1708H15.4258V0.973635C15.4258 0.436513 14.9893 0 14.4522 0H1.47363C0.936513 0 0.5 0.436513 0.5 0.973635V10.8252C0.5 11.3623 0.936513 11.7988 1.47363 11.7988Z"
      fill="white"
    />
    <path
      d="M16.5 4.87456C16.5 4.4705 16.1738 4.14433 15.7698 4.14433H15.4257H10.7669C9.87277 4.14433 9.14905 4.86807 9.14905 5.76219V6.03643C9.14905 6.93055 9.87278 7.65427 10.7669 7.65427H15.4257H15.7698C16.1738 7.65427 16.5 7.3281 16.5 6.92404V4.87456ZM11.0314 6.75854C10.556 6.75854 10.1714 6.37397 10.1714 5.8985C10.1714 5.42304 10.556 5.03845 11.0314 5.03845C11.5069 5.03845 11.8915 5.42304 11.8915 5.8985C11.8915 6.37397 11.5069 6.75854 11.0314 6.75854Z"
      fill="white"
    />
  </svg>
);

export default ConnectWalletIcon;
