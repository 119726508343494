import { Input } from 'antd';
import { DEFAULT_PAGE_SIZE } from 'common/constant';
import CustomPagination from 'components/Pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import selectedAuthen from 'redux/authen/selector';
import Nft from 'resources/images/nft.png';
import icon_seach from 'resources/svg/icon-search.svg';

const MyNft = (props) => {
  const [pagination, setPagination] = useState({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const [nftName, setNftName] = useState('');
  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);

  useEffect(() => {
    if (!selectedAddressWallet) {
      props.history.push('/');
    }
  }, []);

  const mockData = [
    {
      image: Nft,
      name: 'NFT name 1',
    },

    {
      image: Nft,
      name: 'NFT name 1',
    },
    {
      image: Nft,
      name: 'NFT name 1',
    },
    {
      image: Nft,
      name: 'NFT name 1',
    },
    {
      image: Nft,
      name: 'NFT name 1',
    },
    {
      image: Nft,
      name: 'NFT name 1',
    },
    {
      image: Nft,
      name: 'NFT name 1',
    },
  ];

  const handleChangePage = (page, pageSize) => {
    setPagination({ page, pageSize });
  };

  const handleChangeNftName = (e) => {
    setNftName(e.target.value.trim());
  };

  return (
    <div className="container">
      <div className="my-nft">
        <h2 className="my-nft__title">My NFT</h2>
        <div className="my-nft__search">
          <Input
            placeholder="Search NFT name"
            value={nftName}
            onChange={handleChangeNftName}
            onBlur={handleChangeNftName}
            // onKeyDown={handleSearch}
            suffix={<img src={icon_seach} />}
          />
        </div>
        <div className="list-nft">
          {mockData.map((item, index) => (
            <div className="list-nft__item" key={index}>
              <img src={item.image} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
        <div className="my-nft__pagination">
          <span className="my-nft__pagination__total">Total NFT: 10</span>
          <CustomPagination
            current={pagination.page}
            defaultCurrent={1}
            pageSize={pagination.pageSize}
            total={50}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(MyNft);
