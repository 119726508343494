import React from 'react'

const IconClose = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24 1.41405L22.5859 0L12 10.5859L1.41405 0L0 1.41405L10.5859 12L0 22.5859L1.41405 24L12 13.4141L22.5859 24L24 22.5859L13.4141 12L24 1.41405Z" fill="#110C3B" />
    </svg>

  )
}

export default IconClose
