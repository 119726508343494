import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
// import Web3 from 'web3';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useWeb3React } from '@web3-react/core';

import ButtonConnectWallet from '../ButtonConnectWallet';
import { useConnectWallet } from 'common/customHook';
import { CURRENT_WALLET, KEY_DISCONNECT } from 'common/constant';
import ModalPE from '../Modal';
import { METAMASK_DEEPLINK } from 'common/constant';
import { handleChangeNetworkRequest } from 'redux/home/slice';
import { setCurrentWallet } from 'redux/authen/slice';

import icon_metamask from 'resources/svg/logo_metamask.svg';
import icon_walletconnect from 'resources/svg/icon_walletconnect.svg';
import icon_right from 'resources/svg/icon_right.svg';
import icon_right_blue from 'resources/svg/icon_right_blue.svg';
import IconDownload from 'resources/svg/IconDownload.tsx';
const ethereum = window?.ethereum;
const { Paragraph, Text, Title } = Typography;

const ConnectWallet = ({ handleOnPopup }) => {
  const { t } = useTranslation();
  const { connectInjected, connectWalletConnect } = useConnectWallet();
  const dispatch = useDispatch();
  const { isActive, account } = useWeb3React();

  const chains = useSelector((state) => state.HomeSlice.chains);
  const network = useSelector((state) => state.HomeSlice.network);

  const [visible, setVisible] = useState(false);
  const [localWallet, setLocalWallet] = useState('');

  useEffect(() => {
    if (isActive && account) {
      dispatch(setCurrentWallet(localWallet));
    }
  }, [localWallet, isActive, account]);

  const onWalletConnect = async () => {
    await connectWalletConnect();
    setLocalWallet(CURRENT_WALLET.WALLET_CONNECT);
  };

  const onMetamask = () => {
    if (ethereum?.isMetaMask) {
      connectInjected(null, connectSuccess, handleOnPopup);
      setLocalWallet(CURRENT_WALLET.METAMASK);
    } else {
      setVisible(true);
    }
  };

  const connectSuccess = () => {
    localStorage.removeItem(KEY_DISCONNECT);
    handleOnPopup();
  };

  const hanhdleMetamaskNotfound = () => {
    setVisible(!visible);
  };

  const handleChangeNetwork = (chain) => () => {
    dispatch(handleChangeNetworkRequest({ network: chain.chainId }));
  };

  return (
    <div className="connect_wallet">
      <Title className="title">{t('header.txt_connect_wallet')}</Title>
      <p className="desc" dangerouslySetInnerHTML={{ __html: t('header.txt_note_connect_wallet') }}></p>
      <span className="label">Choose Network</span>
      <div className="chains-network">
        {chains.map((item) => (
          <div
            className={cx('btn-wrapper', { active: network === item.chainId })}
            key={item.chainId}
            onClick={handleChangeNetwork(item)}
          >
            <Button className="chain btn">
              {item?.icon && <img src={require(`resources/svg/${item?.icon}`)} />}
              {item?.chainName}
            </Button>
          </div>
        ))}
      </div>
      <span className="label">Choose Wallet</span>
      <ButtonConnectWallet className="btn" onSubmit={onMetamask}>
        <img className="btn__icon-logo" src={icon_metamask} alt={icon_metamask} />
        {t('header.txt_metamask')}
        <img className="btn__icon-right" src={icon_right} alt={icon_right} />
      </ButtonConnectWallet>
      <ButtonConnectWallet className="btn" onSubmit={onWalletConnect}>
        <img className="btn__icon-logo" src={icon_walletconnect} alt={icon_walletconnect} />
        {t('header.txt_wallet_connect')}
        <img className="btn__icon-right" src={icon_right_blue} alt={icon_right_blue} />
      </ButtonConnectWallet>
      <ModalPE visible={visible} onCancel={hanhdleMetamaskNotfound} wrapClassName="model-metamask_notfound">
        <div className={'metamask_notfound'}>
          <Paragraph className="title">{t('header.metamask_not_found_title')}</Paragraph>
          <img src={icon_metamask} alt={icon_metamask} />
          <Text className="hint">{t('header.modal_connectWallet_hint')}</Text>
          <a target="_blank" href={`${METAMASK_DEEPLINK}`} className="link" rel="noreferrer">
            <IconDownload />
            {t('header.modal_connectWallet_link')}
          </a>
        </div>
      </ModalPE>
    </div>
  );
};

export default withTranslation()(ConnectWallet);
