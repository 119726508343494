import React, { useState, Fragment } from 'react';
import { Button, Typography } from 'antd';
import iconEye from 'resources/images/icon_eye.png';
import iconEyeClose from 'resources/images/icon_eye_close.png';
import { passwordStrength } from 'utils';
import { useTranslation } from 'react-i18next';
import NumberFormat from '../NumberFormat/index.tsx';
import { isNil } from '../NumberFormat/utils';

const onlyNumberArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];

const { Paragraph } = Typography;

function InputComponent({
  field,
  form,
  labelTitle,
  required,
  type,
  showLevelPassword,
  onlyNumber = false,
  border = false,
  button,
  paragraph,
  maxClick,
  inputMaxAmount = false,
  hideError = false,
  typeNumber = false,
  maxLengthNumber,
  decimalScale,
  autoComplete,
  isAllowed,
  onChange,
  ...props
}) {
  const { t } = useTranslation();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [typePassword, setTypePassword] = useState('password');

  const { touched, errors } = form;

  const toggleIconEye = () => {
    setIsShowPassword((state) => !state);
    setTypePassword(typePassword === 'password' ? 'text' : 'password');
  };
  const levelPassword = [
    { level: 0, text: t('common.low') },
    { level: 1, text: t('common.low') },
    { level: 2, text: t('common.medium') },
    { level: 3, text: t('common.high') },
    { level: 4, text: t('common.high') },
  ];
  const addClassLevel =
    passwordStrength(field.value) < 2
      ? 'input__label--low'
      : passwordStrength(field.value) < 3
      ? 'input__label--medium'
      : passwordStrength(field.value) < 5
      ? 'input__label--high'
      : '';

  const onlyAccessNumber = (e) => {
    if (onlyNumber && !onlyNumberArray.includes(e.key)) {
      e.preventDefault();
      return;
    }
  };

  const handleChange = (e) => {
    if (true) return;
  };

  return (
    <div className="input">
      {labelTitle && (
        <label htmlFor={field.name} className="input__label">
          {labelTitle}
          {required && ' *'}
          {type === 'password' && !!field.value && !!showLevelPassword && (
            <div className="input__label--level">
              {levelPassword.map((item) => (
                <span
                  className={`input__label--level-item ${item.level <= passwordStrength(field.value) && addClassLevel}`}
                ></span>
              ))}
              <span className={`input__label--level-title ${addClassLevel}`}>
                {levelPassword.filter((item) => item.level === passwordStrength(field.value))[0]?.text}
              </span>
            </div>
          )}
        </label>
      )}
      <div className={`input__wrap-input ${border && 'input__wrap-input_border'}`}>
        {paragraph && <Paragraph className="input__paragraph">{paragraph}</Paragraph>}
        {typeNumber ? (
          <Fragment>
            <NumberFormat
              {...field}
              {...props}
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={decimalScale || 8}
              decimalSeparator={'.'}
              onValueChange={(values) => {
                const { floatValue } = values;
                form.setFieldValue(field.name, floatValue);
              }}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                if (floatValue > maxLengthNumber) maxClick();
                return isNil(maxLengthNumber) || formattedValue === '' || floatValue <= maxLengthNumber;
              }}
              onChange={onChange ?? handleChange}
            />
            {button && (
              <button type="button" className="input__button" onClick={maxClick} disabled={props.disabled}>
                {button}
              </button>
            )}
          </Fragment>
        ) : (
          <>
            <input
              type={type === 'password' ? typePassword : type}
              id={field.name}
              onKeyDown={onlyAccessNumber}
              autoComplete={autoComplete || 'nope'}
              {...field}
              {...props}
            />
            {button && (
              <button type="button" className="input__button" onClick={maxClick}>
                {button}
              </button>
            )}
          </>
        )}
        {type === 'password' && (
          <Button
            className="input__wrap-input-icon"
            shape="circle"
            onClick={toggleIconEye}
            icon={!isShowPassword ? <img src={iconEyeClose} /> : <img src={iconEye} />}
          />
        )}
      </div>
      {!hideError && errors[field.name] && touched[field.name] && (
        <span className="input__message">{errors[field.name]}</span>
      )}
    </div>
  );
}

export default InputComponent;
