import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  handleGetPoolsRequest,
  handleGetPoolsResponse,
  handleGetNftsRequest,
  handleGetNftsResponse,
  handleStakingRequest,
  handleUpdateTxidStakingRequest,
  handleGetMyStakingPoolsRequest,
  handleGetMyStakingPoolsResponse,
  handleSignRequest,
  handleClaimRequest,
  handleUpdateStatusUnstake,
  handleRevertUnstake,
  handleUpdateClaim,
} from './slice';

import {
  getListPoolApi,
  getListNftsApi,
  stakingApi,
  getListNftsStakingApi,
  signStakingApi,
  claimgApi,
  updateTxidStakingApi,
  updateActionStatus,
  revertUnstakeStatus,
  updateClaimStatus,
} from 'services/nftStaking';

// list pools
export function* handlegetPoolsRequestSaga(action) {
  const { payload } = action;
  const { data } = payload;

  try {
    const response = yield getListPoolApi(data);
    if (response?.meta?.code === 0) {
      yield put(
        handleGetPoolsResponse({
          data: response?.data,
          loadMore: data.offset,
        }),
      );
    } else {
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// my staking pools
export function* handleGetMyStakingPoolsSaga(action) {
  const { payload } = action;
  const { data } = payload;

  try {
    const response = yield getListNftsStakingApi(data);
    if (response?.meta?.code === 0) {
      yield put(
        handleGetMyStakingPoolsResponse({
          data: response?.data,
          loadMore: data.offset,
        }),
      );
    } else {
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// list nft for user
export function* handleGetNftsSaga(action) {
  const { payload } = action;
  const { data } = payload;

  try {
    const response = yield getListNftsApi(data);
    if (response?.meta?.code === 0) {
      yield put(
        handleGetNftsResponse({
          data: response?.data,
          loadMore: data.offset,
        }),
      );
    } else {
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// staking nft
export function* handleStakingSaga(action) {
  const { payload } = action;
  const { data, callback, callbackFail } = payload;

  try {
    const response = yield stakingApi(data);
    if (response?.meta?.code === 0) {
      callback(response.data._id);
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// update txid staking nft
export function* handleUpdateTxidStakingSaga(action) {
  const { payload } = action;
  const { txid, idStake, callback, callbackFail } = payload;

  try {
    const response = yield updateTxidStakingApi({ txId: txid, stakeId: idStake });
    if (response?.meta?.code === 0) {
      callback();
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
  } finally {
    // yield put(handleGetPoolsResponse({ loading: false }));
  }
}

// sign
export function* handleSignSaga(action) {
  const { payload } = action;
  const { data, stakeId, callback, callbackFail } = payload;

  try {
    const response = yield signStakingApi(data);
    if (response?.meta?.code === 0) {
      callback({ resSign: response.data, stakeId });
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
    callbackFail();
    return null;
  } finally {
  }
}

// claim

export function* handleClaimSaga(action) {
  const { payload } = action;
  const { data, callbackFail, callback } = payload;

  try {
    const response = yield claimgApi(data);
    if (response?.meta?.code === 0) {
      callback();
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
    callbackFail();
    return null;
  } finally {
  }
}

// update status unstake
export function* handleUpdateStatusUnstakeSaga(action) {
  const { payload } = action;
  const { data, callbackFail, callback } = payload;

  try {
    const response = yield updateActionStatus(data);
    if (response?.meta?.code === 0) {
      callback();
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
    callbackFail();
    return null;
  } finally {
  }
}

// revert unstake
export function* handleRevertUnstakeSaga(action) {
  const { payload } = action;
  const { data, callbackFail, callback } = payload;

  try {
    const response = yield revertUnstakeStatus(data);
    if (response?.meta?.code === 0) {
      callback();
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
    callbackFail();
    return null;
  } finally {
  }
}

// update status claim
export function* handleUpdateClaimSaga(action) {
  const { payload } = action;
  const { data, callbackFail, callback } = payload;

  try {
    const response = yield updateClaimStatus(data);
    if (response?.meta?.code === 0) {
      callback();
    } else {
      callbackFail();
      return null;
    }
  } catch (error) {
    callbackFail();
    return null;
  } finally {
  }
}

function* watchNftStakingSaga() {
  yield all([takeLatest(handleGetPoolsRequest, handlegetPoolsRequestSaga)]);
  yield all([takeLatest(handleGetNftsRequest, handleGetNftsSaga)]);
  yield all([takeLatest(handleStakingRequest, handleStakingSaga)]);
  yield all([takeLatest(handleUpdateTxidStakingRequest, handleUpdateTxidStakingSaga)]);
  yield all([takeLatest(handleGetMyStakingPoolsRequest, handleGetMyStakingPoolsSaga)]);
  yield all([takeLatest(handleSignRequest, handleSignSaga)]);
  yield all([takeLatest(handleClaimRequest, handleClaimSaga)]);
  yield all([takeLatest(handleUpdateStatusUnstake, handleUpdateStatusUnstakeSaga)]);
  yield all([takeLatest(handleRevertUnstake, handleRevertUnstakeSaga)]);
  yield all([takeLatest(handleUpdateClaim, handleUpdateClaimSaga)]);
}

export default watchNftStakingSaga;
