import React, { useState } from 'react';
import { Input, Switch } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';

import SwitchComponent from 'components/SwitchComponent';
import icon_seach from 'resources/svg/icon-search.svg';
import { DATA_SEARCH_POOL_MECHANISM, DATA_SEARCH_STATUS, DATA_SEARCH_TOKEN_STAKING_STATUS } from 'common/constant';

const Search = ({ dataSearch, onChangeSearch }) => {
  const { t } = useTranslation();

  const [poolName, setpoolName] = useState('');

  const handlePoolname = (e) => {
    setpoolName(e.target.value);
  };

  const handleChangeStatus = (e) => {
    onChangeSearch({ ...dataSearch, status: e });
  };

  const handleChangeTypeToken = (e) => {
    onChangeSearch({ ...dataSearch, type: e });
  };

  const handleChangePoolMechanism = (e) => {
    onChangeSearch({ ...dataSearch, modelType: e });
  };

  const handleChangeMyStaking = (e) => {
    onChangeSearch({ ...dataSearch, myStaking: e });
  };

  const handleSearch = (data) => {
    if (data.key === 'Enter') {
      setpoolName(data.target.value.trim());
      onChangeSearch({ ...dataSearch, keyword: poolName.trim() });
    }
  };

  const handleChangeNamePoolBlur = (e) => {
    setpoolName(e.target.value.trim());
  };
  return (
    <div className="search-token-list">
      <div className="search-left">
        <SwitchComponent
          data={DATA_SEARCH_STATUS}
          checked={dataSearch.status}
          name="statusNftStaking"
          onChange={handleChangeStatus}
        />
        <SwitchComponent
          data={DATA_SEARCH_TOKEN_STAKING_STATUS}
          checked={dataSearch.type}
          name="statusTokenStaking"
          onChange={handleChangeTypeToken}
        />
        <SwitchComponent
          data={DATA_SEARCH_POOL_MECHANISM}
          checked={dataSearch.modelType}
          name="poolMechanism"
          onChange={handleChangePoolMechanism}
        />
        <div className="staking-pool">
          <Switch onChange={handleChangeMyStaking} checked={dataSearch.myStaking} />
          {t('nft-staking.txt_my_staking_pool')}
        </div>
      </div>
      <div className="search-right">
        <Input
          placeholder={t('nft-staking.txt_search_pool_name')}
          value={poolName}
          onChange={handlePoolname}
          suffix={<img src={icon_seach} />}
          onBlur={handleChangeNamePoolBlur}
          onKeyDown={handleSearch}
        />
      </div>
    </div>
  );
};

export default withTranslation()(Search);
