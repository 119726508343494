import React from 'react';
import { Button } from 'antd';

const ButtonConnectWallet = ({ className, children, onSubmit, handleOnPopup }) => {
  return (
    <div className="btn-wrapper">
      <Button onClick={onSubmit} className={className}>
        {children}
      </Button>
    </div>
  );
};

export default ButtonConnectWallet;
