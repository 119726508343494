import React, { useState } from 'react';
import { Drawer, Menu } from 'antd';
import { withTranslation, useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { linkRouters, LINK_BY_LP_TOKEN, LINK_BY_TOKEN } from 'common/constant';

const DrawerMenu = ({ onClose, visible, onDisconnect, selectedAddressWallet, ...props }) => {
  const [current, setCurrent] = useState('');

  const { t } = useTranslation();

  const handleChangeMenu = (props) => {};

  const handleRedirect = (path) => {
    props.history.push({
      pathname: linkRouters[path],
    });
    onClose();
  };

  return (
    <Drawer
      title=""
      placement={'right'}
      width={375}
      onClose={onClose}
      visible={visible}
      getContainer=""
      // closeIcon={<IconClose />}
    >
      <div>
        <Menu onClick={handleChangeMenu} selectedKeys={[current]}>
          <Menu.Item key="by-token">
            <a href={LINK_BY_TOKEN} target="_blank" rel="noreferrer">
              {t('header.txt_ttx_token')}
            </a>
          </Menu.Item>
          <Menu.Item key="get-token">
            <a href={LINK_BY_LP_TOKEN} target="_blank" rel="noreferrer">
              {' '}
              {t('header.get_ttx-bnb_lp_token')}
            </a>
          </Menu.Item>
          {selectedAddressWallet && (
            <Menu.Item key="disconnect" onClick={onDisconnect}>
              {t('header.txt_profile_disconnect')}
            </Menu.Item>
          )}
        </Menu>
        {props.children}
      </div>
    </Drawer>
  );
};

export default withTranslation()(withRouter(DrawerMenu));
