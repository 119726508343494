import React, { useEffect, useMemo } from 'react';
// import { Web3ReactProvider } from '@web3-react/core';
import { withTranslation } from 'react-i18next';
import { Router, Route, Switch } from 'react-router-dom';
import { SocketContext, socket } from 'context/socket';
import { Web3ReactProvider } from '@web3-react/core';

import { browserHistory } from './utils/history';
import {
  hooksMetaMask,
  hooksWalletConnectBSC,
  metaMask,
  walletConnectBSC,
  walletConnectETH,
  walletConnectPoly,
  hooksWalletConnectETH,
  hooksWalletConnectPoly,
} from 'common/connectors';
import Layout from './pages/layout';
import { routes, privateRoutes } from './Routes';

function App() {
  const connectors = useMemo(
    () => [
      [metaMask, hooksMetaMask],
      [walletConnectBSC, hooksWalletConnectBSC],
      [walletConnectETH, hooksWalletConnectETH],
      [walletConnectPoly, hooksWalletConnectPoly],
    ],
    [],
  );
  return (
    <SocketContext.Provider value={socket}>
      <Web3ReactProvider connectors={connectors}>
        <div className="App bg-app">
          <Router history={browserHistory}>
            <Switch>
              <Route path={[...routes, ...privateRoutes].map((item) => item.path)} component={Layout} />
            </Switch>
          </Router>
        </div>
      </Web3ReactProvider>
    </SocketContext.Provider>
  );
}

export default withTranslation()(App);
