import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Layout, Menu, Popover, Space, Typography } from 'antd';
import { useWeb3React } from '@web3-react/core';
import cx from 'classnames';

import selectedAuthen from 'redux/authen/selector';
import { handleConnectRequest, handleDisConnectRequest, handleConnectModalRequest } from 'redux/authen/slice';
import { handleChangeNetworkRequest } from 'redux/home/slice';
import { requestSupportNetwork } from 'utils/setupNetwork';
import { convertAddressWallet, signToVerify, useWindowSize } from 'utils/index';
import { BREAKPOINT_LG, KEY_WALLETCONNECT, LINK_BY_LP_TOKEN, LINK_BY_TOKEN } from 'common/constant';
import { getToken } from 'services/api';
import { useConnectWallet, deactivateConnect } from 'common/customHook';
import { linkRouters } from 'common/constant';
import localStorage from 'redux-persist/es/storage';

import DrawerMenu from './Drawer';
import ButtonPE from '../ButtonPE';
import ConnectWallet from '../ConnectWallet';
import ModalPE from '../Modal';
import NetworkNotice from './NetworkNotice';

import Logo from 'resources/svg/logo.svg';
import IconCopy from 'resources//svg/iconCopy';
import IconChecked from 'resources//svg/iconChecked';
import MenuOutlined from 'resources/svg/menuOutlined';
import ConnectWalletIcon from 'resources/svg/ConnectWalletIcon';
import LoadingContract from 'components/LoadingContract';

import { getProvider } from 'utils/baseWallet';

const { Item } = Menu;

const { Header } = Layout;
const { Paragraph } = Typography;

const HeaderCommon = (props) => {
  const { account, isActive, chainId, connector } = useWeb3React();
  const library = getProvider(connector?.provider);
  const { connectInjected, connectWalletConnect } = useConnectWallet();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [width] = useWindowSize();

  const isMobile = width < BREAKPOINT_LG;

  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);
  const visibleConnect = useSelector(selectedAuthen.selectedVisibleModalConnectWallet);
  const currentWallet = useSelector(selectedAuthen.selectedCurrentWallet);
  const chains = useSelector((state) => state.HomeSlice.chains);
  const network = useSelector((state) => state.HomeSlice.network);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [visibleWrongNetwork, setVisibleWrongNetwork] = useState(false);
  const [visibleChangeNetwork, setVisibleChangeNetwork] = useState(false);
  const [visibleSign, setVisibleSign] = useState(false);
  const [headerTiny, setHeaderTiny] = useState(false);

  useEffect(() => {
    checkConnectWallet();
  }, []);

  const checkConnectWallet = async () => {
    let checkConnect = await localStorage.getItem(KEY_WALLETCONNECT);
    if (checkConnect) {
      connectWalletConnect();
    } else if (selectedAddressWallet) {
      connectInjected(null, null, null);
    }
  };

  useEffect(() => {
    const { ethereum } = window;
    const handleLockWallet = (payload) => {
      if (!payload.length) handleDisconnect();
    };
    if (ethereum) {
      ethereum.on('accountsChanged', handleLockWallet);
    }
    return () => {
      ethereum.removeListener('accountsChanged', handleLockWallet);
    };
  }, [window?.ethereum]);

  useEffect(() => {
    if (account && isActive) dispatch(handleConnectModalRequest({ data: false }));
    const connectWithSignature = async () => {
      if (chainId && chainId === network) {
        setVisibleWrongNetwork(false);

        if (isActive && account.toLowerCase() !== selectedAddressWallet.toLowerCase()) {
          setVisibleSign(true);
          const signature = await signToVerify({
            library,
            creator: account,
            callbackReject: () => setVisibleSign(false),
          });
          if (!signature) return handleDisconnect();
          setVisibleSign(false);
          dispatch(handleConnectRequest({ address: account, chainId: chainId, signature }));
        }
      } else {
        if (!chainId || !account) return;
        switchNetwork();
        if (account) {
          setVisibleWrongNetwork(true);
        } else handleDisconnect();
      }
    };
    connectWithSignature();
  }, [account, chainId, isActive, network, chains]);

  const switchNetwork = async () => {
    const networkSupports = chains.reduce(
      (object, item) => ({
        ...object,
        [item.chainId]: {
          chainId: `0x${item.chainId.toString(16)}`,
          chainName: item?.chainName,
          nativeCurrency: item?.nativeCurrency,
          rpcUrls: item?.rpcUrls,
          blockExplorerUrls: [item?.blockExplorerUrls],
        },
      }),
      {},
    );
    // check user choose which network then use id network.
    await requestSupportNetwork(currentWallet, networkSupports, network);
  };

  const handleScroll = () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      setHeaderTiny(true);
    } else {
      setHeaderTiny(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleRedirecHome = () => {
    props.history.push({
      pathname: linkRouters.home,
    });
  };

  const handleOnPopup = () => {
    if (isActive) deactivateConnect(connector);
    dispatch(handleConnectModalRequest({ data: null }));
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleDisconnect = () => {
    deactivateConnect(connector);
    setVisibleSign(false);
    getToken('');
    dispatch(handleDisConnectRequest());
  };

  const RenderPopup = () => {
    return (
      <div className="wrap-info-header">
        <Paragraph
          copyable={{
            tooltips: false,
            text: selectedAddressWallet,
            icon: [<IconCopy key="copy" />, <IconChecked key="checked" />],
          }}
        >
          {convertAddressWallet(selectedAddressWallet)}
        </Paragraph>
        <Menu className="header__menu account__menu-mobile">
          {/* <Item key="0" className="item" onClick={() => goToMyNft()}>
            <a>{t('header.txt_profile_myNft')}</a>
          </Item> */}
          <Item key="1" className="item">
            <ButtonPE
              text={t('header.txt_profile_disconnect')}
              variant="primary"
              onClick={handleDisconnect}
              classNameStyle="btn-disconnect"
            />
          </Item>
        </Menu>
      </div>
    );
  };

  const networkSelected = useMemo(() => {
    const chain = chains.find((item) => item.chainId === network);

    return chain;
  }, [chains, network]);

  const renderAddressWallet = () => {
    return !selectedAddressWallet ? (
      <div className={`address-wallet `} style={isMobile ? { bottom: '44px', marginLeft: '12px' } : {}}>
        <div className={`${!isMobile && 'content content-bg'}`}>
          <ButtonPE
            text={
              <Space align="center" size={6}>
                <ConnectWalletIcon />
                {t('header.txt_connect_wallet')}
              </Space>
            }
            variant="primary"
            classNameStyle="btn-connect"
            onClick={handleOnPopup}
          />
        </div>
      </div>
    ) : (
      <Popover
        content={<RenderPopup />}
        trigger={!isMobile ? 'click' : ''}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <div className={`address-wallet `} style={isMobile ? { bottom: '44px', marginLeft: '12px' } : {}}>
          <div className={`${!isMobile && 'content content-bg'}`}>
            <ButtonPE
              text={
                <Space align="center">
                  <ConnectWalletIcon />
                  {convertAddressWallet(selectedAddressWallet)}
                </Space>
              }
              classNameStyle="btn-connect"
            />
          </div>
        </div>
      </Popover>
    );
  };

  const handleChangeVisibleNetwork = () => {
    setVisibleChangeNetwork((state) => !state);
  };

  const handleChangeNetwork = (chain) => () => {
    dispatch(handleChangeNetworkRequest({ network: chain.chainId }));
    setVisibleChangeNetwork(false);
  };

  return (
    <Header className={`header ${headerTiny ? 'header-tiny' : ''}`}>
      <div className="divide container">
        <div className="header--left" onClick={handleRedirecHome}>
          <img src={Logo} className="logo" alt="logo" />
          <h2 className="brand">{t('common.brand')}</h2>
        </div>
        {!isMobile && (
          <div className="header--center">
            <span>
              <a href={LINK_BY_TOKEN} target="_blank" rel="noreferrer">
                {t('header.txt_ttx_token')}
              </a>
            </span>
            <span key="get-token">
              <a href={LINK_BY_LP_TOKEN} target="_blank" rel="noreferrer">
                {' '}
                {t('header.get_ttx-bnb_lp_token')}
              </a>
            </span>
          </div>
        )}
        <div className="header--right">
          {!isMobile ? (
            <>
              {networkSelected && (
                <span className="network--selected" onClick={handleChangeVisibleNetwork}>
                  {networkSelected?.icon && <img src={require(`resources/svg/${networkSelected?.icon}`)} />}
                  {networkSelected?.chainName}
                </span>
              )}{' '}
              {renderAddressWallet()}
            </>
          ) : (
            <div className="header-mobile">
              <MenuOutlined alt="Menu Mobile" onClick={toggleDrawer} />
              <DrawerMenu
                visible={isDrawerOpen}
                onClose={toggleDrawer}
                onDisconnect={handleDisconnect}
                selectedAddressWallet={selectedAddressWallet}
              >
                <>
                  {networkSelected && (
                    <span className="network--selected" onClick={handleChangeVisibleNetwork}>
                      {networkSelected?.icon && <img src={require(`resources/svg/${networkSelected?.icon}`)} />}
                      {networkSelected?.chainName}
                    </span>
                  )}{' '}
                  {renderAddressWallet()}
                </>
              </DrawerMenu>
            </div>
          )}
        </div>
      </div>
      <ModalPE visible={visibleConnect} onCancel={handleOnPopup}>
        <ConnectWallet handleOnPopup={handleOnPopup} />
      </ModalPE>
      <ModalPE visible={visibleWrongNetwork} wrapClassName="model-metamask_notfound">
        <NetworkNotice />
      </ModalPE>
      <ModalPE
        visible={visibleChangeNetwork}
        wrapClassName="model-change-network"
        onCancel={handleChangeVisibleNetwork}
      >
        <div className="switch-network">
          <p className="title">Switch Network</p>
          <div className="chains-network">
            {chains.map((item) => (
              <div
                className={cx('btn-wrapper', { active: network === item.chainId })}
                key={item.chainId}
                onClick={handleChangeNetwork(item)}
              >
                <Button className="chain btn">
                  {item?.icon && <img src={require(`resources/svg/${item?.icon}`)} />}
                  {item?.chainName}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </ModalPE>
      <LoadingContract className="popup-sign" visible={visibleSign} title={t('Please sign to connect the system.')} />
    </Header>
  );
};
export default withTranslation()(withRouter(HeaderCommon));
