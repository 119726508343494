import { useWeb3React } from '@web3-react/core';
import { message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import selectedAuthen from 'redux/authen/selector';
import BaseWalletService, { getProvider } from 'utils/baseWallet';
import { finish } from 'common/constant';
import ButtonPE from 'components/ButtonPE';
import ConfirmSuccess from 'components/ConfirmSuccess';

const ApproveButton = ({ setVisibleLoaingContract, dataSearch, tokenId, setIsApproved, poolAddress }) => {
  const { t } = useTranslation();
  const { connector } = useWeb3React();
  const library = getProvider(connector?.provider);
  const wallet = new BaseWalletService().getInstance();
  const selectedAddressWallet = useSelector(selectedAuthen.selectedAddressWallet);

  const [visibleConfirmSuccess, setConfirmSuccess] = useState(false);
  const [textSuccess, setTextSuccess] = useState('');
  const [textHash, setTxHash] = useState('');

  const onHandleApprove = async () => {
    setVisibleLoaingContract(true);
    if (!selectedAddressWallet) {
      message.error(t('message.unauthorized'));
    } else {
      await wallet.approveToken({
        tokenAddress: tokenId,
        library,
        account: selectedAddressWallet,
        contractAddress: poolAddress,
        callbackSuccess: (data) => {
          setTextSuccess('Successfully approved.');
          setTxHash(data.hash);
          setConfirmSuccess(true);
        },
        callbackError: (err) => {
          message.error('An error occurred. Please try again later.');
        },
      });
    }
    setVisibleLoaingContract(false);
  };

  const handleCallbackSuccessApprove = () => {
    setIsApproved(true);
    setConfirmSuccess(false);
  };

  return (
    <>
      <ButtonPE
        text="Approve"
        classNameStyle="btn-stake"
        onClick={onHandleApprove}
        disabled={dataSearch.status === finish}
      />
      <ConfirmSuccess
        onCancel={handleCallbackSuccessApprove}
        visible={visibleConfirmSuccess}
        title={`Success`}
        content={textSuccess}
        hash={textHash}
        textBtn="Done"
        onDone={handleCallbackSuccessApprove}
      />
    </>
  );
};

export default ApproveButton;
